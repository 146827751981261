import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScrollContainer from "react-indiana-drag-scroll";
import PropTypes from "prop-types";

import GeneralPaymentCard from "../../Dumb/Cards/PaymentCard/GeneralPaymentCard";

const useStyles = makeStyles((theme) => ({
  horizontalScroll: {
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.radiuses[2],
    overflowY: "hidden",
    whiteSpace: "nowrap",
    scrollbarWidth: "thin",
    scrollbarColor: "#10316D",
  },
  paymentCardContainer: {
    margin: "0 1rem",
  },
}));

export default function UsedCards({ cards, selectedCardID, handleClickOnCard }) {
  const classes = useStyles();
  return (
    <ScrollContainer
      horizontal
      className={cards?.length > 0
        ? classes.horizontalScroll : null}
    >
      {
        cards.map((card) => (
          <div
            className={classes.paymentCardContainer}
            key={card.id}
          >
            <GeneralPaymentCard
              cardNumber={`**** **** **** ${card.last4}`}
              cardHolderName={card.name}
              cardExpiryDate={`${card.exp_month} / ${(card.exp_year)?.toString().slice(-2)}`}
              selectedCard={card.id === selectedCardID}
              onClickHandler={() => handleClickOnCard(card)}
            />
          </div>
        ))
      }
    </ScrollContainer>
  );
}

UsedCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    brand: PropTypes.string,
    exp_month: PropTypes.number,
    exp_year: PropTypes.number,
    id: PropTypes.string,
    last4: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  selectedCardID: PropTypes.string.isRequired,
  handleClickOnCard: PropTypes.func.isRequired,
};
