/* eslint-disable import/no-cycle */
import { loggedInAxios } from "../LoggedInCalls/LoggedInCalls";

export async function updatePassword(data) {
  const response = await loggedInAxios.post("/accounts/change-password/", data);
  return response;
}

export async function updateEmail(data) {
  const response = await loggedInAxios.patch("/accounts/profile/", data);
  return response;
}

export async function getUserData() {
  const response = await loggedInAxios.get("/accounts/profile/");
  return response;
}
