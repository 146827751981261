import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import FrameOne from "../../../Components/Dumb/CarouselFrames/FrameOne/FrameOne";
import FrameTwo from "../../../Components/Dumb/CarouselFrames/FrameTwo/FrameTwo";
import FrameThree from "../../../Components/Dumb/CarouselFrames/FrameThree/FrameThree";
import "./Carousel.css";

export default function Carousel() {
  const items = [
    <FrameOne
      cardText="Call Bulgaria for as little as 1.5&#162; per minute."
      url="/services#products"
    />,
    <FrameTwo
      title="Bulgaria Unlimited 2 in 1"
      emphasizedPaperText="Two virtual numbers for price of one! "
      paperText="For only $7.95/month you get one land and one mobile line Bulgarian number!"
      url="/services#products"
    />,
    <FrameThree
      title="Foneca Classic"
      paperText="Direct connection to Bulgaria and/or USA."
      url="/services#products"
    />,
  ];

  return (
    <AliceCarousel
      items={items}
      swipeDelta={20}
      touchTracking
      autoPlay="true"
      autoPlayInterval={7000}
      autoPlayStrategy="none"
      animationDuration={3000}
      disableDotsControls
      infinite
    />
  );
}
