import React, { useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

const StripeInput = (
  { component: Component, inputRef, ...props },
) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  })); return (
    <Component
      // eslint-disable-next-line no-return-assign
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
};

export default StripeInput;

StripeInput.propTypes = {
  component: PropTypes.func.isRequired,
  inputRef: PropTypes.string.isRequired,
};
