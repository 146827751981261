import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Menu, List, ListItem, IconButton, Paper, Button,
} from "@material-ui/core";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import GradientTypography from "../GradientTypography/GradientTypography";
import { useShoppingCart } from "../../../hooks/useShoppingCart/useShoppingCart";
import "./ShoppingCartDropDown.css";

const StyledMenu = withStyles((theme) => ({
  paper: {
    maxWidth: "25rem",
    background: "transparent",
    padding: "1rem",
    borderRadius: theme.radiuses[2],
  },
  list: {
    padding: "0rem",
    background: "transparent",
  },
}))((props) => (
  <Menu
    disableScrollLock
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  styledMenu: {
    maxWidth: "25rem",
    background: "transparent",
    border: "none",
  },
  list: {
    padding: "1rem 1rem 0rem 1rem",
    background: "#fff",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    maxHeight: "17rem",
    outline: "none",
    overflow: "auto",
  },
  listItem: {
    padding: "1rem",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
  },
  itemTypography: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bolder",
    marginRight: "1rem",
    width: "7rem",
  },
  emptyCartList: {
    marginBottom: "1rem",
    justifyContent: "center",
    padding: "1rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
  },
  emptyCartTypography: {
    fontWeight: "bolder",
  },
  imageHolder: {
    width: "8rem",
    marginRight: "1rem",
    display: "inline-grid",
  },
  image: {
    width: "100%",
    borderRadius: theme.radiuses[1],
  },
  paper: {
    boxShadow: theme.shadows[1],
    borderRadius: theme.radiuses[1],
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    alignItems: "center",
  },
  totalAmountTypography: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bolder",
    width: "7rem",
    textAlign: "center",
  },
  totalAmount: {
    WebkitTextFillColor: "#4F4F4F",
  },
  checkoutButton: {
    background: theme.palette.fonecaLogoGradient.main,
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: theme.radiuses[1],
    fontSize: "1.3rem",
    padding: "2rem 1rem",
    height: "3rem",
    "&:disabled": {
      color: "#fff",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
}));

export default function ShoppingCartDropDownMenu({
  anchorEl, isShoppingCartOpen, closeShoppingCart,
  onCheckoutClick,
}) {
  const classes = useStyles();
  const {
    getAllProducts, getTotal, removeProduct, getProductsCount,
  } = useShoppingCart();
  const cartProducts = getAllProducts();

  return (
    <>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isShoppingCartOpen}
        onClose={closeShoppingCart}
      >
        <List className={classes.list} id="products-list">
          {!getProductsCount() ? (
            <ListItem className={classes.emptyCartList}>
              <GradientTypography className={classes.emptyCartTypography}>
                Cart Is Empty !
              </GradientTypography>
            </ListItem>
          ) : (
            cartProducts?.map((product) => (
              <ListItem className={classes.listItem} key={product.key}>
                <div className={classes.imageHolder}>
                  <img alt="foneca-classic" src={product.image} className={classes.image} />
                </div>
                <GradientTypography className={classes.itemTypography}>
                  {product.name}
                  <span>
                    $
                    {product?.price?.price}
                  </span>
                </GradientTypography>
                <IconButton
                  onClick={() => removeProduct(product)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            ))
          )}
        </List>
        <Paper className={classes.paper}>
          <GradientTypography className={classes.totalAmountTypography}>
            Total
            <span className={classes.totalAmount} data-cy="total-amount">
              $
              {!getProductsCount() ? 0 : getTotal()}
            </span>
          </GradientTypography>
          <Button
            className={classes.checkoutButton}
            onClick={onCheckoutClick}
            disabled={!getProductsCount()}
          >
            Checkout
          </Button>
        </Paper>
      </StyledMenu>
    </>
  );
}

ShoppingCartDropDownMenu.defaultProps = {
  anchorEl: null,
};

ShoppingCartDropDownMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  isShoppingCartOpen: PropTypes.bool.isRequired,
  closeShoppingCart: PropTypes.func.isRequired,
  onCheckoutClick: PropTypes.func.isRequired,
};
