import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TextField, CircularProgress } from "@material-ui/core";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import { updateEmail } from "../../../api/ProfileCalls/ProfileCalls";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";

export default function ManageEmailForm() {
  const { openSnackbar } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    errors,
    setError,
  } = useForm({ reValidateMode: "onSubmit" });

  const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { mutate: updateEmailMutation, status: updateEmailStatus } = useMutation(
    updateEmail,
    {
      onSuccess: () => {
        openSnackbar("success", "Email updated successfully!", "success-email-update-snackbar");
      },
      onError: () => {
        setError("email", { message: "User with this email already exists" });
      },
    },
  );

  const onSubmit = (data) => {
    updateEmailMutation(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        required
        fullWidth
        label="New Email"
        name="email"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register({
          pattern: {
            value: emailRegex,
            message: "Please enter a valid email.",
          },
        })}
        error={!!errors.email}
        helperText={errors?.email?.message}
        variant="outlined"
        margin="normal"
        data-cy="new-email"
      />
      <CustomSubmitButton disableElevation fullWidth variant="contained" color="primary" type="submit" data-cy="update-email-button" style={{ margin: "2rem 0 0 0" }}>
        {
          updateEmailStatus === "loading" ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : ("Update")
        }
      </CustomSubmitButton>
    </form>
  );
}
