import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button, Dialog, IconButton, Typography,
} from "@material-ui/core/";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import AccessNumbersCard from "./AccessNumbersCard/AccessNumbersCard";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "12px",
    top: "12px",
    color: theme.palette.grey[500],
    [theme.breakpoints.down("330")]: {
      right: "3px",
    },
    [theme.breakpoints.down("285")]: {
      right: "3px",
      top: "6px",
    },
  },
  dialogTitle: {
    fontSize: "1.7rem",
    fontWeight: "bold",
    [theme.breakpoints.down("285")]: {
      fontSize: "1.3rem",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: theme.radiuses[1],
    [theme.breakpoints.down("380")]: {
      margin: "1.25rem",
    },
    [theme.breakpoints.down("285")]: {
      margin: "0.5rem",
    },
  },
  button: {
    background: theme.palette.fonecaLogoGradient.main,
    color: "#fff",
    fontWeight: "bold",
    fontSize: "1rem",
    height: "3rem",
    width: "5rem",
    borderRadius: theme.radiuses[1],
  },
  typography: {
    color: theme.palette.darkerFoneca.main,
    [theme.breakpoints.down("420")]: {
      padding: "1rem",
    },
  },
  accessNumbersContainer: {
    margin: "2rem 1rem",
    [theme.breakpoints.down("285")]: {
      margin: "2rem 0.5rem",
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <GradientTypography className={classes.dialogTitle}>{children}</GradientTypography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "1rem",
    [theme.breakpoints.down("420")]: {
      padding: "0rem",
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AccessNumbersDialog({
  accessNumbers,
  isAccessNumbersDialogOpen,
  closeAccessNumbersDialogHandler,
}) {
  const classes = useStyles();
  const isAccessNumberAvailable = !accessNumbers?.includes(undefined);

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      onClose={closeAccessNumbersDialogHandler}
      open={isAccessNumbersDialogOpen}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeAccessNumbersDialogHandler}>
        Access Numbers
      </DialogTitle>

      <DialogContent dividers>
        {isAccessNumberAvailable ? (
          <div>
            {accessNumbers?.map((product) => product.map((items) => (
              <div className={classes.accessNumbersContainer} key={uuidv4()}>
                <AccessNumbersCard title={items.title} numbers={items.numbers} />
              </div>
            )))}
          </div>
        ) : (
          <Typography
            className={classes.typography}
          >
            Access numbers will be available, when your order is fulfilled!
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={closeAccessNumbersDialogHandler}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AccessNumbersDialog.propTypes = {
  accessNumbers: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    numbers: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        number: PropTypes.string,
      }),
    ),
  })),
  isAccessNumbersDialogOpen: PropTypes.bool.isRequired,
  closeAccessNumbersDialogHandler: PropTypes.func.isRequired,
};
