import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid, makeStyles, Paper, Select, MenuItem, ButtonBase, Tooltip,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import HowToUseDialog from "./HowToUseDialog/HowToUseDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  typography: {
    fontWeight: 600,
  },
  nominalText: {
    fontWeight: 600,
    paddingRight: "4rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
    borderRadius: "1rem",
    boxShadow: theme.shadows[2],
  },
  insidePaper: {
    padding: theme.spacing(2, 2),
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[2],
  },
  select: {
    height: "2.5rem",
    padding: theme.spacing(0, 2, 0, 0),
  },
  rootSelect: {
    "&:focus": {
      background: "transparent",
    },
  },
  button: {
    color: "white",
    textTransform: "none",
    padding: theme.spacing(4, 2),
  },
  gridRow: {
    padding: theme.spacing(2, 0),
  },
}));

export default function AddToCartSection({
  productName,
  prices,
  handleAddToCart,
  nominal,
  setNominal,
}) {
  const classes = useStyles();

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const {
    setValue,
    control,
  } = useForm();

  const handleNominalChange = (event) => {
    setValue("nominal", event.target.value);
    setNominal(event.target.value);
  };

  const handleClickOpen = () => {
    setIsDialogOpened(true);
  };

  const handleClose = () => {
    setIsDialogOpened(false);
  };

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid
        container
        justify="space-between"
        className={classes.gridRow}
      >
        <Grid item>
          <GradientTypography
            paragraph
            variant="h3"
            className={classes.typography}
            align="left"
            data-cy="long-description-product-name"
          >
            {productName}
          </GradientTypography>
        </Grid>
        <Grid item>
          <Tooltip title="Click for instructions." placement="top">
            <Paper className={classes.insidePaper}>
              <ButtonBase onClick={handleClickOpen}>
                <GradientTypography align="left" variant="h6" className={classes.typography}>
                  How to use?
                </GradientTypography>
              </ButtonBase>
            </Paper>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.gridRow}
        spacing={2}
      >
        <Grid item>
          <CustomSubmitButton
            className={classes.button}
            onClick={handleAddToCart}
            data-cy="add-to-cart-button"
          >
            Add to Cart
          </CustomSubmitButton>
        </Grid>
        <Grid item>
          <Paper className={classes.insidePaper}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <GradientTypography variant="h6" className={classes.nominalText}>
                Nominal
              </GradientTypography>
              <Controller
                render={() => (
                  <Select
                    className={classes.select}
                    classes={{
                      root: classes.rootSelect,
                    }}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    value={nominal}
                    onChange={handleNominalChange}
                    variant="outlined"
                    data-cy="select-price-dropdown"
                  >
                    {
                      prices.map((option) => (
                        <MenuItem key={option.id} value={option} data-cy="select-price-item">
                          $
                          {option?.price}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
                name="nominal"
                control={control}
                defaultValue={nominal}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <HowToUseDialog isDialogOpened={isDialogOpened} onClose={handleClose} />
    </Grid>
  );
}

AddToCartSection.propTypes = {
  productName: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      price: PropTypes.string,
    }),
  ).isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  nominal: PropTypes.PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
  }).isRequired,
  setNominal: PropTypes.func.isRequired,
};
