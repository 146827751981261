import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Container, Button, Paper, Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GradientTypography from "../../GradientTypography/GradientTypography";
import frameThreeBackground from "../../../../static/images/homePage-images/carousel-frames/frame-one/rectangle.svg";
import ladyWithPhone from "../../../../static/images/homePage-images/carousel-frames/frame-three/ladyWithPhone.svg";
import phone from "../../../../static/images/homePage-images/carousel-frames/frame-three/phone.svg";
import frameThreeEllipse from "../../../../static/images/homePage-images/carousel-frames/frame-two/frameTwoEllipse.svg";
import signal from "../../../../static/images/homePage-images/carousel-frames/frame-three/signal.svg";
import mobileSignal from "../../../../static/images/homePage-images/carousel-frames/frame-three/mobileSignal.svg";

const useStyles = makeStyles((theme) => ({
  frameThreeBackground: {
    maxWidth: "none",
    backgroundImage: `url(${frameThreeBackground})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "57rem",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("670")]: {
      height: "50rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "45rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "38rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "30rem",
    },
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "0.1em",
    position: "absolute",
    zIndex: "2",
    lineHeight: "1",
    top: "5%",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("670")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.down("370")]: {
      fontSize: "2.5rem",
    },
  },
  mobileTitle: {
    fontSize: "3rem",
    fontFamily: "Roboto",
    lineHeight: "1",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("330")]: {
      fontSize: "2rem",
    },
  },
  mobileText: {
    fontSize: "2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("460")]: {
      fontSize: "1.5rem",
    },
  },
  ladyImage: {
    backgroundImage: `url(${ladyWithPhone})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "40rem",
    width: "100%",
    position: "absolute",
    transform: "translate(-12%,33%)",
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(-22%,55)",
    },
    [theme.breakpoints.down("860")]: {
      transform: "translate(-10%,43%)",
    },
  },
  phone: {
    backgroundImage: `url(${phone})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "55rem",
    width: "100%",
    position: "absolute",
    transform: "translate(27%,-5%)",
    zIndex: "1",
    [theme.breakpoints.down("860")]: {
      transform: "translate(29%,-8%)",
    },
    [theme.breakpoints.down("760")]: {
      transform: "translate(-5%,-5%) scale(1.2)",
    },
    [theme.breakpoints.down("670")]: {
      transform: "translate(-5%,-10%) scale(1.2)",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-5%,-17%) scale(1.4)",
    },
    [theme.breakpoints.down("470")]: {
      transform: "translate(-5%,-22%) scale(1.6)",
    },
    [theme.breakpoints.down("380")]: {
      transform: "translate(-6%,-22%) scale(2)",
    },
    [theme.breakpoints.down("300")]: {
      transform: "translate(-8%,-27%) scale(2)",
    },
  },
  ellipseImage: {
    backgroundImage: `url(${frameThreeEllipse})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "55rem",
    width: "100%",
    position: "absolute",
    transform: "translate(-31%,-3%)",
    [theme.breakpoints.down("860")]: {
      height: "40rem",
      transform: "translate(-31%,45%)",
    },
    [theme.breakpoints.down("860")]: {
      width: "0",
    },
  },
  paperHolder: {
    position: "absolute",
    zIndex: "3",
    top: "20%",
    left: "7%",
    [theme.breakpoints.down("1350")]: {
      top: "20%",
      left: "3%",
      width: "29rem",
    },
    [theme.breakpoints.down("1030")]: {
      top: "15%",
    },
    [theme.breakpoints.down("960")]: {
      top: "17%",
    },
    [theme.breakpoints.down("760")]: {
      top: "40%",
      right: "0",
      left: "0",
      marginLeft: "auto",
      marginRight: "auto",
      width: "24rem",
    },
    [theme.breakpoints.down("xs")]: {
      top: "35%",
    },
    [theme.breakpoints.down("460")]: {
      width: "16rem",
      top: "35%",
    },
  },
  paper: {
    maxWidth: "32rem",
    minHeight: "10rem",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "25rem",
    },
    [theme.breakpoints.down("760")]: {
      width: "20rem",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "auto",
    },
    [theme.breakpoints.down("460")]: {
      maxWidth: "14rem",
      padding: "1rem",
    },
  },
  emphasizeText: {
    fontSize: "3rem",
    fontWeight: "bold",
    fontFamily: "Roboto",
    lineHeight: "1.2",
  },
  text: {
    fontSize: "2.5rem",
    lineHeight: "1.2",
    fontFamily: "Roboto",
    color: theme.palette.darkerFoneca.main,
  },
  button: {
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    height: "5.7rem",
    padding: "0rem 2rem",
    alignSelf: "flex-end",
    position: "relative",
    marginTop: "1rem",
    [theme.breakpoints.down("550")]: {
      height: "4.5rem",
      padding: "0rem 1rem",
      borderRadius: "0.875rem",
    },
  },
  buttonTypography: {
    fontWeight: "bold",
  },
  signalImage: {
    height: "10.9rem",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "2rem",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "9.9rem",
    },
    [theme.breakpoints.down("860")]: {
      height: "8.9rem",
      top: "-25%",
    },
    [theme.breakpoints.down("760")]: {
      height: "6.9rem",
    },
    [theme.breakpoints.down("700")]: {
      top: "-20%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-20%",
      height: "5.9rem",
    },
    [theme.breakpoints.down("560")]: {
      top: "-15%",
      height: "5.9rem",
    },
    [theme.breakpoints.down("470")]: {
      top: "-13%",
      height: "4.5rem",
    },
    [theme.breakpoints.down("420")]: {
      height: "3.5rem",
      top: "-10%",
    },
    [theme.breakpoints.down("330")]: {
      height: "3rem",
      top: "-7%",
    },
  },
}));

export default function FrameThree({
  title, paperText, url,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesCustomScreen = useMediaQuery(theme.breakpoints.down("760"));

  return (
    <Container className={classes.frameThreeBackground}>
      <div className={classes.ellipseImage} />
      {!matchesCustomScreen ? <div className={classes.ladyImage} /> : null}
      <div className={classes.phone}>
        <img src={!matchesMobileScreen ? signal : mobileSignal} alt="signal" className={classes.signalImage} />
      </div>
      {!matchesMobileScreen && (
        <GradientTypography variant="h1" className={classes.title}>
          {title}
        </GradientTypography>
      )}
      <div className={classes.paperHolder}>
        <Paper className={classes.paper}>
          {!matchesMobileScreen ? (
            <Typography className={classes.text}>
              {paperText}
            </Typography>
          ) : (
            <GradientTypography className={classes.mobileTitle}>
              {title}
              <br />
              <span className={classes.mobileText}>
                {paperText}
              </span>
            </GradientTypography>
          )}
          <Button className={classes.button} onClick={() => history.push(url)}>
            <GradientTypography variant="h5" className={classes.buttonTypography}>Try It</GradientTypography>
          </Button>
        </Paper>
      </div>
    </Container>
  );
}

FrameThree.propTypes = {
  title: PropTypes.string.isRequired,
  paperText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
