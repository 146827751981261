/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../TablePaginationActions/TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  tableFooter: {
    color: theme.palette.darkerFoneca.main,
  },
}));

export default function CustomTablePagination({
  rows,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  page,
}) {
  const classes = useStyles();

  return (
    <TablePagination
      className={classes.tableFooter}
      rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]}
      count={rows ? rows?.length : 1}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: true,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
}

CustomTablePagination.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      completed_at: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
          price_id: PropTypes.number,
          product_name: PropTypes.string,
          product_id: PropTypes.number,
          anis: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
          })),
        }),
      ),
      paid_at: PropTypes.string,
      status: PropTypes.string,
      total: PropTypes.number,
      updated_at: PropTypes.string,
      user: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  rowsPerPage: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  page: PropTypes.number,
};
