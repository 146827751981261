/* eslint-disable prefer-arrow-callback */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  Dialog, AppBar, Paper, Toolbar, IconButton, Slide,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import GradientTypography from "../../Dumb/GradientTypography/GradientTypography";
import FonecaLogo from "../../../static/logo/Logo.svg";
import GeneralPaymentCard from "../../Dumb/Cards/PaymentCard/GeneralPaymentCard";
import CustomSubmitButton from "../../Dumb/CustomSubmitButton/CustomSubmitButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "#FEFEFE",
    boxShadow: theme.shadows[1],
    marginBottom: "1.5rem",
  },
  closeButton: {
    color: theme.palette.darkerFoneca.main,
  },
  toolbar: {
    minHeight: "5rem",
  },
  logoHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "2rem",
  },
  menuContainer: {
    padding: "0rem 1rem",
  },
  title: {
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bolder",
    width: "100%",
    textAlign: "center",
  },
  cardsContainer: {
    marginBottom: "3rem",
  },
  horizontalScroll: {
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.radiuses[2],
    overflowY: "hidden",
    whiteSpace: "nowrap",
    scrollbarWidth: "thin",
    scrollbarColor: "#10316D",
    [theme.breakpoints.down("400")]: {
      whiteSpace: "initial",
    },
  },
  paymentCardContainer: {
    margin: "0 1rem",
  },
  paper: {
    display: "flex",
    padding: "1.5rem",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    marginBottom: "1.5rem",
    [theme.breakpoints.down("370")]: {
      padding: "1rem",
      justifyContent: "space-between",
    },
  },
  typography: {
    fontSize: "1.7rem",
    fontWeight: "bolder",
    textAlign: "center",
    lineHeight: "1.2",
    [theme.breakpoints.down("370")]: {
      fontSize: "1.5rem",
      display: "flex",
      flex: "1",
      justifyContent: "center",
    },
  },
  saveButton: {
    color: theme.palette.tertiary.main,
    fontWeight: 600,
    fontSize: "1.5rem",
    height: "5rem",
    width: "9rem",
    borderRadius: "1rem",
    textTransform: "none",
    [theme.breakpoints.down("370")]: {
      width: "7rem",
    },
    [theme.breakpoints.down("285")]: {
      width: "5rem",
      height: "4rem",
    },
  },
}));

export default function PaymentInformation({
  isPaymentInfoDialogOpen, closePaymentInfoDialog, cards, selectedCardID,
  setSelectedCardID, setSelectedCard,
}) {
  const classes = useStyles();
  const selectedCard = useRef();
  const [savedCard, setSavedCard] = useState({});

  const handleClickOnCard = (card) => {
    selectedCard.current = card;
    setSavedCard(selectedCard.current);
    setSelectedCardID(card.id);
  };

  const handlerSaveCart = () => {
    setSelectedCard(savedCard);
    closePaymentInfoDialog();
  };

  return (
    <Dialog
      fullScreen
      open={isPaymentInfoDialogOpen}
      onClose={closePaymentInfoDialog}
      TransitionComponent={Slide}
      transitionDuration={500}
      classes={{
        root: classes.dialog,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={closePaymentInfoDialog} aria-label="close">
            <CloseIcon className={classes.closeButton} />
          </IconButton>
          <div className={classes.logoHolder}>
            <img src={FonecaLogo} className={classes.logo} alt="foneca-log" />
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.menuContainer}>
        <GradientTypography className={classes.title}>Payment Info</GradientTypography>
        <div className={classes.cardsContainer}>
          {
            cards
            && (
              <ScrollContainer
                horizontal
                className={cards?.length > 0
                  ? classes.horizontalScroll
                  : null}
              >
                {
                  cards.map((card) => (
                    <div className={classes.paymentCardContainer} key={card.id}>
                      <GeneralPaymentCard
                        deleteButton
                        cardNumber={`**** **** **** ${card.last4}`}
                        cardHolderName={card.name}
                        cardExpiryDate={`${card.exp_month} / ${(card.exp_year).toString().slice(-2)}`}
                        selectedCard={card.id === selectedCardID}
                        onClickHandler={() => handleClickOnCard(card)}
                      />
                    </div>
                  ))
                }
              </ScrollContainer>
            )
          }
        </div>
        <Paper className={classes.paper}>
          <GradientTypography className={classes.typography}>
            Choose a
            <br />
            card
          </GradientTypography>
          <CustomSubmitButton
            onClick={handlerSaveCart}
            className={classes.saveButton}
          >
            Save
          </CustomSubmitButton>
        </Paper>
      </div>
    </Dialog>
  );
}

PaymentInformation.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
      id: PropTypes.string,
      last4: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  isPaymentInfoDialogOpen: PropTypes.bool.isRequired,
  closePaymentInfoDialog: PropTypes.func.isRequired,
  selectedCardID: PropTypes.string.isRequired,
  setSelectedCardID: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
};
