import { loginAxios } from "../AccountCalls/AccountCalls";
import { loggedInAxios } from "../LoggedInCalls/LoggedInCalls";

// eslint-disable-next-line import/prefer-default-export
export async function getProductList() {
  const response = await loginAxios.get("/products/");
  return response;
}

export async function getCategoryList() {
  const response = await loginAxios.get("/categories/");
  return response;
}

export async function getProduct(id) {
  const response = await loginAxios.get(`/products/${id}/`);
  return response;
}

export async function getMyProducts() {
  const response = await loggedInAxios.get("/products/my_products/");
  return response;
}
