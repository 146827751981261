import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Person as PersonIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  ShoppingBasket as ShoppingBasketIcon,
  History as HistoryIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  activeMenuItemsButton: {
    boxShadow: theme.shadows[2],
    color: "#10316D !important",
    fontWeight: "500",
  },
  activeIcon: {
    color: "#10316D",
  },
}));

export default function SideMenuListItems() {
  const history = useHistory();
  const [activeButton, setActiveButton] = useState("nav-profile-button");
  const classes = useStyles();

  const findActiveButtonId = (event) => {
    const { id } = event.currentTarget;
    setActiveButton(id);
  };

  const handleProfileClick = (e) => {
    findActiveButtonId(e);
    history.push("/profile");
  };

  const handleBillingInformationClick = (e) => {
    findActiveButtonId(e);
    history.push("/billing");
  };

  const handleMyProductsClick = (e) => {
    findActiveButtonId(e);
    history.push("/my-products");
  };

  const handleMyOrdersClick = (e) => {
    findActiveButtonId(e);
    history.push("/orders");
  };

  const menuItems = [
    {
      text: "Profile",
      icon: <PersonIcon className={clsx(classes.icons, {
        [classes.activeIcon]: activeButton === "nav-profile-button",
      })}
      />,
      id: "nav-profile-button",
      clickHandler: handleProfileClick,
    },
    {
      text: "Billing Information",
      icon: <ChromeReaderModeIcon
        className={clsx(classes.icons, {
          [classes.activeIcon]: activeButton === "nav-billing-information-button",
        })}
      />,
      id: "nav-billing-information-button",
      clickHandler: handleBillingInformationClick,
    },
    {
      text: "My Products",
      icon: <ShoppingBasketIcon
        className={clsx(classes.icons, {
          [classes.activeIcon]: activeButton === "nav-my-products-button",
        })}
      />,
      id: "nav-my-products-button",
      clickHandler: handleMyProductsClick,
    },
    {
      text: "My orders",
      icon: <HistoryIcon className={clsx(classes.icons, {
        [classes.activeIcon]: activeButton === "nav-my-orders-button",
      })}
      />,
      id: "nav-my-orders-button",
      clickHandler: handleMyOrdersClick,
    },
  ];

  const getListItem = (listItemInfo) => {
    const {
      text, icon, id, clickHandler,
    } = listItemInfo;
    return (
      <ListItem
        button
        onClick={clickHandler}
        id={id}
        key={id}
        className={clsx(classes.listItem, activeButton === id && classes.activeMenuItemsButton)}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
        />
      </ListItem>
    );
  };

  return (
    menuItems.map((item) => getListItem(item))
  );
}
