import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  TableCell, TableRow, List, ListItem, Button,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import OrderStatusTooltip from "../../OrderStatusTooltip/OrderStatusTooltip";
import CustomTooltip from "../../../../Components/Dumb/CustomTooltip/CustomTooltip";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    color: theme.palette.darkerFoneca.main,
    padding: "10px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  list: {
    padding: "0rem",
  },
  listItem: {
    borderBottom: "1px solid #e0e0e0",
    justifyContent: "center",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  button: {
    background: theme.palette.fonecaLogoGradient.main,
    color: theme.palette.tertiary.main,
    fontWeight: 500,
    height: "3.5rem",
    width: "5.5rem",
    borderRadius: theme.radiuses[1],
    fontSize: "1rem",
  },
}));

export default function Row({ row, openAccessNumbersDialogHandler }) {
  const classes = useStyles();
  const {
    id, items: products, status, created_at, total, type,
  } = row;

  const transformPrice = (price) => price.toFixed(2).toString();

  return (
    <TableRow hover key={id}>
      <StyledTableCell scope="row" align="center" style={{ minWidth: "4rem" }}>{id}</StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "10rem" }}>
        <List className={classes.list}>
          {products.map((product, index) => (
            <ListItem className={classes.listItem} key={uuidv4()}>
              {index + 1}
              .
              {" "}
              {product.product_name}
            </ListItem>
          ))}
        </List>
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "7rem" }}>
        <List className={classes.list}>
          {products.map((product, index) => (
            <ListItem className={classes.listItem} key={uuidv4()}>
              {index + 1}
              .
              {" "}
              {transformPrice(product.price).replace(/^/, "$ ")}
            </ListItem>
          ))}
        </List>
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "7rem" }}>
        <CustomTooltip title="Check the access numbers" placement="top">
          <Button
            className={classes.button}
            onClick={() => openAccessNumbersDialogHandler(products)}
          >
            Check
          </Button>
        </CustomTooltip>
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ minWidth: "10rem", cursor: "pointer" }}
      >
        <OrderStatusTooltip status={status} />
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "7rem" }}>
        $
        {" "}
        {transformPrice(total)}
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "4rem" }}>
        {products.length}
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "5rem" }}>
        {created_at.split("T")[0]}
      </StyledTableCell>
      <StyledTableCell align="center" style={{ minWidth: "5rem" }}>
        {type}
      </StyledTableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    completed_at: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.number,
    paid_at: PropTypes.string,
    status: PropTypes.string,
    total: PropTypes.number,
    updated_at: PropTypes.string,
    user: PropTypes.number,
    type: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number,
        price_id: PropTypes.number,
        product_name: PropTypes.string,
        product_id: PropTypes.number,
        anis: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
        })),
      }),
    ),
  }).isRequired,
  openAccessNumbersDialogHandler: PropTypes.func.isRequired,
};
