import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#10316D",
    },
    secondary: {
      main: "#000000",
    },
    tertiary: {
      main: "#ffffff",
    },
    fonecaLogoGradient: {
      main: "linear-gradient(180deg, #10316D 0%, #295EBD 100%)",
    },
    orangeLogo: {
      main: "#E30016",
    },
    orangeFoneca: {
      main: "#EB4008",
    },
    darkerFoneca: {
      main: "#10316D",
    },
    chip: {
      main: "#586F99",
    },
    pageBG: {
      main: "#FAFAFA",
    },
    green: {
      main: "green",
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
  shadows: [
    "none",
    "0px 15px 60px rgba(16, 49, 109, 0.25)", // styled shadow
    "0px 15px 60px rgba(16, 49, 109, 0.1)", // styled shadow light
    ...Array(22).fill("none"),
  ],
  radiuses: [
    "none",
    "20px",
    "30px",
  ],
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "1rem",
      },
    },
  },
});

export default responsiveFontSizes(theme);
