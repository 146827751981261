import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  ListItem,
  Button,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";

import { getMyProducts } from "../../../api/ServicesCalls/ServicesCalls";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "350px",
    padding: "1rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 11rem)",
      padding: "2rem",
    },
    [theme.breakpoints.down(300)]: {
      width: "calc(100vw - 9rem)",
      padding: "1rem",
    },
    margin: "10px",
    boxShadow: theme.shadows[2],
    width: "100%",
  },
  list: {
    padding: "1rem 1rem 0rem 1rem",
    background: "#fff",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    maxHeight: "17rem",
    outline: "none",
    overflow: "auto",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-around",
    padding: "1rem",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
    flexDirection: "row",
    [theme.breakpoints.down("xs", "sm")]: {
      flexDirection: "column",
    },
  },
  imageHolder: {
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
    width: "8rem",
  },
  image: {
    width: "100%",
    borderRadius: theme.radiuses[1],
  },
  nameTypography: {
    textAlign: "center",
    margin: "4px",
    display: "flex",
    flexDirection: "column",
    fontWeight: "bolder",
    [theme.breakpoints.down("xs", "sm")]: {
      fontSize: "1.6rem",
    },
  },
  checkoutButton: {
    background: theme.palette.fonecaLogoGradient.main,
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: theme.radiuses[1],
    fontSize: "1.3rem",
    padding: "2rem 1rem",
    height: "3rem",
    width: "9rem",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  heading: {
    textAlign: "center",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  moreButton: {
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[2],
    fontSize: "1.3rem",
    padding: "2rem 1rem",
    height: "3rem",
    width: "8rem",
  },
  moreButtonTypography: {
    fontSize: "1.4rem",
    fontWeight: 500,
  },
}));

export default function MyProductsSummary({ maxItemsToShow }) {
  const classes = useStyles();
  const history = useHistory();
  const { data: myProducts } = useQuery("myProducts", getMyProducts, {
    select: (response) => response.data,
    retry: false,
  });

  return (
    <Paper className={classes.paper}>
      <GradientTypography className={classes.heading}>
        My Products
      </GradientTypography>
      {
        !myProducts?.length && (
        <GradientTypography
          className={classes.heading}
        >
          No Products To Show
        </GradientTypography>
        )
      }
      {myProducts?.reverse().map(({ product: { id, image, name }, pin, rechargeable }, index) => (
        index <= (maxItemsToShow - 1)
        && (
        <ListItem key={uuidv4()} className={classes.listItem}>
          <div className={classes.imageHolder}>
            <img
              className={classes.image}
              src={`${process.env.REACT_APP_API_URL}${image}`}
              alt="product"
            />
          </div>
          <GradientTypography className={classes.nameTypography}>
            {name}
          </GradientTypography>
          {rechargeable
          && (
          <Button
            onClick={() => history.push(`/recharge/${id}/${pin}`)}
            className={classes.checkoutButton}
          >
            Recharge
          </Button>
          )}
        </ListItem>
        )
      ))}
      {
        myProducts?.length > maxItemsToShow
        && (
        <Button className={classes.moreButton}>
          <GradientTypography onClick={() => history.push("/my-products")} className={classes.moreButtonTypography}>
            More...
          </GradientTypography>
        </Button>
        )
      }

    </Paper>
  );
}

MyProductsSummary.propTypes = {
  maxItemsToShow: PropTypes.number.isRequired,
};
