import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import ProfilePage from "../ProfilePage/ProfilePage";
import BillingInformationPage from "../BillingInformationPage/BillingInformationPage";
import MyProductsPage from "../MyProductsPage/MyProductsPage";
import OrdersHistoryPage from "../OrdersHistoryPage/OrdersHistoryPage";
import { getUserData } from "../../api/ProfileCalls/ProfileCalls";
import { UserContext } from "../../Context/UserContext/UserContext";

export default function LoggedInEntryPage() {
  const { userState, setUserState } = useContext(UserContext);
  const currentUrl = useLocation();

  const { isLoading } = useQuery("profileData", getUserData, {
    onSuccess: (response) => {
      setUserState(response.data);
    },
  });

  const getComponentByPath = () => {
    switch (currentUrl.pathname) {
    case "/profile":
      return (
        <ProfilePage
          isLoading={isLoading}
          username={userState.username}
        />
      );
    case "/billing":
      return (
        <BillingInformationPage />
      );
    case "/my-products":
      return (
        <MyProductsPage />
      );
    case "/orders":
      return (
        <OrdersHistoryPage />
      );
    default:
      return <></>;
    }
  };

  return (
    <HeaderFooterContainer>
      {
        getComponentByPath()
      }
    </HeaderFooterContainer>
  );
}
