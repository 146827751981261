import React from "react";
import PropTypes from "prop-types";
import {
  Dialog, Button, makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  paper: {
    borderRadius: theme.radiuses[2],
    padding: theme.spacing(1),
  },
  continueShoppingButton: {
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    height: "3rem",
    fontSize: "1.2rem",
    borderRadius: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  checkoutButton: {
    background: theme.palette.fonecaLogoGradient.main,
    color: "white",
    height: "3rem",
    fontSize: "1.2rem",
    borderRadius: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    marginTop: "1rem",
  },
}));

export default function ContinueShoppingDialog({ open, setOpen }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        elevation: 0,
      }}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <Button classes={{ root: classes.continueShoppingButton }} onClick={() => history.push("/services")}>Continue Shopping</Button>
      <Button className={classes.checkoutButton} onClick={() => history.push("/confirm-and-pay")} id="dialog-checkout-button">Checkout</Button>
    </Dialog>
  );
}

ContinueShoppingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
