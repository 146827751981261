import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Grid, Paper, IconButton,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { Close as DeleteIcon } from "@material-ui/icons";
import GradientTypography from "../../../Dumb/GradientTypography/GradientTypography";
import { useShoppingCart } from "../../../../hooks/useShoppingCart/useShoppingCart";
import { DialogContext } from "../../../../Context/DialogContext/DialogContext";
import ProductANIs from "../../../Dumb/ProductANIs/ProductANIs";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 600,
  },
  cartItem: {
    boxShadow: theme.shadows[2],
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  image: {
    maxWidth: "150px",
    width: "100%",
    borderRadius: theme.radiuses[1],
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      maxWidth: "110px",
    },
  },
  innerPaper: {
    padding: theme.spacing(2, 3),
    margin: theme.spacing(2, 0, 1, 0),
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(3, 1),
    },
  },
  outsidePaper: {
    padding: theme.spacing(1),
  },
}));

export default function CartItemCard({ product }) {
  const classes = useStyles();
  const { removeProduct } = useShoppingCart();
  const { openDialog, closeDialog } = useContext(DialogContext);

  const handleRemoveFromCart = (item) => {
    openDialog("Delete item from cart",
      "Are you sure you want to remove this item?",
      () => {
        removeProduct(item);
        closeDialog();
      },
      "Confirm",
      "confirm-button");
  };

  return (
    <Paper className={classes.cartItem}>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.outsidePaper}>
        <Grid item xs={6} md={5} lg={6}>
          <img src={product.image} alt={product.name} className={classes.image} />
        </Grid>
        <Grid item xs={4} md={6} lg={5}>
          <GradientTypography variant="h6" className={classes.typography}>
            {product.name}
          </GradientTypography>
          <GradientTypography variant="h6" className={classes.typography}>
            $
            {product.price.price}
          </GradientTypography>
        </Grid>
        <Grid item xs={2} md={1} lg={1} style={{ textAlign: "center" }}>
          <IconButton onClick={() => handleRemoveFromCart(product)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {
        product.anis?.length > 0 && (
          <Grid item>
            <ProductANIs anis={product.anis} />
          </Grid>
        )
      }
    </Paper>
  );
}

CartItemCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.shape({
      id: PropTypes.number,
      price: PropTypes.string,
    }),
    anis: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
  }).isRequired,
};
