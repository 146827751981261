import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  Paper,
  Grid,
} from "@material-ui/core";
import {
  ArrowDropDown as ExpandMoreIcon,
  ArrowDropUp as ExpandLessIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import PropTypes from "prop-types";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import GradientButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import ProductANIs from "../../../Components/Dumb/ProductANIs/ProductANIs";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 240,
    width: "100%",
    borderRadius: "2rem",
    boxShadow: theme.shadows[1],
    marginBottom: "2rem",
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    [theme.breakpoints.down(560)]: {
      width: "70%",
    },
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: "1rem",
  },
  scrollableTable: {
    width: "100%",
    overflowX: "auto",
  },
  cardHeader: {
    width: "100%",
    borderRadius: "1rem",
    textAlign: "center",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
  },
  cardHeaderText: {
    fontSize: "1.8rem",
  },
  image: {
    width: "100%",
    borderRadius: "1rem",
  },
  cardContent: {
    padding: 0,
  },
  rechargeButton: {
    fontSize: "1.25rem",
    color: "white",
    textTransform: "none",
    width: "100%",
  },
  expandShrinkTypography: {
    fontSize: "1.25rem",
    fontWeight: 300,
  },
  expandShrinkButton: {
    background: "white",
    boxShadow: theme.shadows[2],
  },
  expandedTabs: {
    borderRadius: "1.5rem",
    display: "flex",
    justifyContent: "space-around",
    padding: "0.2rem",
    boxShadow: theme.shadows[2],
    marginTop: "1rem",
  },
  expandedTabsText: {
    display: "grid",
    placeItems: "center",
    fontSize: "1.2rem",
    margin: "5px",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  expandedCardContent: {
    marginTop: "1rem",
    padding: 0,
    paddingBottom: "0px !important",
  },
  textCentered: {
    textAlign: "center",
  },
}));

export default function MyProductCard({
  id, productName, pin, purchasedDate, lastRechargedDate, anis, image, rechargeable,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const handleExpandClick = async () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <Paper className={classes.cardHeader}>
        <GradientTypography className={classes.cardHeaderText}>
          {productName}
        </GradientTypography>
      </Paper>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img className={classes.image} src={`${process.env.REACT_APP_API_URL}${image}`} alt="product" />
          </Grid>
          {rechargeable
          && (
          <Grid item xs={6}>
            <GradientButton onClick={() => history.push(`/recharge/${id}/${pin}`)} className={classes.rechargeButton}>
              Recharge
            </GradientButton>
          </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {expanded
          ? (
            <GradientTypography className={classes.expandShrinkTypography}>
              Shrink
            </GradientTypography>
          )
          : (
            <GradientTypography className={classes.expandShrinkTypography}>
              Expand
            </GradientTypography>
          )}
        <IconButton
          className={classes.expandShrinkButton}
          id="preview-group-branches-button"
          color="primary"
          onClick={handleExpandClick}
        >
          {
            expanded
              ? <ExpandLessIcon />
              : <ExpandMoreIcon />
          }
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.expandedCardContent}>

          {/* PIN */}
          <Paper className={classes.expandedTabs}>
            <Typography className={classes.expandedTabsText}>PIN</Typography>
            <Typography className={classes.expandedTabsText}>-</Typography>
            <Typography className={clsx(classes.expandedTabsText, classes.textCentered)}>
              {pin}
            </Typography>
          </Paper>

          {/* Purchased */}
          <Paper className={classes.expandedTabs}>
            <Typography className={classes.expandedTabsText}>Purchased</Typography>
            <Typography className={classes.expandedTabsText}>-</Typography>
            <Typography className={clsx(classes.expandedTabsText, classes.textCentered)}>
              {purchasedDate ? format(new Date(purchasedDate), "PP") : "None"}
            </Typography>
          </Paper>

          {/* Last Recharged */}
          <Paper className={classes.expandedTabs}>
            <Typography className={classes.expandedTabsText}>Last Recharged</Typography>
            <Typography className={classes.expandedTabsText}>-</Typography>
            <Typography className={clsx(classes.expandedTabsText, classes.textCentered)}>
              {lastRechargedDate ? format(new Date(lastRechargedDate), "PP") : "None"}
            </Typography>
          </Paper>

          {/* Caller IDs */}
          <ProductANIs anis={anis} />

        </CardContent>
      </Collapse>
    </Card>
  );
}

MyProductCard.propTypes = {
  id: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  purchasedDate: PropTypes.string.isRequired,
  lastRechargedDate: PropTypes.string,
  image: PropTypes.string.isRequired,
  anis: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  rechargeable: PropTypes.bool,
};
