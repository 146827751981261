import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

export default function PrivateRoute({ component: Component, ...rest }) {
  const [cookies] = useCookies([]);
  const adminAccessToken = cookies.access_token;
  const adminRefreshToken = cookies.refresh_token;

  const impersonateUser = () => {
    if (adminAccessToken && adminRefreshToken) {
      localStorage.clear();
      localStorage.setItem("access_token", adminAccessToken);
      localStorage.setItem("refresh_token", adminRefreshToken);
    }
  };

  return (
    <Route
      {...rest}
      {...impersonateUser()}
      render={(props) => (
        localStorage.getItem("access_token") ? <Component {...props} /> : <Redirect to="/" exact />
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
};
