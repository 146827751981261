import React, {
  useContext, useState,
} from "react";
import clsx from "clsx";
import {
  Grid, Paper, Typography, CircularProgress, Container, Box, Backdrop,
} from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  CardNumberElement, useElements, useStripe,
} from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";
import { useShoppingCart } from "../../hooks/useShoppingCart/useShoppingCart";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import { getUserData } from "../../api/ProfileCalls/ProfileCalls";
import { createOrder, getUserCards } from "../../api/OrdersCalls/OrdersCalls";
import PaymentInformation from "../../Components/Smart/PaymentInformation/PaymentInformation";
import InitialPaymentCard from "../../Components/Dumb/Cards/PaymentCard/InitialPaymentCard";
import NewCardDetailsForm from "../../Components/Smart/NewCardDetailsForm/NewCardDetailsForm";
import PaymentInfoPanel from "../../Components/Smart/PaymentInfoPanel/PaymentInfoPanel";
import UsedCards from "../../Components/Smart/UsedCards/UsedCards";
import CartItems from "../../Components/Smart/CartItems/CartItems";
import BillingInfoPanel from "../../Components/Smart/BillingInfoPanel/BillingInfoPanel";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    margin: "5rem auto",
    minHeight: "calc(100vh - 12rem)",
    [theme.breakpoints.down("xs")]: {
      margin: "3rem auto",
    },
    [theme.breakpoints.down("281")]: {
      padding: "0rem",
    },
  },
  title: {
    fontWeight: 600,
    textAlign: "center",
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: "3rem",
  },
  typography: {
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      textAlign: "left",
    },
  },
  paper: {
    display: "grid",
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 3),
      margin: theme.spacing(0, 1, 4),
    },
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    minHeight: "3rem",
  },
  totalAndPayContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0rem 1.5rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0.5rem",
    },
  },
  paperTotalAndPay: {
    width: "50%",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  totalAmountContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
  typographyTotal: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    textAlign: "center",
  },
  payButton: {
    color: theme.palette.tertiary.main,
    fontWeight: 600,
    height: "5rem",
    width: "9rem",
    borderRadius: "1rem",
    fontSize: "2rem",
  },

  initialPaymentCardContainer: {
    padding: "0rem 0.5rem",
  },
  infoCardContainer: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 3),
    },
  },
  addCardContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
  },
  addCardButton: {
    color: theme.palette.tertiary.main,
    fontWeight: 600,
    fontSize: "1.5rem",
    height: "5rem",
    width: "9rem",
    borderRadius: "1rem",
    textTransform: "none",
    [theme.breakpoints.down("370")]: {
      lineHeight: "1.3",
    },
  },
}));

export default function ConfirmAndPayPage() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isPaymentInfoDialogOpen, setIsPaymentInfoDialogOpen] = useState(false);
  const {
    getProductsCount, getAllProducts, getTotal, removeAllProducts,
  } = useShoppingCart();
  const { openSnackbar } = useContext(SnackbarContext);
  const count = getProductsCount();
  const total = getTotal();
  const products = getAllProducts();
  const stripe = useStripe();
  const elements = useElements();
  const [isChecked, setIsChecked] = useState(true);
  const [isAddNewCardClicked, setIsNewCardClicked] = useState(false);
  const [selectedCardID, setSelectedCardID] = useState("");
  const [selectedCard, setSelectedCard] = useState({});
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
  } = useForm({ reValidateMode: "onSubmit" });

  const removePlusSignFromPhoneNumber = (number) => number.substring(1);

  const handleClickAddNewCard = () => {
    setIsNewCardClicked(true);
  };

  const handleSaveCardForFutureCheck = (value) => {
    setIsChecked(value);
  };

  const closeAddNewCardForm = (value) => {
    setIsNewCardClicked(value);
  };

  const handleClickOnCard = (card) => {
    setSelectedCard(card);
    setSelectedCardID(card.id);
  };

  const { data: status, isLoading } = useQuery("billingInfo", getUserData, {
    select: (response) => response.data.status,
    retry: false,
    onSuccess: () => {
      if (status === "UNVERIFIED") {
        history.push("/billing");
        openSnackbar(
          "info",
          "Whoops.. You have no billing info or you need to be verified by an administrator.",
          "billing-information-login-info-snackbar",
        );
      }
    },
    onError: (error) => {
      if (error.response.status === 401) {
        history.push("/login");
        openSnackbar("info", "You need to log in to proceed.", "user-not-logged-in-snackbar");
      }
    },
  });

  const handleEditPaymentInformationButton = () => (
    status === "UNVERIFIED"
      ? openSnackbar(
        "info",
        "Whoops.. You have no billing info or you need to be verified by an administrator.",
        "billing-information-login-info-snackbar",
      )
      : setIsNewCardClicked(true));

  const { data: cards } = useQuery("getUserCards", getUserCards, {
    select: (response) => response.data,
    retry: false,
    onSuccess: (response) => {
      setSelectedCard(response[0]);
      setSelectedCardID(response[0]?.id);
    },
  });

  const sendStripeRequest = async (paymentIntent) => {
    setIsBackdropOpened(true);
    const result = await stripe.confirmCardPayment(paymentIntent, {
      payment_method: isAddNewCardClicked ? ({
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: getValues("card_holder_name"),
          address: {
            postal_code: getValues("zip"),
          },
        },
      }) : selectedCard.id,
      setup_future_usage: isChecked ? "on_session" : null,
    });

    setIsBackdropOpened(false);

    if (result.error) {
      openSnackbar("error", result.error.message, "payment-error-snackbar");
    } else {
      openSnackbar("success", "Your payment has been processed.", "payment-success-snackbar");
      history.push("/orders");
      removeAllProducts();
      localStorage.removeItem("checkout");
    }
  };

  const {
    mutate: createOrderMutation,
    status: orderCreationStatus,
  } = useMutation(
    createOrder,
    {
      onSuccess: (response) => {
        openSnackbar("success", "Your order is being processed...", "order-creation-success-snackbar");
        if (selectedCard === undefined && !isAddNewCardClicked) {
          openSnackbar("error", "You need to provide payment info!", "empty-cart-error-snackbar");
        } else {
          sendStripeRequest(response.data.stripe_intent);
        }
      },
      onError: (error) => {
        if (error.response.data.items[0].anis[0]) {
          openSnackbar("error", "You have duplicate caller IDs!", "order-creation-error-snackbar");
        } else {
          openSnackbar("error", "You need to fill both billing and payment information!", "order-creation-error-snackbar");
        }
      },
    },
  );

  const sendOrderToBackend = () => {
    const order = products.map((product) => ({
      price: product.price.id,
      product: product.id,
      anis: product.anis.map((ani) => removePlusSignFromPhoneNumber(ani.label)),
    }));

    if (count < 1) {
      openSnackbar("error", "Your cart is empty!", "empty-cart-error-snackbar");
    } else {
      createOrderMutation({ items: order });
    }
  };

  const onSubmit = () => {
    sendOrderToBackend();
  };

  const editCardHandler = () => {
    setIsPaymentInfoDialogOpen(true);
  };

  const closePaymentInfoDialog = () => {
    setIsPaymentInfoDialogOpen(false);
  };

  const showWithPaymentInfoComponents = () => (
    isAddNewCardClicked ? (
      <NewCardDetailsForm
        register={register}
        closeAddNewCardForm={closeAddNewCardForm}
        isChecked={isChecked}
        handleSaveCardForFutureCheck={handleSaveCardForFutureCheck}
      />
    ) : (
      <UsedCards
        cards={cards}
        selectedCardID={selectedCardID}
        handleClickOnCard={handleClickOnCard}
      />
    )
  );

  const showNoPaymentInfoComponents = () => (
    isAddNewCardClicked ? (
      <NewCardDetailsForm
        register={register}
        closeAddNewCardForm={closeAddNewCardForm}
        isChecked={isChecked}
        handleSaveCardForFutureCheck={handleSaveCardForFutureCheck}
      />
    ) : (
      <PaymentInfoPanel
        handleEditButton={handleEditPaymentInformationButton}
      />
    )
  );

  return (
    <HeaderFooterContainer>
      <Container className={classes.container} maxWidth="lg">
        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <GradientTypography variant="h4" className={classes.title}>
              Confirm & Pay
            </GradientTypography>
            <Typography variant="h6" className={classes.subtitle} align="center">
              {count}
              {" "}
              {count === 1 ? "item" : "items"}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} lg={6} style={{ marginBottom: "2rem" }}>
              <CartItems products={products} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
            >
              <BillingInfoPanel
                isVerified={status === "VERIFIED"}
                handleEditButton={() => history.push("/billing")}
              />

              {/* Payment Information Starts Here */}
              <form onSubmit={handleSubmit(onSubmit)}>
                {
                  matchesMobileScreen && cards?.length > 0 ? (
                    <div className={classes.initialPaymentCardContainer}>
                      <InitialPaymentCard
                        selectedCard
                        editCardHandler={editCardHandler}
                        cardNumber={`**** **** **** ${selectedCard.last4}`}
                        cardHolderName={selectedCard.name}
                        cardExpiryDate={`${selectedCard.exp_month} / ${(selectedCard.exp_year)?.toString().slice(-2)}`}
                      />
                      <PaymentInformation
                        isPaymentInfoDialogOpen={isPaymentInfoDialogOpen}
                        closePaymentInfoDialog={closePaymentInfoDialog}
                        selectedCardID={selectedCardID}
                        setSelectedCardID={setSelectedCardID}
                        setSelectedCard={setSelectedCard}
                        cards={cards}
                      />
                      {
                        isAddNewCardClicked
                        && (
                          <Paper className={classes.paper}>
                            <NewCardDetailsForm
                              register={register}
                              closeAddNewCardForm={closeAddNewCardForm}
                              isChecked={isChecked}
                              handleSaveCardForFutureCheck={handleSaveCardForFutureCheck}
                            />
                          </Paper>
                        )
                      }
                    </div>
                  )
                    : (
                      <Paper className={classes.paper}>
                        {
                          cards?.length > 0
                            ? showWithPaymentInfoComponents()
                            : showNoPaymentInfoComponents()
                        }
                      </Paper>
                    )
                }
                {
                  cards?.length > 0 && !isAddNewCardClicked
                  && (
                    <Paper className={classes.paper}>
                      <Grid container className={classes.addCardContainer} justify="space-between" alignItems="center">
                        <GradientTypography variant="h5" className={clsx(classes.typography, classes.infoCardContainer)}>
                          Pay with new card
                        </GradientTypography>
                        <CustomSubmitButton
                          disabled={!stripe}
                          onClick={handleClickAddNewCard}
                          className={classes.addCardButton}
                        >
                          Add Card
                        </CustomSubmitButton>
                      </Grid>
                    </Paper>
                  )
                }
                <Box
                  className={classes.totalAndPayContainer}
                >
                  <Paper className={classes.paperTotalAndPay}>
                    <Box className={classes.totalAmountContainer}>
                      <GradientTypography variant="h5" className={classes.typographyTotal}>
                        Total
                      </GradientTypography>
                      <Typography variant="h6" className={classes.typographyTotal}>
                        $
                        {total}
                      </Typography>
                    </Box>
                    <CustomSubmitButton
                      disabled={!stripe}
                      type="submit"
                      className={classes.payButton}
                      data-cy="pay-button"
                    >
                      {
                        (orderCreationStatus === "loading" && isLoading) ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : ("Pay")
                      }
                    </CustomSubmitButton>
                  </Paper>
                </Box>
              </form>
              {/* Payment Information Ends Here */}
              <Backdrop className={classes.backdrop} open={isBackdropOpened}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </HeaderFooterContainer>
  );
}
