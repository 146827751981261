import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid, Tooltip, TextField,
  FormControlLabel, Checkbox, Box,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos as ArrowBackIosIcon } from "@material-ui/icons";
import {
  CardNumberElement, CardExpiryElement, CardCvcElement,
} from "@stripe/react-stripe-js";
import GradientTypography from "../../Dumb/GradientTypography/GradientTypography";
import StripeInput from "../StripeInput/StripeInput";

const useStyles = makeStyles({
  typography: {
    fontWeight: 600,
    textAlign: "center",
  },
  checkboxContainer: {
    margin: "1rem 0",
  },
  addYourCardTitle: {
    margin: "1.5rem 0rem",
  },
  arrowBackContainer: {
    margin: "1rem 0 0 1rem",
  },
  arrowBackIcon: {
    display: "flex",
    padding: "0.5rem",
    cursor: "pointer",
  },
});

export default function NewCardDetailsForm({
  register, closeAddNewCardForm, isChecked, handleSaveCardForFutureCheck,
}) {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center" direction="column">
      <Box style={{ display: "flex", width: "100%" }}>
        <Tooltip title="Toggle back">
          <ArrowBackIosIcon
            className={classes.arrowBackIcon}
            color="primary"
            onClick={() => closeAddNewCardForm(false)}
          />
        </Tooltip>
        <GradientTypography
          style={{ flex: "1" }}
          variant="h4"
          className={clsx(classes.typography, classes.addYourCardTitle)}
        >
          Add Your Card
        </GradientTypography>
      </Box>
      <Grid item>
        <TextField
          required
          fullWidth
          label="Card Holder Name"
          name="card_holder_name"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register}
          variant="outlined"
          margin="normal"
          data-cy="card-holder-name-field"
        />
        <TextField
          required
          fullWidth
          label="Card Number"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
            },
          }}
          variant="outlined"
          margin="normal"
        />
        <TextField
          required
          fullWidth
          label="Expiration Date"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
          variant="outlined"
          margin="normal"
        />
        <TextField
          required
          fullWidth
          label="CVC"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
          variant="outlined"
          margin="normal"
        />
        <TextField
          required
          fullWidth
          label="ZIP"
          name="zip"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register}
          variant="outlined"
          margin="normal"
          data-cy="zip-field"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          className={classes.checkboxContainer}
          control={(
            <Checkbox
              checked={isChecked}
              onChange={() => handleSaveCardForFutureCheck(!isChecked)}
              name="checkedB"
              color="primary"
            />
          )}
          label={(
            <GradientTypography variant="subtitle1" className={classes.typography}>
              I want to save my card for future usage
            </GradientTypography>
          )}
        />
      </Grid>
    </Grid>
  );
}

NewCardDetailsForm.propTypes = {
  register: PropTypes.func.isRequired,
  closeAddNewCardForm: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleSaveCardForFutureCheck: PropTypes.func.isRequired,
};
