import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import Testimonials from "./Testimonials/Testimonials";
import Carousel from "./Carousel/Carousel";
import LandingPageAboutSection from "./AboutSection/AboutSection";
import RatesSlider from "./RatesSlider/RatesSlider";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "3rem",
  },
  carouselContainer: {
    height: "56rem",
    [theme.breakpoints.down("670")]: {
      height: "53rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "47rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "40rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "31rem",
    },
  },
  aboutSection: {
    position: "relative",
    height: "60rem",
    overflow: "hidden",
    marginTop: "3rem",
    maxWidth: "none",
    padding: "3rem 0rem 0rem 10rem",
    [theme.breakpoints.down("1280")]: {
      padding: "3rem 0rem 0rem 5rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "52rem",
    },
    [theme.breakpoints.down("325")]: {
      height: "47rem",
      marginTop: "1rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "40rem",
    },
  },
  testimonials: {
    margin: "3rem auto",
    maxWidth: "none",
  },
}));

export default function LandingPage() {
  const classes = useStyles();

  return (
    <HeaderFooterContainer>
      <div className={classes.carouselContainer}>
        <Carousel />
      </div>
      <Container className={classes.aboutSection}>
        <LandingPageAboutSection />
      </Container>
      <Container className={classes.testimonials}>
        <RatesSlider />
      </Container>
      <Container className={classes.testimonials}>
        <Testimonials />
      </Container>
    </HeaderFooterContainer>
  );
}
