import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import "./GradientTypography.css";

const CustomGradientTypography = withStyles((theme) => ({
  root: {
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    background: theme.palette.fonecaLogoGradient.main,
  },
}))(Typography);

export default function GradientTypography({ children, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomGradientTypography {...rest}>
      {children}
    </CustomGradientTypography>
  );
}

GradientTypography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
