import axios from "axios";
// eslint-disable-next-line import/no-cycle
import { getUserData } from "../ProfileCalls/ProfileCalls";

export const loginAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export async function login(credentials) {
  const response = await loginAxios.post("/accounts/login/", credentials);
  const { refresh, access } = response.data;
  localStorage.setItem("access_token", access);
  localStorage.setItem("refresh_token", refresh);
  return response;
}

export async function loginWithStatus(credentials) {
  await login(credentials);
  const statusResponse = await getUserData();
  return statusResponse;
}

export async function signup(signupData) {
  const response = await loginAxios.post("/accounts/register/", signupData);
  return response;
}

export async function verify(verifyData) {
  const response = await loginAxios.post("/accounts/verify-registration/", verifyData);
  return response;
}

export async function sendResetPasswordLink(username) {
  const response = await loginAxios.post("/accounts/send-reset-password-link/", username);
  return response;
}

export async function changePassword(newData) {
  const response = await loginAxios.post("/accounts/reset-password/", newData);
  return response;
}

export async function verifyRecaptcha(recaptchaData) {
  const response = await loginAxios.post("/accounts/recaptcha/", recaptchaData);
  return response;
}
