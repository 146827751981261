import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, CardContent, Typography, List, ListItem, Box, Button,
} from "@material-ui/core/";
import { v4 as uuidv4 } from "uuid";
import OrderStatusTooltip from "../OrderStatusTooltip/OrderStatusTooltip";
import CustomTooltip from "../../../Components/Dumb/CustomTooltip/CustomTooltip";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import "./OrderCard.css";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("650")]: {
      minWidth: 220,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 275,
    },
    [theme.breakpoints.down("325")]: {
      minWidth: 245,
    },
    [theme.breakpoints.down("285")]: {
      minWidth: 210,
    },
  },
  cardContent: {
    height: "91%",
    overflow: "auto",
  },
  title: {
    fontSize: "2.5rem",
    textAlign: "center",
    fontWeight: "bolder",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem 0rem",
    borderBottom: "1px solid #10316D",
    minHeight: "2.5rem",
  },
  list: {
    padding: "0rem",
  },
  listItem: {
    borderBottom: "1px solid #e0e0e0",
    justifyContent: "center",
    paddingRight: "0rem",
    color: "#10316D",
    fontSize: "1.1rem",
    "&:last-child": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("321")]: {
      textAlign: "right",
    },
    [theme.breakpoints.down("285")]: {
      fontSize: "1rem",
    },
  },
  button: {
    background: theme.palette.fonecaLogoGradient.main,
    color: theme.palette.tertiary.main,
    fontWeight: 500,
    height: "3.5rem",
    width: "5.5rem",
    borderRadius: theme.radiuses[1],
    fontSize: "1rem",
    [theme.breakpoints.down("285")]: {
      fontSize: "0.8rem",
    },
  },
  gradientTypography: {
    fontSize: "1.1rem",
    fontWeight: "bolder",
    [theme.breakpoints.down("285")]: {
      fontSize: "1rem",
    },
  },
  typography: {
    fontSize: "1.1rem",
    color: "#10316D",
    [theme.breakpoints.down("285")]: {
      fontSize: "1rem",
    },
  },
}));

export default function OrderCards({
  id, items: products, totalPrice, status, quantity, date, openAccessNumbersDialogHandler, type,
}) {
  const classes = useStyles();

  const transformPrice = (price) => price.toFixed(2).toString();

  return (
    <Card className={classes.root}>
      <CardContent id="card-content" className={classes.cardContent}>
        <GradientTypography className={classes.title} color="textSecondary">
          Order
        </GradientTypography>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Order &#8470;:
          </GradientTypography>
          <Typography className={classes.typography}>
            {id}
          </Typography>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            {products.length > 1 ? "Items:" : "Item:"}
          </GradientTypography>
          <List className={classes.list}>
            {products?.map((product, index) => (
              <ListItem className={classes.listItem} key={uuidv4()}>
                {index + 1}
                .
                {" "}
                {product.product_name}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Price:
          </GradientTypography>
          <List className={classes.list}>
            {products?.map((product, index) => (
              <ListItem className={classes.listItem} key={uuidv4()}>
                {index + 1}
                .
                {" "}
                {transformPrice(product.price).replace(/^/, "$ ")}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Status:
          </GradientTypography>
          <OrderStatusTooltip status={status} />
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Total:
          </GradientTypography>
          <Typography className={classes.typography}>
            $
            {" "}
            {transformPrice(totalPrice)}
          </Typography>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Quantity:
          </GradientTypography>
          <Typography className={classes.typography}>
            {quantity}
          </Typography>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Date:
          </GradientTypography>
          <Typography className={classes.typography}>
            {date?.split("T")[0]}
          </Typography>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Type:
          </GradientTypography>
          <Typography className={classes.typography}>
            {type}
          </Typography>
        </Box>
        <Box className={classes.boxContainer}>
          <GradientTypography className={classes.gradientTypography}>
            Access Numbers:
          </GradientTypography>
          <CustomTooltip title="Check the access numbers" placement="top">
            <Button
              className={classes.button}
              onClick={() => openAccessNumbersDialogHandler(products)}
            >
              Check
            </Button>
          </CustomTooltip>
        </Box>
      </CardContent>
    </Card>
  );
}

OrderCards.propTypes = {
  id: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      price_id: PropTypes.number,
      product_name: PropTypes.string,
      product_id: PropTypes.number,
      anis: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
      })),
    }),
  ),
  status: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  openAccessNumbersDialogHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
