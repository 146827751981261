import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import ladyOnPhone from "../../../static/images/homePage-images/about-section/ladyOnPhone.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    maxWidth: "60rem",
    lineHeight: "1.5",
    marginBottom: "10rem",
    [theme.breakpoints.down("1580")]: {
      maxWidth: "50rem",
    },
    [theme.breakpoints.down("1420")]: {
      maxWidth: "45rem",
      lineHeight: "1.2",
    },
    [theme.breakpoints.down("1220")]: {
      maxWidth: "40rem",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      textAlign: "center",
      marginBottom: "0rem",
    },
    [theme.breakpoints.down("440")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("375")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("300")]: {
      fontSize: "2rem",
    },
  },
  ladyImage: {
    backgroundImage: `url(${ladyOnPhone})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "40rem",
    width: "100%",
    position: "absolute",
    top: "15%",
    left: "18%",
    transform: "scale(1.5)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      top: "20%",
      left: "-3%",
      marginRight: "auto",
      marginLeft: "auto",
      transform: "scale(1.3)",
    },
    [theme.breakpoints.down("750")]: {
      top: "27%",
      height: "35rem",
    },
    [theme.breakpoints.down("xs")]: {
      top: "23%",
      transform: "scale(1.7)",
    },
    [theme.breakpoints.down("440")]: {
      top: "20%",
      left: "-6%",
      transform: "scale(2)",
    },
    [theme.breakpoints.down("375")]: {
      left: "-8%",
      transform: "scale(2.3)",
    },
    [theme.breakpoints.down("300")]: {
      top: "8%",
    },
  },
  paper: {
    maxWidth: "48rem",
    padding: "3rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
    position: "relative",
    right: "2rem",
    zIndex: "2",
    [theme.breakpoints.down("1700")]: {
      maxWidth: "35rem",
      right: "0rem",
    },
    [theme.breakpoints.down("1220")]: {
      maxWidth: "29rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "25rem",
      padding: "2rem",
      position: "absolute",
      top: "55%",
      left: "50%",
      transform: "translate(-50%,0)",
    },
    [theme.breakpoints.down("xs")]: {
      top: "55%",
    },
    [theme.breakpoints.down("485")]: {
      width: "20rem",
    },
    [theme.breakpoints.down("410")]: {
      width: "15rem",
    },
    [theme.breakpoints.down("375")]: {
      padding: "1rem",
      top: "51%",
    },
    [theme.breakpoints.down("325")]: {
      top: "55%",
    },
    [theme.breakpoints.down("300")]: {
      width: "13rem",
    },
  },
  paperText: {
    color: theme.palette.darkerFoneca.main,
    lineHeight: "1.7",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("485")]: {
      fontSize: "2rem",
      textAlign: "center",
    },
    [theme.breakpoints.down("410")]: {
      fontSize: "1.7rem",
    },
    [theme.breakpoints.down("300")]: {
      fontSize: "1.5rem",
    },
  },
}));

export default function AboutSection() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <GradientTypography variant="h1" className={classes.title}>Reliable International Calls to Bulgaria.</GradientTypography>
      <Paper className={classes.paper}>
        <Typography variant="h2" className={classes.paperText}>
          Pin or Pinless.
        </Typography>
      </Paper>
      <div className={classes.ladyImage} />
    </Box>
  );
}
