import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import CustomTooltip from "../../../Components/Dumb/CustomTooltip/CustomTooltip";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    padding: "1rem 0.5rem",
    borderRadius: theme.radiuses[1],
    cursor: "pointer",
    [theme.breakpoints.down("1200")]: {
      display: "flex",
      width: "10.5rem",
      justifyContent: "center",
    },
    [theme.breakpoints.down("321")]: {
      width: "7rem",
    },
    [theme.breakpoints.down("285")]: {
      width: "6rem",
    },
  },
  awaitingPayment: {
    background: theme.palette.orangeLogo.main,
  },
  fulfilled: {
    background: "#3da838",
  },
  canceled: {
    background: theme.palette.orangeFoneca.main,
  },
  returned: {
    background: "#96a331",
  },
  inProgress: {
    background: "#ed874c",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  typography: {
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "500",
    textAlign: "center",
    [theme.breakpoints.down("285")]: {
      fontSize: "0.8rem",
    },
  },
}));

export default function OrderStatusTooltip({ status }) {
  const classes = useStyles();

  const getToolTipTitle = () => {
    switch (status) {
    case "FULFILLED":
      return "Your order has successfully been created. Please check your email.";
    case "MANUALLY_FULFILLED":
      return "Administrator has manually fulfilled your order.";
    case "AWAITING_PAYMENT":
      return (
        <Typography>
          Your order awaits to be paid. Please contact us
          <Link className={classes.link} to="/contact-info"> here!</Link>
        </Typography>
      );
    case "CANCELED":
    case "RETURNED":

      return (
        <Typography>
          Please contact us
          <Link className={classes.link} to="/contact-info"> here!</Link>
        </Typography>
      );
    case "IN_PROGRESS":
      return (
        <Typography>
          The payment was successful, but we encountered an issue creating your card.
          Please contact us
          <Link className={classes.link} to="/contact-info"> here!</Link>
        </Typography>
      );
    default:
      return "";
    }
  };

  return (
    <CustomTooltip title={getToolTipTitle()} placement="top" interactive>
      <div className={clsx(classes.statusContainer, {
        [classes.awaitingPayment]: status === "AWAITING_PAYMENT",
        [classes.fulfilled]: status === "FULFILLED" || status === "MANUALLY_FULFILLED",
        [classes.canceled]: status === "CANCELED",
        [classes.returned]: status === "RETURNED",
        [classes.inProgress]: status === "IN_PROGRESS",
      })}
      >
        <Typography className={classes.typography}>{status.replace("_", " ")}</Typography>
      </div>
    </CustomTooltip>
  );
}

OrderStatusTooltip.propTypes = {
  status: PropTypes.string.isRequired,
};
