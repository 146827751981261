import React from "react";
import {
  Paper, Grid, Tooltip, IconButton,
} from "@material-ui/core";
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import GradientTypography from "../../Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "grid",
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 3),
      margin: theme.spacing(0, 1, 4),
    },
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    minHeight: "3rem",
  },
  infoCardContainer: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 3),
    },
  },
  typography: {
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      textAlign: "left",
    },
  },
  editIcon: {
    boxShadow: theme.shadows[2],
  },
  greenIcon: {
    color: theme.palette.green.main,
  },
  redIcon: {
    color: theme.palette.orangeFoneca.main,
  },
}));

export default function BillingInfoPanel({ isVerified, handleEditButton }) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        display="row"
        style={{ flexWrap: "nowrap" }}
      >
        <Grid item className={classes.infoCardContainer}>
          <GradientTypography variant="h5" className={classes.typography}>
            Billing Information
          </GradientTypography>
        </Grid>
        <Grid item style={{ display: "flex" }}>
          <IconButton
            disabled
          >
            {
              isVerified
                ? <CheckCircleIcon className={classes.greenIcon} />
                : <CancelIcon className={classes.redIcon} />
            }
          </IconButton>
          <Tooltip title="Click to add your billing info">
            <IconButton
              className={classes.editIcon}
              onClick={handleEditButton}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
}

BillingInfoPanel.propTypes = {
  handleEditButton: PropTypes.func,
  isVerified: PropTypes.bool,
};
