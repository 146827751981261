import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper, Grid, useMediaQuery, CircularProgress, Container,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import AddToCartSection from "./AddToCartSection/AddToCartSection";
import ANIs from "./ANIs/ANIs";
import Description from "./Description/Description";
import AccessNumbers from "./AccessNumbers/AccessNumbers";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import { getProduct } from "../../api/ServicesCalls/ServicesCalls";
import { useShoppingCart } from "../../hooks/useShoppingCart/useShoppingCart";
import ContinueShoppingDialog from "./ContinueShoppingDialog/ContinueShoppingDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "grid",
    padding: theme.spacing(1),
    margin: theme.spacing(0, 3, 3, 3),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 1),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 0),
    },
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[2],
    minHeight: "13rem",
    minWidth: "270px",
  },
  imgPaper: {
    placeItems: "center",
  },
  img: {
    width: "100%",
    borderRadius: theme.radiuses[1],
  },
  circularProgressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    marginTop: "4rem",
    marginBottom: "4rem",
    minHeight: "calc(100vh - 8rem)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
}));

const rates = [
  { id: 0, text: "1.7 c/min to 51 countries" },
  { id: 1, text: "1.5 c/min to Sofia" },
  { id: 2, text: "1.5 c/min to Bulgaria land" },
  { id: 3, text: "4.9 c/min to any Bulgaria mobile" },
];

export default function PrepaidPhoneCardPage() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  const {
    addProduct,
  } = useShoppingCart();

  const [chipData, setChipData] = useState([]);
  const [nominal, setNominal] = useState({});
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const { id } = useParams();
  const { data: productData, isLoading: isProductLoading } = useQuery(["getProduct", id], () => getProduct(id), {
    select: (response) => response.data,
    retry: false,
  });

  useEffect(() => {
    if (productData) {
      setNominal(productData.prices[0]);
    }
  }, [productData]);

  const handleAddToCart = () => {
    const newCartItem = {
      id: productData.id,
      image: productData.image,
      name: productData.name,
      price: nominal,
      anis: chipData,
    };
    addProduct(newCartItem);
    setChipData([]);
    setNominal(productData.prices[0]);
    setIsDialogOpened(true);
    localStorage.setItem("checkout", "true");
  };

  const showCircularProgress = () => (
    <div className={classes.circularProgressContainer}>
      <CircularProgress />
    </div>
  );

  return (
    <HeaderFooterContainer>
      <Container className={classes.container} maxWidth="lg">
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid container justify="center">
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={clsx(classes.paper, classes.imgPaper)}>
                {
                  isProductLoading ? showCircularProgress()
                    : (
                      <img
                        src={productData.image}
                        className={classes.img}
                        alt="Foneca prepaid phone card"
                      />
                    )
                }
              </Paper>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={classes.paper}>
                {
                  isProductLoading
                    ? showCircularProgress()
                    : (
                      <AddToCartSection
                        productName={productData?.name}
                        prices={productData?.prices}
                        handleAddToCart={handleAddToCart}
                        nominal={nominal}
                        setNominal={setNominal}
                      />
                    )
                }
              </Paper>
            </Grid>
          </Grid>
          {
            matchesDesktopScreen && (
            <Grid container justify="center">
              <Grid item xs={12} lg={3}>
                <Paper className={classes.paper}>
                  {
                    isProductLoading
                      ? showCircularProgress()
                      : (
                        <Description
                          description={productData.long_description}
                          rates={rates}
                        />
                      )
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <ANIs chipData={chipData} setChipData={setChipData} />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {
                      isProductLoading
                        ? (
                          <Paper className={classes.paper}>
                            {showCircularProgress()}
                          </Paper>
                        )
                        : (
                          <AccessNumbers
                            accessNumbers={productData.access_numbers}
                          />
                        )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            )
          }
          {
            matchesMobileScreen && (
            <Grid container justify="center">
              <Grid item xs={12} md={7}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <ANIs chipData={chipData} setChipData={setChipData} />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {
                      isProductLoading
                        ? showCircularProgress()
                        : (
                          <Description
                            description={productData.long_description}
                            rates={rates}
                          />
                        )
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container>
                    {
                      isProductLoading
                        ? (
                          <Paper className={classes.paper}>
                            {showCircularProgress()}
                          </Paper>
                        )
                        : (
                          <AccessNumbers
                            accessNumbers={productData.access_numbers}
                          />
                        )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            )
          }
          <ContinueShoppingDialog open={isDialogOpened} setOpen={setIsDialogOpened} />
        </Grid>
      </Container>
    </HeaderFooterContainer>
  );
}
