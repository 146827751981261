import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Container, Button, Typography,
} from "@material-ui/core";
import GradientTypography from "../../GradientTypography/GradientTypography";
import frameOneBackground from "../../../../static/images/homePage-images/carousel-frames/frame-one/rectangle.svg";
import mobileCard from "../../../../static/images/services-svg/mobileCard.svg";
import fonecaLogo from "../../../../static/images/services-svg/logo.svg";
import frameOneLady from "../../../../static/images/homePage-images/carousel-frames/frame-one/frameOneLady.svg";
import frameOneEllipse from "../../../../static/images/homePage-images/carousel-frames/frame-one/frameOneEllipse.svg";

const useStyles = makeStyles((theme) => ({
  frameOneBackground: {
    maxWidth: "none",
    backgroundImage: `url(${frameOneBackground})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "57rem",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("670")]: {
      height: "50rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "45rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "38rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "30rem",
    },
  },
  ladyImage: {
    backgroundImage: `url(${frameOneLady})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "53rem",
    width: "100%",
    position: "absolute",
    transform: "translate(-25%,-5%) scale(1.1)",
    zIndex: "1",
    [theme.breakpoints.down("770")]: {
      transform: "translate(-6%, -5%) scale(1.2)",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(-6%, -18%) scale(1.2)",
    },
    [theme.breakpoints.down("460")]: {
      transform: "translate(-6%, -21%) scale(1.6)",
    },
    [theme.breakpoints.down("370")]: {
      transform: "translate(-6%, -22%) scale(2)",
    },
    [theme.breakpoints.down("300")]: {
      transform: "translate(-6%, -28%) scale(2)",
    },
  },
  ellipseImage: {
    backgroundImage: `url(${frameOneEllipse})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "50rem",
    width: "100%",
    position: "absolute",
    transform: "translate(40%,-50%)",
    [theme.breakpoints.down("770")]: {
      height: "35rem",
      transform: "translate(45%, -25%)",
    },
    [theme.breakpoints.down("670")]: {
      width: "0",
    },
  },
  mobileCardHolder: {
    width: "100%",
    position: "relative",
    zIndex: "1",
    transform: "translate(13%, 40%)",
    [theme.breakpoints.down("970")]: {
      transform: "translate(0%, 60%)",
    },
    [theme.breakpoints.down("770")]: {
      transform: "translate(0%, 60%)",
    },
    [theme.breakpoints.down("670")]: {
      transform: "translate(0%, 100%)",
    },
    [theme.breakpoints.down("xs")]: {
      transform: "translate(0%, 60%)",
    },
    [theme.breakpoints.down("460")]: {
      transform: "translate(0%, 80%)",
    },
    [theme.breakpoints.down("330")]: {
      transform: "translate(0%, 115%)",
    },
  },
  mobileCard: {
    backgroundImage: `url(${mobileCard})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30rem",
    [theme.breakpoints.down("770")]: {
      height: "28rem",
    },
    [theme.breakpoints.down("670")]: {
      height: "20rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "13rem",
    },
    [theme.breakpoints.down("325")]: {
      height: "10rem",
    },
  },
  fonecaLogo: {
    padding: "5rem 7rem 2rem 2rem",
    [theme.breakpoints.down("670")]: {
      height: "5rem",
      padding: "3rem 5rem 2rem 2rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "3rem",
      padding: "2rem 5rem 0rem 2rem",
    },
    [theme.breakpoints.down("325")]: {
      height: "2.5rem",
      padding: "1rem 3rem 0rem 2rem",
    },
  },
  mobileCardTypographyAndButtonContainer: {
    display: "flex",
    width: "36rem",
    height: "100%",
    marginTop: "1rem",
    [theme.breakpoints.down("670")]: {
      width: "25rem",
    },
    [theme.breakpoints.down("460")]: {
      width: "16.5rem",
      marginTop: "2rem",
    },
    [theme.breakpoints.down("325")]: {
      width: "12.5rem",
      marginTop: "1.8rem",
    },
  },
  mobileCardTypohraphy: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#fff",
    lineHeight: "1",
    alignSelf: "flex-start",
    padding: "0rem 1rem 0rem 0rem",
    [theme.breakpoints.down("670")]: {
      fontSize: "2rem",
      padding: "0rem",
    },
    [theme.breakpoints.down("460")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("325")]: {
      fontSize: "1.1rem",
    },
  },
  mobileCardButton: {
    borderRadius: theme.radiuses[2],
    backgroundColor: "#fff",
    height: "9rem",
    padding: "0rem 4rem",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("670")]: {
      height: "7rem",
      padding: "0rem 3rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "4.5rem",
      padding: "0rem 1.5rem",
      borderRadius: "0.875rem",
    },
    [theme.breakpoints.down("325")]: {
      height: "4rem",
      padding: "0rem 1rem",
      alignSelf: "flex-start",
    },
  },
  mobileCardButtonTypography: {
    fontWeight: "bold",
    textTransform: "capitalize",
    [theme.breakpoints.down("460")]: {
      fontSize: "1.2rem",
    },
  },
}));

export default function FrameOne({ cardText, url }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.frameOneBackground}>
      <div className={classes.ladyImage} />
      <div className={classes.mobileCardHolder}>
        <div className={classes.mobileCard}>
          <img src={fonecaLogo} alt="foneca-logo" title="foneca-logo" className={classes.fonecaLogo} />
          <div className={classes.mobileCardTypographyAndButtonContainer}>
            <Typography
              className={classes.mobileCardTypohraphy}
            >
              {cardText}
            </Typography>
            <Button
              className={classes.mobileCardButton}
              onClick={() => history.push(url)}
            >
              <GradientTypography
                variant="h4"
                className={classes.mobileCardButtonTypography}
              >
                Click Here
              </GradientTypography>
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.ellipseImage} />
    </Container>
  );
}

FrameOne.propTypes = {
  cardText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
