/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import clsx from "clsx";
import {
  AppBar, Toolbar, Typography, Button, Box, ListItemText,
  Drawer, List, ListItem, IconButton, ListItemIcon,
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  PersonOutline as PersonOutlineIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  AccountBox as AccountBoxIcon,
  Storage as StorageIcon,
  ContactPhone as ContactPhoneIcon,
  ExitToApp as ExitToAppIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons/";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import { useCookies } from "react-cookie";
import SignupDropDown from "../SignupDropDown/SignupDropDown";
import SideMenuListItems from "../SideMenuListItems/SideMenuListItems";
import FonecaLogo from "../../../static/logo/Logo.svg";
import { UserContext } from "../../../Context/UserContext/UserContext";
import ShoppingCartDropDown from "../ShoppingCartDropDown/ShoppingCartDropDown";
import MobileShoppingCart from "../ShoppingCartDropDown/MobileShoppingCart/MobileShoppingCart";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";
import { useShoppingCart } from "../../../hooks/useShoppingCart/useShoppingCart";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    height: "4.375rem",
    boxShadow: theme.shadows[2],
  },
  headerToolbar: {
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("650")]: {
      paddingRight: "0.5rem",
      paddingLeft: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1rem",
    },
    [theme.breakpoints.down("285")]: {
      padding: "0rem 0.2rem",
    },
  },
  logo: {
    color: "#000",
    fontSize: "2.0rem",
    fontWeight: "bolder",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  mobileLogo: {
    width: "100%",
  },
  icons: {
    "&& > svg": {
      fontSize: "1.7rem",
      padding: "0rem",
    },
    [theme.breakpoints.down("880")]: {
      margin: "0rem",
      minWidth: "2.8rem",
      justifyContent: "center",
    },
    [theme.breakpoints.down("650")]: {
      padding: "0rem",
    },
  },
  loggedInIcons: {
    [theme.breakpoints.down("880")]: {
      minWidth: "1.5rem",
    },
  },
  groupButton: {
    height: "80%",
    display: "inline-flex",
  },
  cartAccountAndLogoutContainer: {
    display: "flex",
  },
  accountPopUpListItems: {
    textAlign: "center",
  },
  drawerCloseButtonContainer: {
    padding: "0.5rem 0.5rem 0rem 1rem",
    marginBottom: "-1rem",
  },
  drawerCloseButton: {
    fontSize: "1.7rem",
    color: "#fff",
    zIndex: "2",
  },
  burgerMenuButton: {
    fontSize: "1.7rem",
  },
  shoppingCartIcon: {
    position: "relative",
    color: theme.palette.primary.main,
    "&:active": {
      background: "transparent",
    },
    "&:visited": {
      background: "transparent",
    },
  },
  shoppingCartProductsNumber: {
    fontSize: "0.7rem",
    position: "absolute",
    top: "0px",
    right: "0px",
    background: "#10316D",
    color: "#fff",
    width: "1rem",
    height: "1rem",
    borderRadius: "100%",
    opacity: "0",
    transition: "1s ease-in-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shoppingCartProductNumberVisible: {
    opacity: "1",
  },
  drawerListItemIconContainer: {
    width: "40%",
    marginRight: "1rem",
    justifyContent: "flex-end",
  },
  drawerIcons: {
    fontSize: "2rem",
    color: "#fff",
  },
  drawerListItemText: {
    fontSize: "1.5rem",
    color: "#fff",
    fontWeight: "500",
  },
  sideDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    top: "70px",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    top: "70px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  usernameTypography: {
    color: theme.palette.secondary.main,
  },
  activeMenuButton: {
    boxShadow: "0px 0px 20px rgba(16, 49, 109, 0.1)",
  },
  listSideMenuItems: {
    paddingBottom: "0rem",
  },
  mobileMenuDrawer: {
    background: theme.palette.fonecaLogoGradient.main,
  },
  listMobileMenuDrawer: {
    paddingBottom: "1rem",
  },
}));

const MenuButton = withStyles(() => ({
  root: {
    backgroundColor: "#fff",
    fontSize: "1.1rem",
    fontWeight: "500",
    padding: "1.2rem",
    color: "#10316D",
    textTransform: "Capitalize",
    borderRadius: "1rem",
    marginRight: "0.3rem",
  },
}))(Button);

const isScreenSmall = () => !!(window.innerWidth <= 600);

export default function AppHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("760"));
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("880"));
  const [myAccountAnchorEl, setMyAccountAnchorEl] = useState(null);
  const [shoppingCartAnchorEl, setShoppingCartAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(!isScreenSmall());
  const [isCartMenuDialogOpen, setIsCartMenuDialogOpen] = useState(false);
  const { userState } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const { getProductsCount } = useShoppingCart();
  const [, , removeCookie] = useCookies([]);

  const isLoggedIn = () => !!localStorage.getItem("access_token");
  const handleMyAccountClick = () => (isLoggedIn() ? history.push("/profile") : history.push("/login"));
  const handleHomeClick = () => history.push("/");
  const handleServicesClick = () => history.push("/services");
  const handleContactsClick = () => history.push("/contacts");
  const handleLogoutClick = () => {
    removeCookie("access_token");
    removeCookie("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    history.push("/login");
  };

  const closeShoppingCartMenu = () => {
    setShoppingCartAnchorEl(null);
  };

  const toggleSideDrawer = () => setIsSideDrawerOpen(!isSideDrawerOpen);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const openMyAccountHandler = (event) => setMyAccountAnchorEl(event.currentTarget);
  const closeMyAccountHandler = () => setMyAccountAnchorEl(null);
  const location = useLocation();

  const isUserInProfile = () => ["/profile", "/billing", "/my-products", "/orders"].includes(location?.pathname);

  const closeCartMenuDialog = () => {
    setIsCartMenuDialogOpen(false);
  };

  const openShoppingCartMenu = (event) => {
    if (matchesMobileScreen) {
      setIsCartMenuDialogOpen(true);
    } else {
      setShoppingCartAnchorEl(event.currentTarget);
    }
  };

  const handleCheckoutClick = () => {
    if (isLoggedIn()) {
      history.push("/confirm-and-pay");
      setIsCartMenuDialogOpen(false);
    } else {
      localStorage.setItem("checkout", "true");
      history.push("/login");
      openSnackbar("info", "In order to proceed to checkout, you have to log in!", "login-required-snackbar");
    }
    setMyAccountAnchorEl(null);
  };

  const mobileHeaderView = (
    <>
      <IconButton onClick={toggleDrawer} className={classes.toolbarElementContainer}>
        <MenuIcon classes={{ root: classes.burgerMenuButton }} />
      </IconButton>
      <Link to="/">
        <img src={FonecaLogo} alt="Foneca Logo" className={classes.mobileLogo} />
      </Link>
    </>
  );

  const desktopHeaderView = (
    <>
      <Link to="/">
        <img src={FonecaLogo} alt="Foneca Logo" />
      </Link>
      <div className={classes.groupButton}>
        <MenuButton
          variant="text"
          onClick={handleHomeClick}
          className={history.location.pathname === "/" && `${classes.activeMenuButton}`}
        >
          Home
        </MenuButton>
        <MenuButton
          variant="text"
          onClick={handleServicesClick}
          className={history.location.pathname === "/services" && `${classes.activeMenuButton}`}
        >
          Services

        </MenuButton>
        <MenuButton
          variant="text"
          onClick={handleContactsClick}
          className={history.location.pathname === "/contacts" && `${classes.activeMenuButton}`}
        >
          Contacts

        </MenuButton>
      </div>
    </>
  );

  const headerLinks = [
    {
      text: "Home",
      id: "nav-profile-button",
      clickHandler: handleHomeClick,
      icon: <HomeIcon className={classes.drawerIcons} />,
    },
    {
      text: "Services",
      id: "nav-services-button",
      clickHandler: handleServicesClick,
      icon: <StorageIcon className={classes.drawerIcons} />,
    },
    {
      text: "Contacts",
      id: "nav-contacts-button",
      clickHandler: handleContactsClick,
      icon: <ContactPhoneIcon className={classes.drawerIcons} />,
    },
    {
      text: "Profile",
      icon: <AccountBoxIcon className={classes.drawerIcons} />,
      id: "side-nav-profile-button",
      clickHandler: handleMyAccountClick,
    },
    {
      text: "Logout",
      icon: <ExitToAppIcon className={classes.drawerIcons} />,
      id: "logout-button",
      clickHandler: handleLogoutClick,
    },
  ];

  return (
    <>
      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer}>
        <Box role="presentation" className={classes.mobileMenuDrawer}>
          <Box className={classes.drawerCloseButtonContainer}>
            <IconButton onClick={toggleDrawer} className={classes.drawerCloseButton}>
              <CloseIcon classes={{ root: classes.drawerCloseButton }} />
            </IconButton>
          </Box>
          <List className={classes.listMobileMenuDrawer}>
            {
              headerLinks.map((link) => (
                <ListItem button key={link.id} onClick={link.clickHandler}>
                  <ListItemIcon className={classes.drawerListItemIconContainer}>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={link.text}
                    classes={{ primary: classes.drawerListItemText }}
                  />
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Drawer>
      {
        (isLoggedIn() && isUserInProfile())
           && (
           <Drawer
             open={!matchesMobileScreen}
             variant="permanent"
             className={clsx(classes.sideDrawer, {
               [classes.drawerOpen]: isSideDrawerOpen,
               [classes.drawerClose]: !isSideDrawerOpen,
             })}
             classes={{
               paper: clsx({
                 [classes.drawerOpen]: isSideDrawerOpen,
                 [classes.drawerClose]: !isSideDrawerOpen,
               }),
             }}
           >
             <Divider />
             <div className={classes.toolbar}>
               <IconButton onClick={toggleSideDrawer}>
                 {!isSideDrawerOpen
                   ? <ChevronRightIcon />
                   : <ChevronLeftIcon />}
               </IconButton>
             </div>
             <Divider />
             <List className={classes.listSideMenuItems}>
               { isSideDrawerOpen && (
                 <>
                   <Typography variant="body1" align="center" gutterBottom>
                     Welcome,
                     {" "}
                     <span className={classes.usernameTypography}>
                       {!!userState.username && userState.username }
                     </span>
                   </Typography>
                   <Divider />
                 </>
               ) }
               <SideMenuListItems />
             </List>
             <Divider />
           </Drawer>
           )
      }
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.headerToolbar}>
          {matchesMobileScreen ? mobileHeaderView : desktopHeaderView }
          <div className={classes.cartAccountAndLogoutContainer}>
            <IconButton
              className={classes.shoppingCartIcon}
              onClick={openShoppingCartMenu}
              data-cy="shopping-cart-button"
            >
              <ShoppingCartIcon />
              <span
                className={clsx({
                  [classes.shoppingCartProductsNumber]: true,
                  [classes.shoppingCartProductNumberVisible]: !!getProductsCount(),
                })}
              >
                {getProductsCount()}
              </span>
            </IconButton>
            {matchesMobileScreen ? (
              <MobileShoppingCart
                isCartMenuDialogOpen={isCartMenuDialogOpen}
                closeCartMenuDialog={closeCartMenuDialog}
                onCheckoutClick={handleCheckoutClick}
              />
            ) : (
              <ShoppingCartDropDown
                onClickHandler={openShoppingCartMenu}
                onCheckoutClick={handleCheckoutClick}
                anchorEl={shoppingCartAnchorEl}
                isShoppingCartOpen={Boolean(shoppingCartAnchorEl)}
                closeShoppingCart={closeShoppingCartMenu}
              />
            )}
            {
              !matchesMobileScreen && (
                <Button
                  color="primary"
                  id="my-account-header-button"
                  classes={{
                    startIcon: clsx({
                      [classes.icons]: true,
                      [classes.loggedInIcons]: !isLoggedIn(),
                    }),
                    endIcon: clsx({
                      [classes.icons]: true,
                      [classes.loggedInIcons]: !isLoggedIn(),
                    }),
                    root: classes.icons,
                  }}
                  startIcon={<PersonOutlineIcon />}
                  endIcon={isLoggedIn() ? null : <ArrowDropDownIcon />}
                  onClick={isLoggedIn() ? handleMyAccountClick : openMyAccountHandler}
                >
                  {!matchesSmallScreen && (
                  <span className={classes.accountButtonText}>
                    My Account
                  </span>
                  )}
                </Button>
              )
            }
            {
              isLoggedIn() && !matchesMobileScreen
                ? (
                  <Button
                    color="primary"
                    classes={{
                      startIcon: clsx({
                        [classes.icons]: true,
                        [classes.loggedInIcons]: !isLoggedIn(),
                      }),
                      endIcon: classes.icons,
                      root: classes.icons,
                    }}
                    startIcon={<ExitToAppIcon />}
                    onClick={handleLogoutClick}
                  >
                    {!matchesSmallScreen && "Logout"}
                  </Button>
                )
                : (
                  <SignupDropDown
                    element={myAccountAnchorEl}
                    open={Boolean(myAccountAnchorEl)}
                    onClose={closeMyAccountHandler}
                  />
                )
            }
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
