import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Container, Link } from "@material-ui/core";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";
import customService from "../../static/images/contact-support-page/customerService.png";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 12.1rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
  },
  paper: {
    borderRadius: theme.radiuses[2],
    padding: "3rem",
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("380")]: {
      margin: "1rem auto",
    },
    [theme.breakpoints.down("360")]: {
      padding: "1rem",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  img: {
    marginBottom: "2rem",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  typography: {
    fontWeight: "bolder",
    textAlign: "center",
    padding: "0rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export default function ContactSupportPage() {
  const classes = useStyles();

  return (
    <HeaderFooterContainer>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <div className={classes.imageContainer}>
            <img src={customService} alt="customer-service" title="customer-service" className={classes.img} />
          </div>
          <GradientTypography variant="h6" className={classes.typography}>
            You have reached this page as we are constantly
            improving our site and some features are not fully integrated.
            Please contact us at
            <Link href="mailto:sales@foneca.com" target="_top" title="sales@foneca.com" className={classes.link}> sales@foneca.com </Link>
            or
            <Link href="tel:+1 302 351 2121" title="+1 302 351 2121" className={classes.link}> +1 302 351 2121</Link>
            , so we can assist you to complete your order.
          </GradientTypography>
        </Paper>
      </Container>
    </HeaderFooterContainer>
  );
}
