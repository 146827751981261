/* eslint-disable import/prefer-default-export */
export const rates = [
  {
    id: "0",
    rate: "1.7c/min",
    destination: "51 countries",
  },
  {
    id: "1",
    rate: "1.5c/min",
    destination: "Sofia",
  },
  {
    id: "2",
    rate: "1.5c/min",
    destination: "Bulgaria land",
  },
  {
    id: "3",
    rate: "4.9c/min",
    destination: "any Bulgaria mobile",
  },
];
