import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Grid } from "@material-ui/core";
import { useQuery } from "react-query";
import OrdersTable from "./OrdersTable/OrdersTable";
import OrderCards from "./OrderCard/OrderCards";
import { getUserOrders } from "../../api/OrdersCalls/OrdersCalls";
import AccessNumbersDialog from "./AccessNumbersDialog/AccessNumbersDialog";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: "calc(100vh - 8.1rem)",
    padding: "0 0 0 15rem",
    margin: "0rem",
    backgroundColor: theme.palette.pageBG.main,
    width: "100%",
    [theme.breakpoints.down("1200")]: {
      padding: "3.1rem 0rem 3.1rem 5rem",
    },
    [theme.breakpoints.down("600")]: {
      padding: "3.1rem 0rem 3.1rem 3.5rem",
    },
  },
  cardGridItem: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
}));

export default function OrdersHistoryPage() {
  const classes = useStyles();
  const theme = useTheme();
  const [accessNumbers, setAccessNumbers] = useState([]);
  const [isAccessNumbersDialogOpen, setIsAccessNumbersDialogOpen] = useState(false);
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("1200"));
  const [page, setPage] = useState(0);

  const { data: orders } = useQuery("getUserOrders", getUserOrders, {
    select: (response) => response.data,
  });

  const openAccessNumbersDialogHandler = (products) => {
    const noDuplicateProducts = Object.values(products
      .reduce((acc, cur) => Object.assign(acc, { [cur.product_id]: cur }), {}))
      .map((item) => item.access_numbers);
    setAccessNumbers(noDuplicateProducts);
    setIsAccessNumbersDialogOpen(true);
  };

  const closeAccessNumbersDialogHandler = () => {
    setIsAccessNumbersDialogOpen(!isAccessNumbersDialogOpen);
  };

  return (
    <Grid
      className={classes.gridContainer}
      container
      direction="row"
      spacing={2}
    >

      {matchesMobileScreen ? (
        <>
          {
            orders?.map((order) => (
              <Grid
                key={order.created_at}
                className={classes.cardGridItem}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <OrderCards
                  id={order.id}
                  items={order.items}
                  totalPrice={order.total}
                  status={order.status}
                  quantity={order.items.length}
                  date={order.created_at}
                  openAccessNumbersDialogHandler={openAccessNumbersDialogHandler}
                  type={order.type}
                />
              </Grid>
            ))
          }
          <AccessNumbersDialog
            accessNumbers={accessNumbers}
            isAccessNumbersDialogOpen={isAccessNumbersDialogOpen}
            closeAccessNumbersDialogHandler={closeAccessNumbersDialogHandler}
          />
        </>
      ) : (
        <Grid item xs={12} sm={12} style={{ padding: "3rem" }}>
          <OrdersTable page={page} setPage={setPage} orders={orders} />
        </Grid>
      )}

    </Grid>
  );
}
