import React from "react";
import {
  BottomNavigation, BottomNavigationAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import {
  Info as InfoIcon, Policy as PolicyIcon, ContactSupport as ContactSupportIcon,
  Assignment as AssignmentIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    width: "100%",
    background: theme.palette.fonecaLogoGradient.main,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    height: "3.75rem",
    zIndex: "10000",
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      height: "13.75rem",
      flexDirection: "column",
    },
  },
  bottomActionItems: {
    color: theme.palette.tertiary.main,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none",
    },
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <BottomNavigation
      className={classes.bottomNav}
      showLabels
    >
      <BottomNavigationAction className={classes.bottomActionItems} label="About Us" icon={<InfoIcon />} component={Link} to="/about-us" />
      <BottomNavigationAction className={classes.bottomActionItems} label="Privacy Policy" icon={<PolicyIcon />} component={Link} to="/privacy-policy" />
      <BottomNavigationAction className={classes.bottomActionItems} label="Terms and Conditions" icon={<AssignmentIcon />} component={Link} to="/terms" />
      <BottomNavigationAction className={classes.bottomActionItems} label="FAQ" icon={<ContactSupportIcon />} component={Link} to="/faq" />
    </BottomNavigation>
  );
}
