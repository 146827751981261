import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.darkerFoneca.main,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  body: {
    fontSize: 16,
    color: "#5B8397",
    padding: "10px",
  },
}))(TableCell);

export default StyledTableCell;
