/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Card, CardContent, Box, List, ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import GradientTypography from "../../../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[1],
    height: "fit-content",
    minWidth: "21rem",
    [theme.breakpoints.down("420")]: {
      minWidth: "18rem",
    },
    [theme.breakpoints.down("330")]: {
      minWidth: "15rem",
    },
  },
  cardContent: {
    padding: "1rem",
    "&:last-child": {
      padding: "1rem",
    },
  },
  title: {
    fontSize: "1.4rem",
    textAlign: "center",
    fontWeight: "bolder",
    textTransform: "capitalize",
    [theme.breakpoints.down("285")]: {
      fontSize: "1.1rem",
    },
  },
  list: {
    padding: "0rem",
  },
  listItem: {
    borderBottom: "1px solid #e0e0e0",
    justifyContent: "center",
    color: "#10316D",
    fontSize: "1.1rem",
    "&:last-child": {
      borderBottom: "none",
    },
    [theme.breakpoints.down("420")]: {
      padding: "1rem 0rem",
    },
    [theme.breakpoints.down("285")]: {
      fontSize: "1rem",
    },
  },
}));

export default function AccessNumbersCard({ title, numbers }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <GradientTypography className={classes.title} color="textSecondary">
          {title}
        </GradientTypography>
        <Box>
          <List className={classes.list}>
            {numbers.map(({ text, number }) => (
              <ListItem className={classes.listItem} key={uuidv4()}>
                {text}
                {" "}
                :
                {" "}
                {number}
              </ListItem>
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

AccessNumbersCard.propTypes = {
  title: PropTypes.string.isRequired,
  numbers: PropTypes.array.isRequired,
};
