import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, Button, Card, CardMedia,
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import Recharge from "../../../../static/images/productCards/recharge.png";
import { SnackbarContext } from "../../../../Context/SnackbarContext/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "20rem",
    margin: "2rem auto",
    padding: "2rem",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
      margin: "2rem 0rem",
    },
    [theme.breakpoints.down("350")]: {
      padding: "1rem",
    },
  },
  media: {
    height: "222px",
    borderRadius: theme.radiuses[1],
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    marginBottom: "2rem",
    cursor: "pointer",
  },
  textImage: {
    textAlign: "center",
    paddingBottom: "2rem",
  },
  button: {
    backgroundColor: theme.palette.orangeFoneca.main,
    opacity: "0.8",
    borderRadius: "1rem",
    color: "#fff",
    fontSize: "1.3rem",
    padding: "1.6rem 0rem",
    textTransform: "capitalize",
    "&:hover": {
      opacity: "1",
      backgroundColor: theme.palette.orangeFoneca.main,
    },
  },
}));

export default function RechargeCard() {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useContext(SnackbarContext);
  const isLoggedIn = () => !!localStorage.getItem("access_token");

  const handleRechargeButtonClick = () => {
    if (isLoggedIn()) {
      history.push("/my-products");
    } else {
      history.push("/login");
      openSnackbar("info", "Please login to your account so you can recharge.");
    }
  };
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={Recharge}
        title="Recharge"
      />
      <Typography variant="h4" className={classes.textImage}>Recharge</Typography>
      <Button
        onClick={handleRechargeButtonClick}
        fullWidth
        className={classes.button}
      >
        Recharge
      </Button>
    </Card>
  );
}
