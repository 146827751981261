import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Box } from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/styles";
import FonecaLogo from "../../../../static/logo/Logo.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[1],
    maxWidth: "450px",
    minHeight: "200px",
    borderRadius: "2rem",
    border: "1px solid #EFEFEF",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "2rem",
    [theme.breakpoints.down("430")]: {
      flexDirection: "column-reverse",
    },
  },
  commentTypography: {
    marginBottom: "2rem",
  },
  ratingAndLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("430")]: {
      flexDirection: "column-reverse",
    },
  },
  rating: {
    [theme.breakpoints.down(430)]: {
      margin: "1.5rem 0rem",
    },
  },
}));

export default function TestimonialCard({ comment, rating }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="subtitle1"><q>{comment}</q></Typography>
      <Box className={classes.ratingAndLogoContainer}>
        <Rating
          className={classes.rating}
          name="rating"
          value={rating}
          readOnly
        />
        <img src={FonecaLogo} title="foneca-logo" alt="foneca-logo" />
      </Box>
    </Paper>
  );
}

TestimonialCard.propTypes = {
  comment: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};
