import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Grid } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.tertiary.main,
    backgroundColor: theme.palette.chip.main,
  },
  deleteIcon: {
    color: theme.palette.tertiary.main,
  },
  phoneIcon: {
    color: theme.palette.tertiary.main,
  },
}));

export default function ChipsArray({ array, setArray, deleteButton }) {
  const classes = useStyles();

  const handleDelete = (chipToDelete) => () => {
    setArray((chips) => chips.filter(
      (chip) => chip.label !== chipToDelete.label,
    ));
  };

  return (
    <>
      {array?.map((data, index) => (
        <Grid item key={data.label}>
          <Chip
            icon={<PhoneIcon />}
            id={data}
            label={data.label}
            onDelete={deleteButton ? handleDelete(data, index) : null}
            className={classes.chip}
            classes={{
              deleteIcon: classes.deleteIcon,
              icon: classes.phoneIcon,
            }}
          />
        </Grid>
      ))}
    </>
  );
}

ChipsArray.propTypes = {
  array: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  setArray: PropTypes.func,
  deleteButton: PropTypes.bool,
};

ChipsArray.defaultProps = {
  deleteButton: false,
};
