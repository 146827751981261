import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardContent, CardActions, IconButton, Typography, Button, Card, CardMedia, Box,
} from "@material-ui/core/";
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon, Visibility as VisibilityIcon } from "@material-ui/icons/";
import { useHistory } from "react-router-dom";
import GradientTypography from "../../GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "20rem",
    padding: "2rem",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    margin: "2rem auto",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
      margin: "2rem 0rem",
    },
    [theme.breakpoints.down("350")]: {
      padding: "1rem",
    },
  },
  media: {
    height: "222px",
    maxWidth: "332px",
    borderRadius: theme.radiuses[1],
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    cursor: "pointer",
  },
  cardContent: {
    padding: "1rem 0rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      height: "7rem",
    },
  },
  discountContainer: {
    padding: "0.5rem 1rem",
    borderRadius: "1rem",
    backgroundColor: "rgba(235, 64, 8, 0.3)",
    color: "rgba(235, 64, 8, 0.8)",
    fontWeight: "bold",
    [theme.breakpoints.down("350")]: {
      padding: "0.5rem",
    },
  },
  priceContainer: {
    width: "100%",
    textAlign: "end",
  },
  price: {
    lineHeight: "0.7",
    fontWeight: "bold",
    color: theme.palette.darkerFoneca.main,
  },
  priceInfo: {
    color: theme.palette.darkerFoneca.main,
    fontWeight: "bold",
    fontSize: "0.8rem",
    padding: "0rem 0.8rem",
  },
  currency: {
    fontSize: "1rem",
    display: "inline-block",
    verticalAlign: "top",
    color: "#4F4F4F",
  },
  cardActions: {
    padding: "0rem",
    justifyContent: "space-between",
  },
  iconsContainer: {
    padding: "0rem 1.5rem",
    height: "5rem",
    borderRadius: "1rem",
    backgroundColor: "#EBEEFF",
    "&:hover": {
      backgroundColor: "#dfe2f5",
    },
    [theme.breakpoints.down("350")]: {
      padding: "1rem",
      height: "4rem",
    },
  },
  icons: {
    fontSize: "2rem",
    color: theme.palette.darkerFoneca.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  tryItButton: {
    backgroundColor: "#EBEEFF",
    borderRadius: "1rem",
    padding: "0rem 1.5rem",
    height: "5rem",
    color: theme.palette.darkerFoneca.main,
    "&:hover": {
      backgroundColor: "#dfe2f5",
    },
    textTransform: "capitalize",
    [theme.breakpoints.down("430")]: {
      padding: "0rem",
      width: "100%",
    },
    [theme.breakpoints.down("350")]: {
      height: "4rem",
    },
  },
  tryItButtonTypography: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    [theme.breakpoints.down("350")]: {
      fontSize: "1.2rem",
    },
  },
  productName: {
    textAlign: "center",
    marginBottom: "1rem",
    lineHeight: "1",
  },
  priceWrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

export default function ProductCard({
  productName, rate, image, onClickHandler, id,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.root} data-cy="product-card">
      <CardMedia
        className={classes.media}
        image={image}
        title={productName}
        onClick={() => history.push(`/services/prepaid-cards/${id}`)}
      />
      <CardContent className={classes.cardContent}>
        <Box>
          <Typography variant="h4" className={classes.productName}>{productName}</Typography>
        </Box>
        <Box className={classes.priceWrapper}>
          <Box className={classes.price}>
            <Typography variant="h6" className={classes.price}>
              <span className={classes.currency}>$</span>
              <span>
                {rate}
              </span>
            </Typography>
            <span className={classes.priceInfo}>
              Starting price.
            </span>
          </Box>
        </Box>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <IconButton aria-label="add to favorites" className={classes.iconsContainer}>
          <ThumbUpAltOutlinedIcon classes={{ root: classes.icons }} />
        </IconButton>
        <Button
          className={classes.tryItButton}
          onClick={() => history.push(`/services/prepaid-cards/${id}`)}
          data-cy="try-it-now-button"
        >
          <GradientTypography
            className={classes.tryItButtonTypography}
          >
            Try it Now
          </GradientTypography>
        </Button>
        <IconButton className={classes.iconsContainer} onClick={onClickHandler} data-cy="short-description-button">
          <VisibilityIcon classes={{ root: classes.icons }} />
        </IconButton>
      </CardActions>
    </Card>
  );
}

ProductCard.propTypes = {
  productName: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
