import React, { createContext, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";
import CustomSnackBar from "../../Components/Dumb/CustomSnackbar/CustomSnackbar";

const initialState = {
  isPopUpOppened: false,
  snackType: "error",
  snackMessage: "example",
  snackId: "example",
};

export const SnackbarContext = createContext(initialState);

export const SnackbarProvider = ({ children }) => {
  const [snackState, setSnackState] = useState(initialState);
  const handleClose = () => {
    setSnackState({ ...snackState, isPopUpOppened: false });
  };
  const openSnackbar = (type, message, id) => {
    setSnackState({
      isPopUpOppened: true,
      snackType: type,
      snackMessage: message,
      snackId: id,
    });
  };
  return (
    <SnackbarContext.Provider value={{ openSnackbar, snackState }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackState.isPopUpOppened}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <CustomSnackBar
          id={snackState.snackId}
          variant={snackState.snackType}
          message={snackState.snackMessage}
          onClose={handleClose}
        />
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
