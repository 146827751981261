import React from "react";
import {
  Container, Box, Paper, Typography, Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LocationOn as LocationOnIcon, PermPhoneMsg as PermPhoneMsgIcon, Sms as SmsIcon } from "@material-ui/icons/";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 8.2rem)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("1030")]: {
      padding: "3rem 0rem",
    },
  },
  boxContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("1030")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  paperContainer: {
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.down("1030")]: {
      margin: "3rem 0rem",
    },
  },
  paper: {
    width: "20rem",
    height: "16rem",
    boxShadow: theme.shadows[1],
    borderRadius: theme.radiuses[2],
    padding: "3rem 2rem",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: "17rem",
      height: "17rem",
      padding: "3rem 1rem",
    },
  },
  iconContainer: {
    width: "4rem",
    height: "4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    background: theme.palette.fonecaLogoGradient.main,
    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.4)",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "-2rem",
  },
  icon: {
    color: "#fff",
    fontSize: "2rem",
  },
  paperTitles: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  paperInfo: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1.1rem",
  },
  link: {
    textDecoration: "none",
    textAlign: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default function ContactsPage() {
  const classes = useStyles();

  return (
    <HeaderFooterContainer>
      <Container className={classes.mainContainer}>
        <Box className={classes.boxContainer}>
          <div className={classes.paperContainer}>
            <div className={classes.iconContainer}>
              <LocationOnIcon className={classes.icon} />
            </div>
            <Paper className={classes.paper}>
              <GradientTypography className={classes.paperTitles}>
                Address
              </GradientTypography>
              <Typography className={classes.paperInfo}>
                Foneca Inc.
                <br />
                2711 Centerville Rd. #120
                <br />
                Wilmington, DE 19808
              </Typography>
            </Paper>
          </div>
          <div className={classes.paperContainer}>
            <div className={classes.iconContainer}>
              <PermPhoneMsgIcon className={classes.icon} />
            </div>
            <Paper className={classes.paper}>
              <GradientTypography className={classes.paperTitles}>
                Phone
              </GradientTypography>
              <Typography className={classes.paperInfo}>
                USA Number:
                <br />
                <Link href="tel:+1 302 351 2121" title="+1 302 351 2121" className={classes.link}> +1 302 351 2121</Link>
              </Typography>

              <Typography className={classes.paperInfo}>
                Bulgarian Number:
                <br />
                <Link href="tel:+359-700-33911" title="+359-700-33911" className={classes.link}> +359-700-33911</Link>
              </Typography>
            </Paper>
          </div>
          <div className={classes.paperContainer}>
            <div className={classes.iconContainer}>
              <SmsIcon className={classes.icon} />
            </div>
            <Paper className={classes.paper}>
              <GradientTypography className={classes.paperTitles}>
                Email
              </GradientTypography>
              <Typography className={classes.paperInfo}>
                Sales:
                <br />
                <Link href="mailto:sales@foneca.com" target="_top" title="sales@foneca.com" className={classes.link}> sales@foneca.com </Link>
              </Typography>
              <Typography className={classes.paperInfo}>
                Technical Support:
                <br />
                <Link href="mailto:support@foneca.com" target="_top" title="support@foneca.com" className={classes.link}> support@foneca.com </Link>
              </Typography>
            </Paper>
          </div>
        </Box>
      </Container>
    </HeaderFooterContainer>
  );
}
