/* eslint-disable import/prefer-default-export */
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const initialState = {
  userId: "",
  username: "",
  email: "",
};

export const UserContext = createContext(initialState);

export const UserContextProvider = ({ children }) => {
  const [userState, setUserState] = useState(initialState);

  return (
    <UserContext.Provider
      value={{ userState, setUserState }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
