/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-arrow-callback */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Dialog, AppBar, Toolbar, List, ListItem, IconButton, Paper, Button, Slide, Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import GradientTypography from "../../GradientTypography/GradientTypography";
import { useShoppingCart } from "../../../../hooks/useShoppingCart/useShoppingCart";
import FonecaLogo from "../../../../static/logo/Logo.svg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: "10000 !important",
  },
  list: {
    padding: "1rem",
    background: "#fff",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    outline: "none",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("300")]: {
      padding: "0.5rem",
    },
  },
  listItem: {
    padding: "0.7rem",
    marginBottom: "1rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
    justifyContent: "space-between",
    "&:last-child": {
      marginBottom: "0rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "0.5rem",
    },
  },
  itemTypography: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bolder",
    marginRight: "1rem",
    width: "7rem",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginRight: "0.5rem",
      lineHeight: "1.3",
    },
  },
  removeProductButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.4rem",
    },
  },
  emptyCartList: {
    justifyContent: "center",
    padding: "1rem",
    boxShadow: theme.shadows[2],
    borderRadius: theme.radiuses[2],
  },
  emptyCartTypography: {
    fontWeight: "bolder",
  },
  imageHolder: {
    width: "8rem",
    marginRight: "1rem",
    display: "inline-grid",
  },
  image: {
    width: "100%",
    borderRadius: theme.radiuses[1],
  },
  paper: {
    boxShadow: theme.shadows[1],
    borderRadius: theme.radiuses[1],
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    alignItems: "center",
  },
  totalAmountTypography: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bolder",
    width: "7rem",
    textAlign: "center",
  },
  totalAmount: {
    WebkitTextFillColor: "#4F4F4F",
  },
  checkoutButton: {
    background: theme.palette.fonecaLogoGradient.main,
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: theme.radiuses[1],
    fontSize: "1.3rem",
    padding: "2rem 1rem",
    height: "3rem",
    "&:disabled": {
      color: "#fff",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
  appBar: {
    position: "relative",
    background: "#FEFEFE",
    boxShadow: theme.shadows[1],
  },
  closeButton: {
    color: theme.palette.darkerFoneca.main,
  },
  toolbar: {
    minHeight: "5rem",
  },
  logoHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "2rem",
  },
  title: {
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bolder",
    width: "100%",
    textAlign: "center",
  },
  itemsTypography: {
    width: "100%",
    color: "#4F4F4F",
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
  itemsNumberTypography: {
    marginRight: "0.5rem",
  },
  menuContainer: {
    padding: "1rem 1rem 2rem 1rem",
  },

}));

export default function MobileShoppingCart({
  isCartMenuDialogOpen, closeCartMenuDialog, onCheckoutClick,
}) {
  const classes = useStyles();
  const {
    getAllProducts, getTotal, removeProduct, getProductsCount,
  } = useShoppingCart();
  const history = useHistory();
  const handleHomeClick = () => history.push("/");
  const cartProducts = getAllProducts();

  return (
    <Dialog
      fullScreen
      open={isCartMenuDialogOpen}
      onClose={closeCartMenuDialog}
      TransitionComponent={Slide}
      transitionDuration={500}
      classes={{
        root: classes.dialog,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={closeCartMenuDialog} aria-label="close">
            <CloseIcon className={classes.closeButton} />
          </IconButton>
          <div className={classes.logoHolder} style={{ cursor: "pointer " }} onClick={handleHomeClick}>
            <img src={FonecaLogo} className={classes.logo} alt="foneca-log" />
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.menuContainer}>
        <GradientTypography className={classes.title}>Your Cart</GradientTypography>
        <Typography className={classes.itemsTypography}>
          <span className={classes.itemsNumberTypography}>{getProductsCount()}</span>
          Items
        </Typography>
        <List className={classes.list} id="products-list">
          {!getProductsCount() ? (
            <ListItem className={classes.emptyCartList}>
              <GradientTypography className={classes.emptyCartTypography}>
                Cart Is Empty !
              </GradientTypography>
            </ListItem>
          ) : (
            cartProducts?.map((product) => (
              <ListItem className={classes.listItem} key={product.key}>
                <div className={classes.imageHolder}>
                  <img alt="foneca-classic" src={product.image} className={classes.image} />
                </div>
                <GradientTypography className={classes.itemTypography}>
                  {product.name}
                  <span>
                    $
                    {product?.price?.price}
                  </span>
                </GradientTypography>
                <IconButton
                  className={classes.removeProductButton}
                  onClick={() => removeProduct(product)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            ))
          )}
        </List>
        <Paper className={classes.paper}>
          <GradientTypography className={classes.totalAmountTypography}>
            Total
            <span className={classes.totalAmount}>
              $
              {!getProductsCount() ? 0 : getTotal()}
            </span>
          </GradientTypography>
          <Button
            className={classes.checkoutButton}
            onClick={onCheckoutClick}
            disabled={!getProductsCount()}
          >
            Checkout
          </Button>
        </Paper>
      </div>
    </Dialog>
  );
}

MobileShoppingCart.propTypes = {
  isCartMenuDialogOpen: PropTypes.bool.isRequired,
  closeCartMenuDialog: PropTypes.func.isRequired,
  onCheckoutClick: PropTypes.func.isRequired,
};
