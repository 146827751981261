import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  TextField, CircularProgress, Typography, Grid,
} from "@material-ui/core";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import { updateBillingInfo } from "../../../api/BillingCalls/BillingCalls";
import { UserContext } from "../../../Context/UserContext/UserContext";

export default function CompanyInformationForm({ personalDetails, doesBillingInfoExist }) {
  const { openSnackbar } = useContext(SnackbarContext);
  const { userState } = useContext(UserContext);
  const userId = userState.id;

  const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm({ reValidateMode: "onSubmit" });

  const { mutate: updateCompanyInfoMutation, status: companyInfoUpdateStatus } = useMutation(
    updateBillingInfo,
    {
      onSuccess: () => {
        openSnackbar("success", "Successfully updated your company information!", "success-company-info-snackbar");
      },
      onError: () => {
        openSnackbar("error", "You need to fill your personal information first!", "error-company-info-snackbar");
      },
    },
  );

  const onSubmit = (data) => {
    updateCompanyInfoMutation({ data, userId });
  };

  useEffect(() => {
    if (doesBillingInfoExist) {
      setValue("company", personalDetails.company);
      setValue("company_address", personalDetails.company_address);
      setValue("company_email", personalDetails.company_email);
      setValue("bulstat_vat", personalDetails.bulstat_vat);
    }
  }, [personalDetails, doesBillingInfoExist, setValue]);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h6" align="left">Company Information (Optional)</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            required
            fullWidth
            label="Company Name"
            name="company"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.company}
            helperText={errors?.company?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-company-name"
          />
          <TextField
            required
            fullWidth
            label="Company Address"
            name="company_address"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.company_address}
            helperText={errors?.company_address?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-company-address"
          />
          <TextField
            required
            fullWidth
            label="Company Email"
            name="company_email"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              pattern: {
                value: emailRegex,
                message: "Please enter a valid email.",
              },
            })}
            error={!!errors.company_email}
            helperText={errors?.company_email?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-company-email"
          />
          <TextField
            required
            fullWidth
            label="Bulstat/VAT"
            name="bulstat_vat"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.bulstat_vat}
            helperText={errors?.bulstat_vat?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-company-bulstat"
          />
          <CustomSubmitButton disableElevation fullWidth variant="contained" color="primary" type="submit" data-cy="billing-company-save-button" style={{ margin: "2rem 0 0 0" }}>
            {
              (companyInfoUpdateStatus) === "loading" ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : ("Save")
            }
          </CustomSubmitButton>
        </form>
      </Grid>
    </Grid>
  );
}

CompanyInformationForm.propTypes = {
  personalDetails: PropTypes.shape({
    company: PropTypes.string,
    company_address: PropTypes.string,
    company_email: PropTypes.string,
    bulstat_vat: PropTypes.string,
  }),
  doesBillingInfoExist: PropTypes.bool.isRequired,
};
