import React from "react";
import PropTypes from "prop-types";
import {
  Grid, makeStyles, Paper, Dialog, DialogTitle,
  IconButton, DialogContent,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons/";
import GradientTypography from "../../../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 600,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: "center",
    borderRadius: "1rem",
    boxShadow: theme.shadows[2],
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 0,
  },
  dialogPaper: {
    borderRadius: theme.radiuses[2],
  },
  closeButton: {
    color: theme.palette.primary.main,
  },
}));

export default function HowToUseDialog({ isDialogOpened, onClose }) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const helpSteps = [
    {
      id: "0",
      number: "1.",
      description: "Just pick up the phone and dial the access number.",
    },
    {
      id: "1",
      number: "2.",
      description: "When prompted - enter your PIN number.",
    },
    {
      id: "2",
      number: "?",
      description: "To call Bulgaria enter 0+ area code in Bulgaria + phone number.\n No need to dial 011 359 !!! example: (0-2-112-1199)",
    },
    {
      id: "3",
      number: "?",
      description: "To call USA enter 1+ phone number.\n No need to dial 00 !!! example: (1-302-351-2121)",
    },
    {
      id: "4",
      number: "?",
      description: "To call any other country enter 11 + country code + phone number. \n example: (011-44-20-364-24101)",
    },
  ];

  return (
    <Dialog
      onClose={handleClose}
      open={isDialogOpened}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <GradientTypography variant="h6" className={classes.typography}>How to use?</GradientTypography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {
          helpSteps.map((step) => (
            <Grid container direction="row" key={step.id}>
              <Grid item xs={4} sm={2}>
                <Paper className={classes.paper} elevation={0}>
                  <GradientTypography variant={step.number !== "?" ? "h5" : "h6"} className={classes.typography}>
                    {step.number}
                  </GradientTypography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Paper className={classes.paper} elevation={0}>
                  <GradientTypography className={classes.typography} variant={step.number !== "?" ? "h5" : "subtitle1"} align="left">
                    {step.description}
                  </GradientTypography>
                </Paper>
              </Grid>
            </Grid>
          ))
        }
      </DialogContent>
    </Dialog>
  );
}

HowToUseDialog.propTypes = {
  isDialogOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
