import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import GradientTypography from "../../Dumb/GradientTypography/GradientTypography";
import CartItemCard from "./CartItemCard/CartItemCard";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "grid",
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 3),
      margin: theme.spacing(0, 1, 4),
    },
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    minHeight: "3rem",
  },
  productsPaper: {
    display: "grid",
    padding: theme.spacing(1),
    margin: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    minHeight: "3rem",
  },
}));

export default function CartItems({ products }) {
  const classes = useStyles();
  return (
    <Paper
      className={
        products.length > 0
          ? classes.productsPaper
          : classes.paper
      }
    >
      {
        products.length > 0
          ? products.map((product) => (
            <CartItemCard
              product={product}
              key={product.key}
            />
          ))
          : (
            <Grid container justify="center" alignItems="center" display="row">
              <GradientTypography variant="h6" className={classes.typography}>
                Your cart is empty!
              </GradientTypography>
            </Grid>
          )
      }
    </Paper>
  );
}

CartItems.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.shape({
      id: PropTypes.number,
      price: PropTypes.string,
    }),
    anis: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
  })).isRequired,
};
