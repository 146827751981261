import React, { useRef, useEffect, useContext } from "react";
import {
  Container, Typography, Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, useHistory } from "react-router-dom";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";
import Products from "./Products/Products";
import Rectangle from "../../static/images/services-svg/rectangle.svg";
import Hero from "../../static/images/services-svg/hero.svg";
import Ellipse from "../../static/images/services-svg/ellipse.svg";
import Leaves from "../../static/images/services-svg/leaves.svg";
import MobileCard from "../../static/images/services-svg/mobileCard.svg";
import Logo from "../../static/images/services-svg/logo.svg";
import mobileBackground from "../../static/images/services-svg/mobileBackground.svg";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  imagesContainer: {
    maxWidth: "none",
    backgroundImage: `url(${Rectangle})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100rem",
    position: "relative",
    overflow: "hidden",
    marginBottom: "-47rem",
  },
  heroImage: {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "55rem",
    width: "100%",
    position: "absolute",
    top: "-2rem",
    left: "-20rem",
  },
  ellipseImage: {
    backgroundImage: `url(${Ellipse})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "45rem",
    width: "100%",
    position: "absolute",
    top: "5%",
    right: "-40%",
  },
  leavesImage: {
    backgroundImage: `url(${Leaves})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "5rem",
    width: "100%",
    position: "absolute",
    top: "10rem",
    right: "-23rem",
    [theme.breakpoints.down("1160")]: {
      right: "-12rem",
    },
    [theme.breakpoints.down("790")]: {
      top: "13rem",
      zIndex: "1",
    },
  },
  mobileCardHolder: {
    width: "100%",
    position: "relative",
    top: "15rem",
    right: "-18rem",
    [theme.breakpoints.down("1160")]: {
      left: "50%",
      transform: "translate(-40%,0)",
    },
    [theme.breakpoints.down("790")]: {
      transform: "translate(-50%,0)",
    },
    [theme.breakpoints.down("xs")]: {
      position: "static",
      transform: "none",
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  mobileCard: {
    backgroundImage: `url(${MobileCard})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "25rem",
  },
  fonecaLogo: {
    height: "5.5rem",
    padding: "2rem 7rem 2rem 1rem",
    [theme.breakpoints.down("xs")]: {
      height: "4.7rem",
      padding: "4rem 5rem 0rem 1rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "3.5rem",
      padding: "8rem 2rem 2rem 0rem",
    },
    [theme.breakpoints.down("360")]: {
      height: "3rem",
      padding: "8rem 2rem 2rem 0rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "2.5rem",
    },
  },
  mobileCardTypographyAndButtonContainer: {
    display: "flex",
    height: "100%",
    width: "30rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "25rem",
    },
    [theme.breakpoints.down("460")]: {
      width: "18rem",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("360")]: {
      width: "15rem",
    },
    [theme.breakpoints.down("300")]: {
      width: "13rem",
    },
  },
  mobileCardTypohraphy: {
    fontSize: "4rem",
    color: "#fff",
    lineHeight: "1",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("460")]: {
      fontSize: "2rem",
      lineHeight: "1.2",
    },
    [theme.breakpoints.down("300")]: {
      fontSize: "1.7rem",
    },
  },
  mobileCardButton: {
    borderRadius: "0.875rem",
    backgroundColor: "#fff",
    height: "8rem",
    padding: "0rem 2rem",
    "&:hover": {
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      height: "7rem",
      padding: "0rem 1rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "5rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "4rem",
      padding: "0.5rem",
    },
  },
  mobileCardButtonTypography: {
    fontWeight: "bold",
    textTransform: "capitalize",
    [theme.breakpoints.down("360")]: {
      fontSize: "1.2rem",
    },
  },
  servicesTypogprahy: {
    textAlign: "center",
    fontWeight: "bold",
    padding: "1rem 0rem 0rem 12rem",
    [theme.breakpoints.down("790")]: {
      padding: "1rem 0rem 0rem 15.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
      marginTop: "3rem",
      WebkitTextFillColor: "#fff",
    },
  },
  productsContainer: {
    height: "auto",
    margin: "3rem auto",
    display: "block",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  mobileImagesContainer: {
    maxWidth: "none",
    backgroundImage: `url(${mobileBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100rem",
    marginBottom: "-65rem",
  },
}));

export default function ServicesPage() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const servicesBackground = !matchesMobileScreen ? `${classes.imagesContainer}` : `${classes.mobileImagesContainer}`;
  const scrollTo = useRef(null);
  const executeScroll = () => scrollTo.current.scrollIntoView();
  const location = useLocation();
  const history = useHistory();
  const { openSnackbar } = useContext(SnackbarContext);
  const isLoggedIn = () => !!localStorage.getItem("access_token");

  const handleRechargeButtonClick = () => {
    if (isLoggedIn()) {
      history.push("/my-products");
    } else {
      history.push("/login");
      openSnackbar("info", "Please login to your account so you can recharge.");
    }
  };

  useEffect(() => {
    if (location.hash === "#products") executeScroll();
  }, []);

  const desktopView = (
    <>
      <div className={classes.heroImage}>
        <div className={classes.leavesImage} />
      </div>
      <div className={classes.ellipseImage} />
    </>
  );

  return (
    <HeaderFooterContainer>
      <Container className={servicesBackground}>
        {!matchesMobileScreen ? desktopView : null}
        <div className={classes.mobileCardHolder}>
          <div className={classes.mobileCard}>
            <img src={Logo} alt="foneca-logo" title="foneca-logo" className={classes.fonecaLogo} />
            <div className={classes.mobileCardTypographyAndButtonContainer}>
              <Typography className={classes.mobileCardTypohraphy}>Need a Recharge?</Typography>
              <Button className={classes.mobileCardButton}>
                <GradientTypography
                  variant="h4"
                  className={classes.mobileCardButtonTypography}
                  onClick={handleRechargeButtonClick}
                >
                  Click Here
                </GradientTypography>
              </Button>
            </div>
          </div>
          <GradientTypography variant="h1" className={classes.servicesTypogprahy}>Services</GradientTypography>
        </div>
      </Container>
      <Container ref={scrollTo} justify="center" className={classes.productsContainer}>
        <Products />
      </Container>
    </HeaderFooterContainer>
  );
}
