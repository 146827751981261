import React from "react";
import PropTypes from "prop-types";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "../SnackbarContext/SnackbarContext";
import { UserContextProvider } from "../UserContext/UserContext";
import { DialogProvider } from "../DialogContext/DialogContext";

export default function CombinedContextProvider({ children }) {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <DialogProvider>
          <SnackbarProvider>
            <UserContextProvider>
              {children}
            </UserContextProvider>
          </SnackbarProvider>
        </DialogProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

CombinedContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
