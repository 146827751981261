import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStylesToolTip = makeStyles(() => ({
  arrow: {
    color: "rgba(0,0,0,0.5)",
  },
  tooltip: {
    fontSize: "1rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
}));

export default function CustomTooltip(props) {
  const classes = useStylesToolTip();

  return <Tooltip arrow classes={classes} {...props} />;
}
