import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Paper, IconButton, Tooltip, TextField,
} from "@material-ui/core";
import {
  DeleteForever as DeleteForeverIcon, CheckCircle as CheckCircleIcon, Edit as EditIcon,
} from "@material-ui/icons";
import GradientTypography from "../../GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "31.2rem",
    maxHeight: "15.2rem",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    margin: "2rem auto",
    padding: "1rem",
  },
  selectedCardPaper: {
    background: theme.palette.fonecaLogoGradient.main,
  },
  cardNumberAndExpiryDateHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1.5rem",
  },
  cardHolderAndCvcCodeContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleHolder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0rem 0.5rem",
    marginBottom: "1.5rem",
    alignItems: "center",
  },
  generalCardTitle: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
  selectedGeneralCardTitle: {
    WebkitTextFillColor: "#fff",
  },
  checkedButton: {
    color: theme.palette.green.main,
  },
  selectedCheckedButton: {
    color: "#fff",
  },
  deleteButton: {
    height: "3rem",
    width: "3rem",
    color: theme.palette.orangeFoneca.main,
    boxShadow: theme.shadows[2],
    transition: "none",
  },
  selectedIcons: {
    color: "#fff",
    backgroundColor: theme.palette.darkerFoneca.main,
    "&:hover": {
      backgroundColor: theme.palette.darkerFoneca.main,
    },
  },
  iconsHolder: {
    display: "flex",
  },
  editIcon: {
    boxShadow: theme.shadows[2],
    transition: "none",
  },
  textField: {
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("400")]: {
      marginRight: "1rem",
    },
    [theme.breakpoints.down("325")]: {
      marginRight: "0.5rem",
    },
  },
  selectedCardTextField: {
    backgroundColor: theme.palette.darkerFoneca.main,
  },
  textFieldInput: {
    color: theme.palette.darkerFoneca.main,
    fontFamily: "Roboto",
    fontSize: "1.3rem",
    fontWeight: "bolder",
    padding: "1rem 1rem",
    width: "11.2rem",
    [theme.breakpoints.down("400")]: {
      fontSize: "1.1rem",
      width: "10.3rem",
    },
    [theme.breakpoints.down("370")]: {
      fontSize: "1.05rem",
      width: "9rem",
    },
    [theme.breakpoints.down("285")]: {
      fontSize: "0.85rem",
      width: "7.2rem",
    },
  },
  selectedTextFieldInput: {
    color: "#fff",
  },
  expiryDateField: {
    width: "6.2rem",
    [theme.breakpoints.down("400")]: {
      width: "5.7rem",
    },
    [theme.breakpoints.down("285")]: {
      width: "5rem",
    },
  },
  expiryDateInputField: {
    textAlign: "center",
  },
  cvcField: {
    width: "5rem",
  },
  cvcInputField: {
    textAlign: "center",
  },
  noBorder: {
    border: "none",
  },
}));

export default function GeneralPaymentCard({
  deleteButton,
  selectedCard,
  deleteCardHandler,
  editCardHandler,
  cardNumber,
  cardExpiryDate,
  cardHolderName,
  onClickHandler,
}) {
  const classes = useStyles();

  return (
    <Paper
      className={clsx({
        [classes.paper]: true,
        [classes.selectedCardPaper]: selectedCard,
      })}
      onClick={onClickHandler}
    >
      <div className={classes.titleHolder}>
        <GradientTypography className={clsx(classes.generalCardTitle, {
          [classes.selectedGeneralCardTitle]: selectedCard,
        })}
        >
          Payment Information
        </GradientTypography>
        <div className={classes.iconsHolder}>
          <IconButton
            disabled
          >
            <CheckCircleIcon className={clsx(classes.checkedButton, {
              [classes.selectedCheckedButton]: selectedCard,
            })}
            />
          </IconButton>
          {deleteButton ? (
            <Tooltip title="Delete card">
              <IconButton
                className={clsx(classes.deleteButton, {
                  [classes.selectedIcons]: selectedCard,
                })}
                onClick={deleteCardHandler}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Click to edit your payment info">
              <IconButton
                onClick={editCardHandler}
                className={clsx(classes.editIcon, {
                  [classes.selectedIcons]: selectedCard,
                })}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.cardNumberAndExpiryDateHolder}>
        <TextField
          variant="outlined"
          className={clsx(classes.textField, {
            [classes.selectedCardTextField]: selectedCard,
          })}
          value={cardNumber}
          disabled
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: clsx(classes.textFieldInput, {
                [classes.selectedTextFieldInput]: selectedCard,
              }),
            },
          }}
        />
        <TextField
          variant="outlined"
          value={cardExpiryDate}
          disabled
          className={clsx(classes.textField, classes.expiryDateField, {
            [classes.selectedCardTextField]: selectedCard,
          })}
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: clsx(classes.textFieldInput, classes.expiryDateInputField, {
                [classes.selectedTextFieldInput]: selectedCard,
              }),
            },
          }}
        />
      </div>
      <div className={classes.cardHolderAndCvcCodeContainer}>
        <TextField
          variant="outlined"
          value={cardHolderName}
          disabled
          className={clsx(classes.textField, {
            [classes.selectedCardTextField]: selectedCard,
          })}
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: clsx(classes.textFieldInput, {
                [classes.selectedTextFieldInput]: selectedCard,
              }),
            },
          }}
        />
        <TextField
          variant="outlined"
          value="****"
          disabled
          className={clsx(classes.textField, classes.cvcField, {
            [classes.selectedCardTextField]: selectedCard,
          })}
          InputProps={{
            classes: {
              notchedOutline: classes.noBorder,
              input: clsx(classes.textFieldInput, classes.cvcInputField, {
                [classes.selectedTextFieldInput]: selectedCard,
              }),
            },
          }}
        />
      </div>
    </Paper>
  );
}

GeneralPaymentCard.propTypes = {
  cardNumber: PropTypes.string,
  cardExpiryDate: PropTypes.string,
  cardHolderName: PropTypes.string,
  deleteButton: PropTypes.bool,
  selectedCard: PropTypes.bool,
  deleteCardHandler: PropTypes.func,
  editCardHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
};

GeneralPaymentCard.defaultProps = {
  cardNumber: "**** **** **** ****",
  cardExpiryDate: "** / **",
  cardHolderName: "Cardholder Name",
  deleteButton: false,
  selectedCard: false,
};
