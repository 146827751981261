import React from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import StyledTableCell from "../StyledTableCell/StyledTableCell";

const headCells = [
  { id: "id", label: "Order", disableSort: false },
  { id: "items", label: "Item", disableSort: true },
  { id: "price", label: "Price", disableSort: true },
  { id: "access", label: "Access", disableSort: true },
  { id: "status", label: "Status", disableSort: false },
  { id: "total", label: "Total", disableSort: false },
  { id: "quantity", label: "Quantity", disableSort: true },
  { id: "created_at", label: "Date", disableSort: false },
  { id: "type", label: "Type", disableSort: false },
];

export default function EnhancedTableHead({ orderBy, order, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              disabled={headCell.disableSort}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "1rem",
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
