import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme/Theme";
import Routes from "./routes/Routes";
import CombinedContextProvider from "./Context/CombinedContextProvider/CombinedContextProvider";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CombinedContextProvider>
          <Routes />
        </CombinedContextProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
