import { loggedInAxios } from "../LoggedInCalls/LoggedInCalls";

// eslint-disable-next-line import/prefer-default-export
export async function createBillingInfo(billingData) {
  const response = await loggedInAxios.post("/billing-info/", billingData);
  return response;
}
export async function updateBillingInfo(billingInfo) {
  const response = await loggedInAxios.patch(`/billing-info/${billingInfo.userId}/`, billingInfo.data);
  return response;
}

export async function getBillingInfo() {
  const response = await loggedInAxios.get("/billing-info/");
  return response;
}
