/* eslint-disable consistent-return */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table, TableBody, TableCell, TableContainer,
  TableFooter, TableRow, Paper,
} from "@material-ui/core";
import TablePagination from "./TablePagination/TablePagination";
import Row from "./TableRow/TableRow";
import AccessNumbersDialog from "../AccessNumbersDialog/AccessNumbersDialog";
import EnhancedTableHead from "./TableHead/TableHead";
import { stableSort, getComparator } from "./sortUtils";
import StyledTableCell from "./StyledTableCell/StyledTableCell";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    minHeight: "480px",
  },
  paper: {
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[1],
    width: "100%",
  },
}));

export default function OrdersTable({ page, setPage, orders }) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accessNumbers, setAccessNumbers] = useState([]);
  const [isAccessNumbersDialogOpen, setIsAccessNumbersDialogOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openAccessNumbersDialogHandler = (products) => {
    const noDuplicateProducts = Object.values(products
      .reduce((acc, cur) => Object.assign(acc, { [cur.product_id]: cur }), {}))
      .map((item) => item.access_numbers);
    setAccessNumbers(noDuplicateProducts);
    setIsAccessNumbersDialogOpen(true);
  };

  const closeAccessNumbersDialogHandler = () => {
    setIsAccessNumbersDialogOpen(!isAccessNumbersDialogOpen);
  };

  function adjustTableHeightAccordingly() {
    const emptyRowsCount = rowsPerPage - Math.min(rowsPerPage, orders?.length - page * rowsPerPage);

    if (emptyRowsCount > 0) {
      return (
        <TableRow style={{ height: 43.83 * emptyRowsCount }}>
          <TableCell />
        </TableRow>
      );
    }
  }

  const [orderAscDesc, setOrderAscDesc] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderAscDesc === "asc";
    setOrderAscDesc(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} aria-label="customized table">
          <EnhancedTableHead
            order={orderAscDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(orders?.length === 0)
              ? (
                <TableRow>
                  <StyledTableCell colSpan={8} align="center">
                    There are no previous orders!
                  </StyledTableCell>
                </TableRow>
              ) : null}
            {(rowsPerPage > 0
              ? stableSort(orders, getComparator(orderAscDesc, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : orders
              // return empty orders?
            )?.map((order) => (
              <Row
                key={order.id}
                row={order}
                openAccessNumbersDialogHandler={openAccessNumbersDialogHandler}
              />
            ))}
            {
              adjustTableHeightAccordingly()
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rows={orders}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AccessNumbersDialog
        accessNumbers={accessNumbers}
        isAccessNumbersDialogOpen={isAccessNumbersDialogOpen}
        closeAccessNumbersDialogHandler={closeAccessNumbersDialogHandler}
      />
    </>
  );
}

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      completed_at: PropTypes.string,
      created_at: PropTypes.string,
      id: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
          price_id: PropTypes.number,
          product_name: PropTypes.string,
          product_id: PropTypes.number,
          anis: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
          })),
        }),
      ),
      paid_at: PropTypes.string,
      status: PropTypes.string,
      total: PropTypes.number,
      updated_at: PropTypes.string,
      user: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};
