import { loggedInAxios } from "../LoggedInCalls/LoggedInCalls";

export async function createOrder(data) {
  const response = await loggedInAxios.post("/orders/", data);
  return response;
}

export async function getUserCards() {
  const response = await loggedInAxios.get("/cards/");
  return response;
}

export async function getUserOrders() {
  const response = await loggedInAxios.get("/orders/");
  return response;
}

export async function recharge(data) {
  const response = await loggedInAxios.post("/recharges/", data);
  return response;
}
