import React from "react";
import {
  Menu, MenuItem, ListItem, Divider,
} from "@material-ui/core/";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  accountPopUpListItem: {
    textDecoration: "none",
    color: "#000",
    justifyContent: "center",
  },
}));

const DropDownMenu = withStyles((theme) => ({
  paper: {
    width: "167px",
    border: "1px solid #EFEFEF",
    boxSizing: "border-box",
    boxShadow: theme.shadows[2],
    borderRadius: "8px",
    "& .MuiMenu-list": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "0px",
    },
  },
}))((props) => (
  <Menu
    disableScrollLock
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

const DropDownMenuItem = withStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      color: theme.palette.common.black,
      fontSize: "1.1rem",
      fontWeight: "400",
    },
  },
}))(MenuItem);

export default function SignupDropDown({ element, open, onClose }) {
  const classes = useStyles();

  return (
    <DropDownMenu
      anchorEl={element}
      open={open}
      onClick={onClose}
    >
      <DropDownMenuItem>
        <ListItem className={classes.accountPopUpListItem} component={Link} to="/login">Login</ListItem>
      </DropDownMenuItem>
      <Divider />
      <DropDownMenuItem>
        <ListItem className={classes.accountPopUpListItem} component={Link} to="/signup">Signup</ListItem>
      </DropDownMenuItem>
    </DropDownMenu>
  );
}

SignupDropDown.defaultProps = {
  element: null,
};

SignupDropDown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  element: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
