import React from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Cancel as CancelIcon, Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import GradientTypography from "../../Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      textAlign: "left",
    },
  },
  infoCardContainer: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 3),
    },
  },
}));

export default function PaymentInfoPanel({ handleEditButton }) {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center" display="row" style={{ flexWrap: "nowrap" }}>
      <Grid item className={classes.infoCardContainer}>
        <GradientTypography variant="h5" className={classes.typography}>
          Payment Information
        </GradientTypography>
      </Grid>
      <Grid item style={{ display: "flex" }}>
        <IconButton
          disabled
        >
          <CancelIcon color="error" />
        </IconButton>
        <Tooltip title="Click to add your payment info">
          <IconButton
            className={classes.editIcon}
            onClick={handleEditButton}
            data-cy="add-payment-info-icon-button"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

PaymentInfoPanel.propTypes = {
  handleEditButton: PropTypes.func,
};
