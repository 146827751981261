import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RateCard from "./RateCard/RateCard";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import { rates } from "../../../static/mockData/rates";
import "./RatesSlider.css";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "2rem",
  },
  slider: {
    maxWidth: "90%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  cardContainer: {
    margin: "2rem",
    [theme.breakpoints.down("400")]: {
      margin: "1.6rem",
    },
  },
  gridContainer: {
    height: "17rem",
    background: "rgba(255, 255, 255, 0.3)",
    justifyContent: "center",
  },
}));
export default function Rates() {
  const classes = useStyles();

  const responsive = {
    0: { items: 1 },
    525: { items: 2 },
    900: { items: 3 },
    1350: { items: 4 },
    1680: { items: 5 },
  };

  const items = rates.map(({
    id, rate, country, flag,
  }) => (
    <Grid item className={classes.cardContainer} key={id}>
      <RateCard
        style={{ margin: "1rem" }}
        country={country}
        flag={flag}
        rate={rate}
      />
    </Grid>
  ));

  return (
    <>
      <GradientTypography variant="h2" className={classes.title}>Great International Rates</GradientTypography>
      <Grid container className={classes.gridContainer}>
        <Grid container className={classes.slider} id="rates-slider">
          <AliceCarousel
            mouseTracking
            disableButtonsControls
            disableDotsControls
            animationType="fadeout"
            animationDuration="1000"
            autoPlay="true"
            autoPlayInterval={5000}
            responsive={responsive}
            infinite
            items={items}
          />
        </Grid>
      </Grid>
    </>
  );
}
