import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, Paper, Avatar, Grid, CircularProgress, Container,
} from "@material-ui/core";
import clsx from "clsx";
import ManagePasswordForm from "./ManagePasswordForm/ManagePasswordForm";
import ManageEmailForm from "./ManageEmailForm/ManageEmailForm";
import MyProductsSummary from "./MyProductsSummary/MyProductsSummary";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "5rem",
    marginBottom: "5rem",
    minHeight: "calc(100vh - 12rem)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
  gridItem: {
    display: "grid",
    placeItems: "center",
    alignContent: "baseline",
    [theme.breakpoints.between(320, 350)]: {
      placeItems: "flex-end",
    },
    [theme.breakpoints.between(351, 420)]: {
      placeItems: "center",
      marginLeft: "3rem",
    },
  },
  paper: {
    display: "grid",
    justifyContent: "center",
    maxWidth: "300px",
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
      width: "calc(100vw - 11rem)",
    },
    [theme.breakpoints.down(300)]: {
      padding: "1rem",
      width: "calc(100vw - 11rem)",
    },
    margin: "10px",
    boxShadow: "0px 15px 60px rgba(16, 49, 109, 0.1)",
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    background: "none",
    color: theme.palette.primary.main,
    border: "1px solid",
    fontWeight: "bold",
    fontSize: "3rem",
  },
  usernameTypography: {
    margin: "1rem 0 0 0",
  },
  usernameGridItem: {
    height: "800px",
    [theme.breakpoints.down("sm", "xs")]: {
      height: "auto",
    },
  },
}));

export default function ProfilePage({ isLoading, username }) {
  const classes = useStyles();

  const extractAndCapitalizeFirstLetter = (name) => name.charAt(0).toUpperCase();

  return (
    <Container className={classes.container} maxWidth="lg">

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={clsx(classes.gridItem, classes.usernameGridItem)}
        >

          {/* Avatar */}
          <Paper
            className={classes.paper}
          >
            <Avatar className={classes.avatar}>
              { !isLoading && extractAndCapitalizeFirstLetter(username)}
            </Avatar>
            <Typography variant="h6" align="center" color="primary" className={classes.usernameTypography} data-cy="username-typography">
              { !isLoading ? username : <CircularProgress size={24} />}
            </Typography>
          </Paper>

          <MyProductsSummary maxItemsToShow={2} />

        </Grid>

        <Grid item xs={12} sm={6} md={6} className={classes.gridItem}>

          {/* ManagePasswordForm */}
          <Paper className={classes.paper}>
            <Typography color="primary" variant="h6">Manage Password</Typography>
            <ManagePasswordForm />
          </Paper>

          {/* ManageEmailForm */}
          <Paper className={classes.paper}>
            <Typography color="primary" variant="h6">Manage Email</Typography>
            <ManageEmailForm />
          </Paper>
        </Grid>

      </Grid>
    </Container>
  );
}

ProfilePage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
};
