import React, { useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  TextField, InputAdornment, IconButton, CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import { updatePassword } from "../../../api/ProfileCalls/ProfileCalls";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";

export default function ManagePasswordForm() {
  const { openSnackbar } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setError,
  } = useForm({ reValidateMode: "onSubmit" });

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    first: false,
    second: false,
    third: false,
  });

  const password = useRef({});
  password.current = watch("password", "");

  const displayErrorMessages = (err) => {
    /* eslint-disable no-unused-expressions */
    err.old_password && setError("old_password", { message: err.old_password[0] });
    err.password && setError("password", { message: err.password[0] });
    err.password_confirm && setError("password_confirm", { message: err.password_confirm[0] });
  };

  const { mutate: updatePasswordMutation, status: updatePasswordStatus } = useMutation(
    updatePassword,
    {
      onSuccess: () => {
        openSnackbar("success", "Password updated successfully!", "success-password-update-snackbar");
      },
      onError: (error) => {
        displayErrorMessages(error.response.data);
      },
    },
  );

  const onSubmit = (data) => {
    updatePasswordMutation(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        required
        fullWidth
        label="Old Password"
        name="old_password"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={
                  () => setIsPasswordVisible({
                    ...isPasswordVisible,
                    first: !isPasswordVisible.first,
                  })
                }
              >
                {isPasswordVisible?.first ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!errors.old_password}
        helperText={errors?.old_password?.message}
        variant="outlined"
        margin="normal"
        type={isPasswordVisible?.first ? "text" : "password"}
        data-cy="old-password"
      />
      <TextField
        required
        fullWidth
        label="New Password"
        name="password"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register({
          minLength: {
            value: 8,
            message: "Password must have at least 8 characters.",
          },
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={
                  () => setIsPasswordVisible({
                    ...isPasswordVisible,
                    second: !isPasswordVisible.second,
                  })
                }
              >
                {isPasswordVisible?.second ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!errors.password}
        helperText={errors?.password?.message}
        variant="outlined"
        margin="normal"
        type={isPasswordVisible?.second ? "text" : "password"}
        data-cy="new-password"
      />
      <TextField
        required
        fullWidth
        label="Confirm New Password"
        name="password_confirm"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={register({
          validate: (value) => value === password.current || "The passwords do not match.",
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={
                  () => setIsPasswordVisible({
                    ...isPasswordVisible,
                    third: !isPasswordVisible.third,
                  })
                }
              >
                {isPasswordVisible?.third ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!errors.password_confirm}
        helperText={errors?.password_confirm?.message}
        variant="outlined"
        margin="normal"
        type={isPasswordVisible?.third ? "text" : "password"}
        data-cy="new-password-confirm"
      />
      <CustomSubmitButton disableElevation fullWidth variant="contained" color="primary" type="submit" data-cy="update-password-button" style={{ margin: "2rem 0 0 0" }}>
        {
          updatePasswordStatus === "loading" ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : ("Update")
        }
      </CustomSubmitButton>
    </form>
  );
}
