import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Container, Button, Paper, Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GradientTypography from "../../GradientTypography/GradientTypography";
import frameTwoBackground from "../../../../static/images/homePage-images/carousel-frames/frame-one/rectangle.svg";
import ladyOnEarth from "../../../../static/images/homePage-images/carousel-frames/frame-two/ladyOnEarth.svg";
import frameTwoEllipse from "../../../../static/images/homePage-images/carousel-frames/frame-two/frameTwoEllipse.svg";

const useStyles = makeStyles((theme) => ({
  frameTwoBackground: {
    maxWidth: "none",
    backgroundImage: `url(${frameTwoBackground})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "57rem",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("670")]: {
      height: "50rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "45rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "38rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "30rem",
    },
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "0.1em",
    position: "absolute",
    zIndex: "2",
    lineHeight: "1",
    top: "5%",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "65rem",
    [theme.breakpoints.down("670")]: {
      fontSize: "4rem",
    },
  },
  ladyImage: {
    backgroundImage: `url(${ladyOnEarth})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "60rem",
    width: "100%",
    position: "absolute",
    transform: "translate(-26%,-15%) scale(1.1)",
    zIndex: "2",
    [theme.breakpoints.down("670")]: {
      transform: "translate(-3%,-30%) scale(1.7)",
    },
    [theme.breakpoints.down("370")]: {
      transform: "translate(-3%,-35%) scale(2.3)",
    },
  },
  ellipseImage: {
    backgroundImage: `url(${frameTwoEllipse})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    height: "50rem",
    width: "100%",
    position: "absolute",
    transform: "translate(40%,10%)",
    zIndex: "0",
    [theme.breakpoints.down("770")]: {
      height: "30rem",
      transform: "translate(40%,80%)",
    },
    [theme.breakpoints.down("670")]: {
      width: "0",
    },
  },
  paperHolder: {
    position: "absolute",
    zIndex: "3",
    left: "44%",
    top: "30%",
    [theme.breakpoints.down("1080")]: {
      left: "0",
      right: "0",
      top: "46%",
      marginLeft: "auto",
      marginRight: "auto",
      width: "35rem",
    },
    [theme.breakpoints.down("770")]: {
      top: "40%",
    },
    [theme.breakpoints.down("670")]: {
      width: "32rem",
      top: "45%",
    },
    [theme.breakpoints.down("550")]: {
      width: "27rem",
    },
    [theme.breakpoints.down("460")]: {
      width: "15rem",
      top: "43%",
    },
    [theme.breakpoints.down("340")]: {
      maxWidth: "13rem",
      top: "35%",
    },
  },
  paper: {
    maxWidth: "32rem",
    minHeight: "15rem",
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("670")]: {
      minHeight: "auto",
    },
    [theme.breakpoints.down("550")]: {
      maxWidth: "25rem",
      padding: "1rem",
    },
    [theme.breakpoints.down("460")]: {
      maxWidth: "16rem",
    },
    [theme.breakpoints.down("340")]: {
      maxWidth: "11rem",
    },
  },
  text: {
    fontSize: "2.5rem",
    lineHeight: "1.2",
    fontFamily: "Roboto",
    color: theme.palette.darkerFoneca.main,
    [theme.breakpoints.down("460")]: {
      lineHeight: "0.2",
    },
  },
  emphasizeText: {
    fontSize: "3rem",
    fontWeight: "bold",
    fontFamily: "Roboto",
    lineHeight: "1.2",
    [theme.breakpoints.down("670")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("420")]: {
      fontSize: "1.5rem",
    },
  },
  button: {
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    height: "5.7rem",
    padding: "0rem 2rem",
    alignSelf: "flex-end",
    marginTop: "-2rem",
    [theme.breakpoints.down("550")]: {
      height: "4.5rem",
      padding: "0rem 1rem",
      borderRadius: "0.875rem",
    },
    [theme.breakpoints.down("460")]: {
      marginTop: "0rem",
    },
  },
  buttonTypography: {
    fontWeight: "bold",
  },
}));

export default function FrameTwo({
  title, emphasizedPaperText, paperText, url,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("670"));

  return (
    <Container className={classes.frameTwoBackground}>
      <div className={classes.ladyImage} />
      {!matchesMobileScreen && (
        <GradientTypography variant="h1" className={classes.title}>
          {title}
        </GradientTypography>
      )}
      <div className={classes.paperHolder}>
        <Paper className={classes.paper}>
          <Typography className={classes.text}>
            <span className={classes.emphasizeText}>{emphasizedPaperText}</span>
            {!matchesMobileScreen ? paperText : ""}
          </Typography>
          <Button className={classes.button} onClick={() => history.push(url)}>
            <GradientTypography variant="h5" className={classes.buttonTypography}>Try It</GradientTypography>
          </Button>
        </Paper>
      </div>
      <div className={classes.ellipseImage} />
    </Container>
  );
}

FrameTwo.propTypes = {
  title: PropTypes.string.isRequired,
  emphasizedPaperText: PropTypes.string.isRequired,
  paperText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
