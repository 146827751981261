import BulgariaFlag from "../images/flags/bulgaria.svg";
import JapanFlag from "../images/flags/japan.svg";
import MexicoFlag from "../images/flags/mexico.svg";
import SpainFlag from "../images/flags/spain.svg";
import UKFlag from "../images/flags/united-kingdom.svg";
import USAFlag from "../images/flags/united-states-of-america.svg";
import BelgiumFlag from "../images/flags/belgium.svg";
import CanadianFlag from "../images/flags/canada.svg";
import CroatianFlag from "../images/flags/croatia.svg";
import FrenchFlag from "../images/flags/france.svg";
import GermanFlag from "../images/flags/germany.svg";
import IrishFlag from "../images/flags/ireland.svg";
import ItalianFlag from "../images/flags/italy.svg";
import NorwegianFlag from "../images/flags/norway.svg";
import PolishFlag from "../images/flags/poland.svg";

/* eslint-disable import/prefer-default-export */
export const rates = [
  {
    id: "bg-rate-id",
    rate: "0.015/min",
    country: "Bulgaria",
    flag: `${BulgariaFlag}`,
  },
  {
    id: "jp-rate-id",
    rate: "0.03/min",
    country: "Japan",
    flag: `${JapanFlag}`,
  },
  {
    id: "mx-rate-id",
    rate: "0.017c/min",
    country: "Mexico",
    flag: `${MexicoFlag}`,
  },
  {
    id: "sp-rate-id",
    rate: "0.02/min",
    country: "Spain",
    flag: `${SpainFlag}`,
  },
  {
    id: "uk-rate-id",
    rate: "0.02/min",
    country: "UK",
    flag: `${UKFlag}`,
  },
  {
    id: "usa-rate-id",
    rate: "0.015/min",
    country: "USA",
    flag: `${USAFlag}`,
  },
  {
    id: "belgium-rate-id",
    rate: "0.015/min",
    country: "Belgium",
    flag: `${BelgiumFlag}`,
  },
  {
    id: "canada-rate-id",
    rate: "0.016/min",
    country: "Canada",
    flag: `${CanadianFlag}`,
  },
  {
    id: "croatia-rate-id",
    rate: "0.017/min",
    country: "Croatia",
    flag: `${CroatianFlag}`,
  },
  {
    id: "france-rate-id",
    rate: "0.013/min",
    country: "France",
    flag: `${FrenchFlag}`,
  },
  {
    id: "germany-rate-id",
    rate: "0.017/min",
    country: "Germany",
    flag: `${GermanFlag}`,
  },
  {
    id: "ireland-rate-id",
    rate: "0.02/min",
    country: "Ireland",
    flag: `${IrishFlag}`,
  },
  {
    id: "italy-rate-id",
    rate: "0.017/min",
    country: "Italy",
    flag: `${ItalianFlag}`,
  },
  {
    id: "norway-rate-id",
    rate: "0.025/min",
    country: "Norway",
    flag: `${NorwegianFlag}`,
  },
  {
    id: "poland-rate-id",
    rate: "0.017/min",
    country: "Poland",
    flag: `${PolishFlag}`,
  },
];
