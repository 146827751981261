import React from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import MyProductCard from "./MyProductCard/MyProductCard";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";
import { getMyProducts } from "../../api/ServicesCalls/ServicesCalls";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 8.2rem)",
  },
  gridItem: {
    display: "grid",
    placeItems: "center",
    alignContent: "baseline",
    [theme.breakpoints.between(320, 350)]: {
      placeItems: "flex-end",
    },
    [theme.breakpoints.between(351, 420)]: {
      placeItems: "center",
      marginLeft: "3rem",
    },
  },
  title: {
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: "1rem",
    [theme.breakpoints.down(560)]: {
      marginLeft: "3rem",
    },
  },
  card: {
    [theme.breakpoints.down(560)]: {
      marginLeft: "3rem",
    },
  },
}));

export default function MyProductsPage() {
  const classes = useStyles();
  const { data: myProducts } = useQuery("myProducts", getMyProducts, {
    select: (response) => response.data,
    retry: false,
  });

  return (
    <Container className={classes.container} maxWidth="md">
      <Grid item xs={12} md={12} lg={12}>
        <GradientTypography
          className={classes.title}
          variant="h4"
        >
          My Products
        </GradientTypography>
      </Grid>
      {
        !myProducts?.length && (
        <GradientTypography
          className={classes.title}
          variant="h4"
        >
          No Products To Show
        </GradientTypography>
        )
      }
      <Grid container spacing={3}>
        {
          myProducts?.reverse().map(({
            pin, purchased, last_recharged, product: { id, name, image }, anis, rechargeable,
          }) => (
            <Grid key={uuidv4()} className={classes.gridItem} item xs={12} md={6}>
              <MyProductCard
                className={classes.card}
                id={id}
                pin={pin}
                purchasedDate={purchased}
                lastRechargedDate={last_recharged}
                productName={name}
                image={image}
                anis={anis.map((ani) => ({ label: ani }))}
                rechargeable={rechargeable}
              />
            </Grid>
          ))
        }
      </Grid>
    </Container>
  );
}
