import React, {
  useState, useRef, useContext, useEffect,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, TextField, FormControlLabel, Checkbox, CircularProgress, Container,
  InputAdornment, IconButton, Paper, FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import ReCAPTCHA from "react-google-recaptcha";
import { signup, verifyRecaptcha } from "../../api/AccountCalls/AccountCalls";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import MessageBox from "../../Components/Dumb/MessageBox/MessageBox";
import emailSentIllustration from "../../static/confidential_letter_illustration.svg";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 12.2rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
  },
  paper: {
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
    boxShadow: theme.shadows[2],
  },
  title: {
    marginBottom: "1.5rem",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0.5rem",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  termsCheckbox: {
    margin: "1rem 0rem 1rem",
  },
  recapcha: {
    transform: "scale(0.80)",
    transformOrigin: "0 0",
    maxWidth: "200px !important",
  },
  recapchaContainer: {
    margin: "0rem 0rem 2rem 0",
  },
  loginTypography: {
    [theme.breakpoints.down("330")]: {
      padding: "0rem 1rem",
    },
  },
}));

export default function SignupPage() {
  const classes = useStyles();
  const [isPasswordVisible, setIsPasswordVisible] = useState({ first: false, second: false });
  const [isSignupRequestSent, setIsSignupRequestSent] = useState(false);
  const { openSnackbar } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm({ reValidateMode: "onSubmit" });

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  const recaptchaRef = useRef({});

  const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const displayErrorMessages = (err) => {
    /* eslint-disable no-unused-expressions */
    err.username && setError("username", { message: err.username[0] });
    err.email && setError("email", { message: err.email[0] });
    err.password && setError("password", { message: err.password[0] });
  };

  const { mutate: signupMutation, status: signupStatus } = useMutation(
    signup,
    {
      onSuccess: (response) => {
        openSnackbar("success", `Confirmation email sent to ${response?.data.email}!`);
        setIsSignupRequestSent(true);
      },
      onError: (error) => {
        displayErrorMessages(error.response.data);
      },
    },
  );

  const { mutate: recaptchaMutation } = useMutation(
    verifyRecaptcha,
    {
      onError: () => {
        openSnackbar("error", "Something went wrong with the recaptcha verification!");
        recaptchaRef.current.reset();
      },
    },
  );

  const handleRecaptcha = (key) => {
    clearErrors("recaptcha");
    setValue("recaptcha", key);
    recaptchaMutation({ recaptcha: key });
  };

  const onSubmit = (data) => {
    signupMutation(data);
  };

  useEffect(() => {
    register({ name: "recaptcha" }, { required: true });
  });

  return (
    <HeaderFooterContainer>
      <Container className={classes.mainContainer} maxWidth="sm">
        <Paper className={classes.paper}>
          {isSignupRequestSent ? (
            <MessageBox
              title="Verification email sent!"
              subtitle="Please verify your email address in order to log in our system."
              illustration={emailSentIllustration}
              showButton
            />
          ) : (
            <>
              <Typography color="primary" variant="h2" className={classes.title} align="center">
                Sign Up
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  required
                  fullWidth
                  label="Username"
                  name="username"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register()}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  data-cy="username"
                />
                <TextField
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email.",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  margin="normal"
                  data-cy="email"
                />
                <TextField
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters.",
                    },
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={
                            () => setIsPasswordVisible({
                              ...isPasswordVisible,
                              first: !isPasswordVisible.first,
                            })
                          }
                        >
                          {isPasswordVisible?.first ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  margin="normal"
                  type={isPasswordVisible?.first ? "text" : "password"}
                  data-cy="password"
                />
                <TextField
                  required
                  fullWidth
                  label="Confirm Password"
                  name="password_confirm"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    validate: (value) => value === passwordRef.current || "The passwords do not match.",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={
                            () => setIsPasswordVisible({
                              ...isPasswordVisible,
                              second: !isPasswordVisible.second,
                            })
                          }
                        >
                          {isPasswordVisible?.second ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password_confirm}
                  helperText={errors?.password_confirm?.message}
                  variant="outlined"
                  margin="normal"
                  type={isPasswordVisible?.second ? "text" : "password"}
                  data-cy="password-confirm"
                />
                <Controller
                  name="terms_of_use"
                  control={control}
                  defaultValue={false}
                  render={(prop) => (
                    <FormControlLabel
                      className={classes.termsCheckbox}
                      control={(
                        <Checkbox
                          required
                          onChange={(e) => prop.onChange(e.target.checked)}
                          checked={prop.value}
                          data-cy="terms-checkbox"
                        />
                      )}
                      label={(
                        <>
                          <span>I agree to the </span>
                          <Link to="/terms" className={classes.link}>Terms and Conditions</Link>
                        </>
                      )}
                    />
                  )}
                />
                <div className={classes.recapchaContainer}>
                  <div className={classes.recapcha}>
                    <ReCAPTCHA
                      onChange={handleRecaptcha}
                      sitekey={process.env.REACT_APP_RECAPTCHA_TESTING_SITE_KEY}
                      ref={recaptchaRef}
                    />
                  </div>
                  {
                    errors.recaptcha
                && <FormHelperText error>Please, verify you are human.</FormHelperText>
                  }
                </div>
                <CustomSubmitButton disableElevation fullWidth variant="contained" color="primary" type="submit" data-cy="signup-button">
                  {
                    signupStatus === "loading" ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : ("Sign Up")
                  }
                </CustomSubmitButton>
              </form>
              <Typography variant="subtitle1" align="center" className={classes.loginTypography} gutterBottom>
                Already have an account?&ensp;
                <Link to="/login" className={classes.link} color="primary">
                  Log In
                </Link>
              </Typography>
            </>
          )}
        </Paper>
      </Container>
    </HeaderFooterContainer>
  );
}
