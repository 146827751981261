import React from "react";
import PropTypes from "prop-types";
import {
  Grid, makeStyles, Typography, Paper,
} from "@material-ui/core";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.orangeFoneca.main,
  },
  paper: {
    padding: theme.spacing(2, 1),
    margin: theme.spacing(0, 3),
    borderRadius: theme.radiuses[1],
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 1),
      margin: theme.spacing(2, 1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 1),
      margin: theme.spacing(2, 0),
    },
  },
  note: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    padding: 0,
  },
}));

export default function AccessNumbers({ accessNumbers }) {
  const classes = useStyles();

  return (
    <>
      {
        accessNumbers.map((accessNumber) => (
          <Grid item xs={12} lg={6} zeroMinWidth key={accessNumber.id}>
            <Paper className={classes.paper}>
              <GradientTypography
                paragraph
                align="center"
                variant="h5"
                color="primary"
                className={classes.typography}
              >
                {accessNumber.title}
              </GradientTypography>
              <Paper className={classes.paper}>
                {
                  accessNumber.notes && (
                  <Typography align="left" variant="subtitle1" color="primary" gutterBottom className={classes.note}>
                    {accessNumber.notes}
                  </Typography>
                  )
                }
                {accessNumber.numbers.map((num) => (
                  <Grid container direction="row" justify="space-between" key={num.id}>
                    <Grid item className={classes.text}>
                      <Typography align="left" variant="subtitle1" color="primary">
                        {num.number}
                      </Typography>
                    </Grid>
                    {
                      num.text
                     && (
                     <Grid item className={classes.text}>
                       <Typography align="left" variant="subtitle1" color="primary">
                         {num.text}
                       </Typography>
                     </Grid>
                     )
                    }
                  </Grid>
                ))}
              </Paper>
            </Paper>
          </Grid>
        ))
      }
    </>
  );
}

AccessNumbers.propTypes = {
  accessNumbers: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
