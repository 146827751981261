import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CustomSubmitButton from "../CustomSubmitButton/CustomSubmitButton";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "1rem",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  img: {
    maxWidth: "80%",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "2rem",
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: "2rem",
  },
}));

export default function MessageBox({
  title, subtitle, illustration, showButton,
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleBackToLoginClick = () => {
    history.push("/login");
  };

  return (

    <div data-cy="message-container">
      <Typography className={classes.title} variant="h4" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        {subtitle}
      </Typography>
      <div className={classes.imgContainer}>
        <img
          className={classes.img}
          src={illustration}
          alt="foneca-email-sent-illustration"
        />
      </div>
      {
        showButton
          && (
          <CustomSubmitButton
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleBackToLoginClick}
            disableElevation
            id="back-to-login-button"
          >
            Back to Login
          </CustomSubmitButton>
          )
      }
    </div>
  );
}

MessageBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  illustration: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};
