import React from "react";
import PropTypes from "prop-types";
import {
  Grid, makeStyles, Typography, ListItemIcon, List, ListItem, ListItemText,
} from "@material-ui/core";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  typography: {
    fontWeight: 600,
  },
  icon: {
    color: theme.palette.orangeFoneca.main,
  },
  listItemText: {
    color: theme.palette.primary.main,
  },
}));

export default function Description({ description, rates }) {
  const classes = useStyles();
  const extractSentances = (longDescription) => longDescription.split(".").filter((v) => v !== "");
  return (
    <Grid container justify="space-between" className={classes.root}>
      <Grid item>
        { extractSentances(description).map((sentance) => (
          <Typography
            paragraph
            color="primary"
            key={sentance}
            className={classes.typography}
            gutterBottom
          >
            {sentance}
            .
          </Typography>
        )) }
      </Grid>
      <Grid item>
        <List>
          {
            rates.map((rate) => (
              <ListItem
                key={rate.id}
              >
                <ListItemIcon>
                  <CheckCircleOutlineIcon fontSize="small" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={rate.text}
                />
              </ListItem>
            ))
          }
        </List>
      </Grid>
    </Grid>
  );
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
  })).isRequired,
};
