import React from "react";
import PropTypes from "prop-types";
import AppHeader from "../../Dumb/AppHeader/AppHeader";
import AppFooter from "../../Dumb/AppFooter/AppFooter";

export default function HeaderFooterContainer({ children }) {
  return (
    <>
      <AppHeader />
      {children}
      <AppFooter />
    </>
  );
}

HeaderFooterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
