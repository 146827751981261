import React, { useState, useContext } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  TextField, CircularProgress, Typography, Paper, Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sendResetPasswordLink } from "../../api/AccountCalls/AccountCalls";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import MessageBox from "../../Components/Dumb/MessageBox/MessageBox";
import emailSentIllustration from "../../static/confidential_letter_illustration.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 12.2rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
    maxWidth: "700px",
  },
  mainContainerMessageBox: {
    [theme.breakpoints.down("330")]: {
      margin: "2rem auto",
    },
  },
  paper: {
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
    boxShadow: theme.shadows[2],
  },
  title: {
    fontWeight: "bold",
    marginBottom: "1.5rem",
  },
  messageTypography: {
    fontWeight: "400",
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  usernameInput: {
    marginBottom: "4rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
}));

export default function ResetPasswordRequestLinkPage() {
  const classes = useStyles();
  const [isPasswordResetRequestSent, setIsPasswordResetRequestSent] = useState(false);
  const {
    register, handleSubmit, errors, setError,
  } = useForm({ reValidateMode: "onSubmit" });
  const { openSnackbar } = useContext(SnackbarContext);

  const displayErrorMessages = (err) => {
    /* eslint-disable no-unused-expressions */
    err.detail && setError("login", { message: "Please provide a valid username." });
  };

  const { mutate: resetPasswordLinkMutation, status: resetPasswordLinkStatus } = useMutation(
    sendResetPasswordLink,
    {
      onSuccess: () => {
        setIsPasswordResetRequestSent(true);
      },
      onError: (error) => {
        if (error?.response?.status === 404) {
          openSnackbar("error", "Username doesn't exist!", "invalid-username-error-snackbar");
          displayErrorMessages(error.response.data);
        }
      },
    },
  );

  const onSubmit = (username) => {
    resetPasswordLinkMutation(username);
  };

  return (
    <>
      {isPasswordResetRequestSent ? (
        <HeaderFooterContainer>
          <Container className={clsx(classes.mainContainer, classes.mainContainerMessageBox)}>
            <Paper className={classes.paper}>
              <MessageBox
                title="Password recovery email sent!"
                subtitle="An email has been sent to your recovery email address. Follow the directions in the email to reset your password."
                illustration={emailSentIllustration}
                showButton
              />
            </Paper>
          </Container>

        </HeaderFooterContainer>
      ) : (
        <HeaderFooterContainer>
          <Container className={classes.mainContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center" color="primary" className={classes.title}>Reset your password</Typography>
              <Typography align="center" variant="h5" className={classes.messageTypography}>
                Please enter your username and we will send you a
                link to reset your password to the email address associated with your account.
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="login"
                  id="username"
                  label="Username"
                  variant="outlined"
                  className={classes.usernameInput}
                  error={!!errors.login}
                  helperText={errors?.login?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: "Please fill in your username" })}
                />
                <CustomSubmitButton
                  fullWidth
                  variant="contained"
                  disableElevation
                  color="primary"
                  id="send-recovery-link-button"
                  type="submit"
                >
                  { resetPasswordLinkStatus === "loading" ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Send Email"}
                </CustomSubmitButton>
              </form>
            </Paper>
          </Container>
        </HeaderFooterContainer>
      ) }
    </>
  );
}
