/* eslint-disable array-bracket-spacing */
/* eslint-disable no-plusplus */
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const useShoppingCart = () => {
  const [products, setProducts] = useState([]);
  // eslint-disable-next-line comma-dangle
  const [cookies, setCookie, ] = useCookies(["cartItems"]);

  const isCookieInStorage = useCallback(
    () => !!cookies.cartItems,
    [cookies.cartItems],
  );

  const cookieOptions = {
    sameSite: "strict",
    secure: false,
  };

  const addProduct = (product) => setCookie(
    "cartItems",
    isCookieInStorage()
      ? [...cookies.cartItems, { ...product, key: uuidv4() }]
      : [{ ...product, key: uuidv4() }],
    cookieOptions,
  );

  const removeFirstDublicate = (cartItemsArray, productToRemove) => (
    cartItemsArray
      .filter(((index) => (cartItem) => (
        !(_.isEqual(cartItem, productToRemove))
      )
      // eslint-disable-next-line no-param-reassign
      || --index
      )(1))
  );

  const removeProduct = (productToRemove) => {
    setCookie(
      "cartItems",
      removeFirstDublicate(cookies.cartItems, productToRemove),
      cookieOptions,
    );
  };

  const getRounded = (number) => (Math.round((number + Number.EPSILON) * 100) / 100);
  const removeAllProducts = () => setCookie(
    "cartItems",
    [],
    cookieOptions,
  );

  const getTotal = () => getRounded(
    cookies?.cartItems?.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue?.price?.price),
      0,
    ),
  );

  const getAllProducts = () => products;
  const getProductsCount = () => cookies?.cartItems?.length ?? 0;

  useEffect(() => {
    if (isCookieInStorage()) setProducts(cookies?.cartItems?.sort((a, b) => a.id - b.id));
  }, [cookies?.cartItems, isCookieInStorage]);

  return {
    addProduct,
    getAllProducts,
    getProductsCount,
    removeProduct,
    getTotal,
    removeAllProducts,
  };
};
