/* eslint-disable max-len */
import React, {
  useState, useContext, useRef,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  TextField, Typography, InputAdornment, IconButton, CircularProgress, Paper, Container,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loginWithStatus } from "../../api/AccountCalls/AccountCalls";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 12.1rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
  },
  paper: {
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
    boxShadow: theme.shadows[2],
  },
  loginTypography: {
    marginBottom: "1.5rem",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0.5rem",
    },
  },
  link: {
    color: theme.palette.primary.main,
    outline: "none",
    textDecoration: "none",
  },
  forgotPasswordTypography: {
    padding: "1.5rem 1.0rem 3.0rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0.5rem 1.5rem",
    },
  },
  signupTypography: {
    [theme.breakpoints.down("330")]: {
      padding: "0rem 1rem",
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    register, handleSubmit, errors, setError, watch,
  } = useForm({ reValidateMode: "onSubmit" });
  const { openSnackbar } = useContext(SnackbarContext);

  const username = useRef({});
  username.current = watch("username", "");

  const displayErrorMessages = (err) => {
    /* eslint-disable no-unused-expressions */
    err.detail && setError("username", { message: "Please provide a valid username." });
    err.detail && setError("password", { message: "Please provide a valid password." });
  };

  const handleRedirectionOnLogin = (status, checkout) => {
    switch (status) {
    case "VERIFIED":
      if (checkout) {
        history.push("/confirm-and-pay");
        openSnackbar(
          "success",
          "You have items in your cart. Proceed to checkout.",
          "proceed-to-checkout-success-snackbar",
        );
      } else {
        history.push("/services");
        openSnackbar("success", `Welcome, ${username.current}!`, "login-success-snackbar");
      }
      break;
    case "UNVERIFIED":
      history.push("/billing");
      openSnackbar(
        "info",
        "Please, fill in your billing information. You need to be verified by administrator.",
        "billing-information-login-info-snackbar",
      );
      break;
    default:
      openSnackbar(
        "error",
        "Something went wrong",
        "error-login-with-status-snackbar",
      );
      break;
    }
  };

  const { mutate: loginMutation, status: loginStatus } = useMutation(
    loginWithStatus,
    {
      onSuccess: (response) => {
        const { data: { status } } = response;
        const checkout = !!localStorage.getItem("checkout");
        handleRedirectionOnLogin(status, checkout);
      },
      onError: (error) => {
        if (error.response.status === 401) {
          openSnackbar("error", error.response.data.detail, "wrong-credentials-error-snackbar");
          displayErrorMessages(error.response.data);
          return;
        }
        if (error?.response?.data?.password[0]) {
          openSnackbar("error", error?.response?.data.password[0], "wrong-password-error-snackbar");
          setError("password", { message: "Please provide a valid password or click \"Forgot password?\" to restore it." });
        }
      },
    },
  );

  const onSubmit = (formData) => {
    loginMutation(formData);
  };

  return (
    <HeaderFooterContainer>
      <Container className={classes.mainContainer} maxWidth="sm">
        <Paper className={classes.paper}>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1rem",
            }}
            className={classes.paper}
          >
            <Typography
              variant="h5"
              align="center"
              color="primary"
            >
              Are you an existing user?
            </Typography>
            <Typography variant="h3" align="center" style={{ fontWeihgt: "bold" }}>
              <Link to="/send-recovery-email" className={classes.link}>
                Click Here
              </Link>
            </Typography>
            <Typography variant="h5" align="center" style={{ fontWeihgt: "bold" }} color="primary">
              Or go to
              {" "}
              <Link to="/send-recovery-email" className={classes.link}>
                "Forgot Password?"
              </Link>
            </Typography>
          </Paper>

          <Typography variant="h2" align="center" color="primary" className={classes.loginTypography}>
            Log In
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              margin="normal"
              name="username"
              id="username"
              label="Username"
              variant="outlined"
              error={!!errors.username}
              helperText={errors?.username?.message}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: "Please fill in your username" })}
            />
            <TextField
              fullWidth
              margin="normal"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              label="Password"
              variant="outlined"
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputRef={register({ required: "Please fill in your password" })}
            />
            <Typography align="right" variant="subtitle1" className={classes.forgotPasswordTypography}>
              <Link to="/send-recovery-email" className={classes.link}>
                Forgot Password?
              </Link>
            </Typography>
            <CustomSubmitButton
              fullWidth
              variant="contained"
              disableElevation
              color="primary"
              id="login-submit-button"
              type="submit"
            >
              { loginStatus === "loading" ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Log In"}
            </CustomSubmitButton>
          </form>
          <Typography align="center" variant="subtitle1" className={classes.signupTypography}>
            Don't have an account? &ensp;
            <Link to="/signup" className={classes.link}>
              Sign Up
            </Link>
          </Typography>
        </Paper>
      </Container>
    </HeaderFooterContainer>
  );
}
