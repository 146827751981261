/* eslint-disable no-unused-expressions */
import React from "react";
import PropTypes from "prop-types";
import {
  Grid, makeStyles, Typography, Tooltip,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import ChipsArray from "../../../Components/Dumb/ChipsArray/ChipsArray";

const MAX_ANIs_COUNT = 5;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  typography: {
    fontWeight: 600,
  },
  button: {
    color: theme.palette.tertiary.main,
    textTransform: "none",
    margin: "4px -4px",
    "&:disabled": {
      color: theme.palette.tertiary.main,
    },
    height: "2.8rem",
  },
  textfieldContainer: {
    width: "inherit",
  },
}));

export default function ANIs({ chipData, setChipData }) {
  const classes = useStyles();

  const {
    control,
    errors,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm();

  const isPhoneValid = (phoneNumber) => isValidNumber(phoneNumber);

  const isAniInArray = (num) => chipData?.find((chip) => chip.label === num);

  const addNewChip = (num) => setChipData([...chipData, { label: num }]);

  const getANIsCount = () => MAX_ANIs_COUNT - chipData?.length;

  const setErrorOnCondition = (condition, errorMessage) => { if (condition) setError("ani", { message: errorMessage }); };

  const validateAni = (number) => {
    setErrorOnCondition(isAniInArray(number), "This number is already in the list.");
    setErrorOnCondition((number === "" || !isPhoneValid(number)), "Please enter a valid number.");
  };

  const formatAni = (number) => {
    const phone = parsePhoneNumber(number);
    return phone.number;
  };

  const addNewANIToChips = () => {
    const ani = getValues("ani");
    setValue("ani", "");
    clearErrors("ani");
    validateAni(ani);

    const formattedAni = formatAni(ani);
    !errors.ani && addNewChip(formattedAni);
  };

  const handleAddANIButtonClick = () => addNewANIToChips();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addNewANIToChips();
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      className={classes.root}
    >
      <Grid item>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item>
            <GradientTypography variant="h5" className={classes.typography}>
              Enter Caller ID
            </GradientTypography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" align="left" color="secondary">
              The number you are calling from.
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.textfieldContainer}
          >
            <Tooltip title="Your mobile, home or office number." placement="top">
              <Controller
                as={MuiPhoneNumber}
                onlyCountries={["us", "bg"]}
                countryCodeEditable={false}
                disableAreaCodes
                required
                fullWidth
                margin="normal"
                label="Add a phone number."
                name="ani"
                variant="outlined"
                defaultCountry="us"
                defaultValue=""
                control={control}
                error={!!errors.ani}
                helperText={errors.ani ? errors.ani.message : `${getANIsCount()} numbers remaining.`}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <CustomSubmitButton
                      disabled={chipData?.length === MAX_ANIs_COUNT}
                      onClick={handleAddANIButtonClick}
                      className={classes.button}
                      data-cy="add-ani-button"
                    >
                      Add
                    </CustomSubmitButton>
                  ),
                }}
                data-cy="ani"
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-start">
          <ChipsArray
            array={chipData}
            setArray={setChipData}
            deleteButton
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ANIs.propTypes = {
  setChipData: PropTypes.func.isRequired,
  chipData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
};
