import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ChipsArray from "../ChipsArray/ChipsArray";
import GradientTypography from "../GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 600,
  },
  innerPaper: {
    padding: theme.spacing(2, 3),
    margin: theme.spacing(2, 0, 1, 0),
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(3, 1),
    },
  },
}));

export default function ProductANIs({ anis }) {
  const classes = useStyles();

  return (
    <Paper className={classes.innerPaper}>
      <Grid container display="row" justify="center" spacing={1}>
        <Grid item xs={12} md={6} lg={6}>
          <GradientTypography
            variant="h4"
            align="center"
            className={classes.typography}
          >
            Caller IDs
          </GradientTypography>
          <Typography variant="subtitle2" align="center" className={classes.typography}>
            The number you will be calling from.
          </Typography>
        </Grid>
        <Grid item>
          <Grid item xs={12} md={6} lg={6}>
            <ChipsArray array={anis} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

ProductANIs.propTypes = {
  anis: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
};
