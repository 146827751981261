import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomSubmitButton = withStyles((theme) => ({
  root: {
    background: theme.palette.fonecaLogoGradient.main,
    height: "3rem",
    fontSize: "1.2rem",
    borderRadius: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}))(Button);

export default CustomSubmitButton;
