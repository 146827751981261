import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  IconButton, makeStyles,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons/";
import clsx from "clsx";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  dialogPaper: {
    borderRadius: theme.radiuses[2],
    padding: "8px",

  },
  dialogActions: {
    padding: "4px",
  },
  closeIconButton: {
    color: theme.palette.primary.main,
  },
  button: {
    height: theme.spacing(6),
    fontSize: "1rem",
    boxShadow: theme.shadows[0],
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: theme.shadows[0],
    },
  },
  cancelButton: {
    border: 0,
    "&:hover": {
      border: 0,
      boxShadow: theme.shadows[1],
    },
  },
}));

const initialState = {
  isDialogOpened: false,
  dialogTitle: "",
  dialogMessage: "",
  handleClickFuncion: () => {},
  buttonText: "",
  buttonId: "",
};

export const DialogContext = createContext(initialState);

export const DialogProvider = ({ children }) => {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(initialState);

  const closeDialog = () => { setDialogState({ ...dialogState, isDialogOpened: false }); };

  const openDialog = (dialogTitle, dialogMessage, handleClickFuncion, buttonText, buttonId) => {
    setDialogState({
      isDialogOpened: true,
      dialogTitle,
      dialogMessage,
      handleClickFuncion,
      buttonText,
      buttonId,
    });
  };

  return (
    <DialogContext.Provider value={{ openDialog, dialogState, closeDialog }}>
      <Dialog
        open={dialogState.isDialogOpened}
        onClose={closeDialog}
        disableBackdropClick
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle
          disableTypography
          classes={{
            root: classes.dialogTitle,
          }}
        >
          <GradientTypography variant="h5" style={{ marginLeft: "1.5rem" }}>
            {dialogState.dialogTitle}
          </GradientTypography>
          <IconButton aria-label="close" className={classes.closeIconButton} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogState.dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            onClick={closeDialog}
            color="primary"
            className={clsx(classes.button, classes.cancelButton)}
          >
            Cancel
          </Button>
          <CustomSubmitButton
            variant="contained"
            color="primary"
            onClick={dialogState.handleClickFuncion}
            id={dialogState.buttonId}
            className={classes.button}
          >
            {dialogState.buttonText}
          </CustomSubmitButton>
        </DialogActions>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
