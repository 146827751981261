import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, CardContent, Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import GradientTypography from "../../../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.radiuses[2],
    boxShadow: theme.shadows[1],
    width: "270px",
    [theme.breakpoints.down("xs")]: {
      width: "230px",
    },
    [theme.breakpoints.down("525")]: {
      width: "270px",
    },
    [theme.breakpoints.down("281")]: {
      width: "220px",
    },
  },
  countryName: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
  },
  flag: {
    width: "5rem",
    height: "100%",
    borderRadius: "1rem",
    boxShadow: "0px 15px 60px rgba(16, 49, 109, 0.25)",
    margin: "1rem",
  },
  rate: {
    textAlign: "center",
  },
}));

export default function RateCard({ country, flag, rate }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" justifyContent="space-around">
          <GradientTypography className={classes.countryName} variant="h4" component="h2">
            {country}
          </GradientTypography>
          <img className={classes.flag} src={flag} alt={country} />
        </Box>
        <GradientTypography className={classes.rate} variant="h4">
          $
          {rate}
        </GradientTypography>
      </CardContent>
    </Card>
  );
}

RateCard.propTypes = {
  country: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
};
