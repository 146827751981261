import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginPage from "../Pages/LoginPage/LoginPage";
import SignupPage from "../Pages/SignupPage/SignupPage";
import VerifyingUserPage from "../Pages/VerifyingUserPage/VerifyingUserPage";
import ResetPasswordPage from "../Pages/ResetPasswordRequestLinkPage/ResetPasswordRequestLinkPage";
import ChangePasswordPage from "../Pages/ChangePasswordPage/ChangePasswordPage";
import ServicesPage from "../Pages/ServicesPage/ServicesPage";
import LandingPage from "../Pages/LandingPage/LandingPage";
import LoggedInEntryPage from "../Pages/LoggedInEntryPage/LoggedInEntryPage";
import PrepaidPhoneCardPage from "../Pages/PrepaidPhoneCardPage/PrepaidPhoneCardPage";
import ContactSupportPage from "../Pages/ContactSupportPage/ContactSupportPage";
import PrivateRoute from "./PrivateRoute";
import ConfirmAndPayPage from "../Pages/ConfirmAndPayPage/ConfirmAndPayPage";
import ContactsPage from "../Pages/ContactsPage/ContactsPage";
import AboutUsPage from "../Pages/AboutUsPage/AboutUsPage";
import StripeElementsProvider from "../Context/StripeElementsProvider/StripeElementsProvider";
import RechargePage from "../Pages/RechargePage/RechargePage";

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute
        path={["/profile", "/billing", "/orders"]}
        component={LoggedInEntryPage}
      />
      <Route path="/" component={LandingPage} exact />
      <Route path="/login" component={LoginPage} exact />
      <Route path="/signup" component={SignupPage} exact />
      <Route path="/services" component={ServicesPage} exact />
      <Route path="/verify-user" component={VerifyingUserPage} />
      <Route path="/send-recovery-email" component={ResetPasswordPage} />
      <Route path="/reset-password" component={ChangePasswordPage} />
      <Route path="/services/prepaid-cards/:id" component={PrepaidPhoneCardPage} />
      <Route path="/contacts" component={ContactsPage} />
      <Route path="/about-us" component={AboutUsPage} />
      <Route
        path="/confirm-and-pay"
        render={() => (
          <StripeElementsProvider>
            <ConfirmAndPayPage />
          </StripeElementsProvider>
        )}
      />
      {/* TODO: Should we add this to private route? */}
      <Route
        path="/recharge/:id/:pin"
        render={() => (
          <StripeElementsProvider>
            <RechargePage />
          </StripeElementsProvider>
        )}
      />
      <PrivateRoute
        path={["/profile", "/billing", "/my-products"]}
        component={LoggedInEntryPage}
      />
      <Route component={ContactSupportPage} />
    </Switch>
  );
}
