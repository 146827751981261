/* eslint-disable max-len */
import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Container, Box, Typography,
} from "@material-ui/core";
import HeaderFooterContainter from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import AboutUsImage from "../../static/images/abou-us-page/about-us.svg";
import MobileBackground from "../../static/images/services-svg/mobileBackground.svg";
import GradientTypography from "../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 14.1rem)",
    margin: "3rem auto",
    position: "relative",
    [theme.breakpoints.down("1300")]: {
      maxWidth: "none",
      backgroundImage: `url(${AboutUsImage})`,
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("905")]: {
      backgroundPosition: "bottom",
    },
    [theme.breakpoints.down("731")]: {
      backgroundImage: `url(${MobileBackground})`,
      margin: "0rem",
      padding: "3rem 1rem",
      backgroundSize: "cover",
    },
  },
  aboutUsBoxConainter: {
    width: "40%",
    [theme.breakpoints.down("1140")]: {
      width: "100%",
    },
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("1140")]: {
      width: "100%",
      marginBottom: "2rem",
    },
  },
  image: {
    width: "60%",
    height: "auto",
    [theme.breakpoints.down("1370")]: {
      width: "75%",
    },
    [theme.breakpoints.down("1300")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "3.5rem",
    fontWeight: "bolder",
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down("1300")]: {
      marginBottom: "0rem",
    },
    [theme.breakpoints.down("731")]: {
      WebkitTextFillColor: "#fff",
      marginBottom: "2rem",
    },
  },
  fonecaTypography: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    color: "#10316D",
  },
  typographyBubbleOne: {
    position: "absolute",
    maxWidth: "25rem",
    backgroundColor: "#fff",
    padding: "1.125rem 1.5rem",
    borderRadius: "40px",
    fontSize: "1.2rem",
    top: "0rem",
    left: "-10rem",
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("1700")]: {
      left: "-6rem",
    },
    [theme.breakpoints.down("1570")]: {
      left: "-1rem",
    },
    [theme.breakpoints.down("1370")]: {
      left: "1rem",
    },
    [theme.breakpoints.down("1300")]: {
      position: "static",
      maxWidth: "50rem",
      marginBottom: "3rem",
    },
    "&::before": {
      content: "\"\"",
      width: "40px",
      height: "40px",
      borderRadius: "100%",
      position: "absolute",
      backgroundColor: "#fff",
      boxShadow: "0 0.125rem 0.5rem rgba(16, 49, 109, 0.3), 0 0.0625rem 0.125rem rgba(16, 49, 109, 0.2)",
      right: "-2.5rem",
      bottom: "-1.5rem",
      [theme.breakpoints.down("1570")]: {
        right: "3rem",
        bottom: "-3rem",
      },
      [theme.breakpoints.down("1370")]: {
        right: "5rem",
        bottom: "-3rem",
      },
      [theme.breakpoints.down("1300")]: {
        display: "none",
      },
    },
    "&::after": {
      content: "\"\"",
      width: "25px",
      height: "25px",
      borderRadius: "100%",
      position: "absolute",
      backgroundColor: "#fff",
      boxShadow: "0 0.125rem 0.5rem rgba(16, 49, 109, 0.3), 0 0.0625rem 0.125rem rgba(16, 49, 109, 0.2)",
      right: "-4.3rem",
      bottom: "-3.5rem",
      [theme.breakpoints.down("1570")]: {
        right: "2rem",
        bottom: "-5rem",
      },
      [theme.breakpoints.down("1370")]: {
        right: "4rem",
        bottom: "-5rem",
      },
      [theme.breakpoints.down("1300")]: {
        display: "none",
      },
    },
  },
  bubbleTwoContainer: {
    [theme.breakpoints.down("1300")]: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "3rem",
    },
  },
  typographyBubbleTwo: {
    position: "absolute",
    top: "-1.5rem",
    right: "-4rem",
    width: "24rem",
    fontSize: "1.2rem",
    background: "#fff",
    boxShadow: theme.shadows[1],
    borderRadius: "40px",
    padding: "1.5rem",
    textAlign: "left",
    color: "#000",
    [theme.breakpoints.down("1440")]: {
      right: "1rem",
    },
    [theme.breakpoints.down("1300")]: {
      position: "static",
    },
    [theme.breakpoints.down("731")]: {
      width: "auto",
    },
    "&::before": {
      content: "\"\"",
      position: "absolute",
      width: "0",
      height: "0",
      top: "100%",
      left: "3em",
      border: ".75rem solid transparent",
      borderBottom: "none",
      borderTopColor: "#fff",
      filter: "drop-shadow(0 0.0625rem 0.0425rem rgba(16, 49, 109, 0.1))",
      [theme.breakpoints.down("1300")]: {
        display: "none",
      },
    },
  },
  typographyBubbleThree: {
    position: "relative",
    maxWidth: "42rem",
    fontSize: "1.2rem",
    backgroundColor: "#fff",
    padding: "1.125rem 1.5rem",
    borderRadius: "40px",
    bottom: "-1rem",
    left: "10rem",
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("1370")]: {
      bottom: "2.5rem",
    },
    [theme.breakpoints.down("1300")]: {
      position: "static",
      maxWidth: "50rem",
    },
    "&::before": {
      content: "\"\"",
      position: "absolute",
      width: "0",
      height: "0",
      bottom: "100%",
      left: "30rem",
      border: ".75rem solid transparent",
      borderTop: "none",
      borderBottomColor: "#fff",
      filter: "drop-shadow(0 -0.0625rem 0.0625rem rgba(16, 49, 109, 0.1))",
      [theme.breakpoints.down("1570")]: {
        left: "35rem",
      },
      [theme.breakpoints.down("1300")]: {
        display: "none",
      },
    },
  },
  paragraphTypography: {
    fontSize: "1.2rem",
    marginTop: "0.5rem",
  },
  lastParagraphTypography: {
    fontSize: "1.2rem",
  },
  emphasizeText: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    color: "#10316D",
  },
}));

export default function AboutUsPage() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.down("1300"));

  return (
    <HeaderFooterContainter>
      <Container className={classes.mainContainer}>
        <GradientTypography className={classes.title}>About Us</GradientTypography>
        {!matchesMediumScreen && (
          <Box className={classes.imageContainer}>
            <img className={classes.image} src={AboutUsImage} alt="about-us" title="about-us-image" />
          </Box>
        )}
        <Typography className={classes.typographyBubbleOne}>
          <span className={classes.fonecaTypography}>FONECA Inc. </span>
          was founded to address the growing demand of reliable, affordable and cost-effective worldwide communication.
          <br />
          <Typography className={classes.paragraphTypography}>
            <span className={classes.emphasizeText}>The beginning </span>
            was in 2002 when Boston Connection was established in Massachusetts.
            The next year FONECA Inc. acquired Boston Connection to further develop the supply of up-to-date telecom solutions.
            We are a Delaware corporation with sales representatives throughout USA and Europe.
            We see that the world is progressively breaking the boundaries to communication.
          </Typography>
          <Typography className={classes.lastParagraphTypography}>
            <span className={classes.emphasizeText}>This is </span>
            a world where people make the decisions as they know best their own needs.
          </Typography>
        </Typography>
        <div className={classes.bubbleTwoContainer}>
          <Typography className={classes.typographyBubbleTwo}>
            <span className={classes.fonecaTypography}>FONECA </span>
            is a service integrator and a distributor of telecommunication services.
            <Typography className={classes.paragraphTypography}>
              <span className={classes.emphasizeText}>We focus </span>
              on serving end users by offering a comprehensive solution for high-quality up-to-date telecom services at favorable rates.
              In order to keep up with the current telecom environment, FONECA is focusing on VOIP (Voice over IP) and the related opportunities.
            </Typography>
          </Typography>
        </div>
        <Typography className={classes.typographyBubbleThree}>
          <span className={classes.fonecaTypography}>FONECA </span>
          provides solutions for cost-effective long distance and worldwide modern telecommunications.
          <Typography className={classes.paragraphTypography}>
            <span className={classes.emphasizeText}>To deliver </span>
            maximum value and entirely meet their telecom needs, we build and maintain close
            relations with our clients but also provide them with the opportunity of totally self-managed personal accounts.
            These entitle the clients to choose their products/services, to make and checkout their orders and to monitor their assets in details.
            We make our best to provide them with a quick and reliable service and have a Customer Support team available 24/7 to solve
            any difficulties they may experience.
          </Typography>
        </Typography>
      </Container>
    </HeaderFooterContainter>
  );
}
