export const data = [
  {
    id: 0,
    comment: "I highly recommend the new card Foneca Classic.It has great rates, excellent quality, and customer service always available.",
    rating: 5,
  },
  {
    id: 1,
    comment: "I have used Foneca for over 2 years and they are excellent.Great customer service, unbelievable rates and clean quality.",
    rating: 5,
  },
  {
    id: 2,
    comment: "I want to recommend Bulgaria GSM service.I've used it several times when I was on vacation in Bulgaria.With this service I was in contact with my family and friends in the US.",
    rating: 5,
  },
];
