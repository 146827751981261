import React, { useState, useContext } from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import {
  Container, Box, ButtonBase, Grid, CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";
import ProductCard from "../../../Components/Dumb/Cards/ProductCard/ProductCard";
import RechargeCard from "../../../Components/Dumb/Cards/RechargeCard/RechargeCard";
import ShortDescription from "../../../Components/Dumb/ShortDescription/ShortDescription";
import { getProductList, getCategoryList } from "../../../api/ServicesCalls/ServicesCalls";

const CategoryButton = withStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[2],
    "&:hover": {
      background: "linear-gradient(180deg, #10316D 0%, #295EBD 100%)",
      color: "#fff",
    },
    margin: "0rem 0.5rem",
    width: "100%",
    padding: "1rem",
    fontSize: "1.2rem",
    borderRadius: "3.7rem",
    color: "#000",
    fontWeight: "400",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0.25rem",
    },
    [theme.breakpoints.down("370")]: {
      padding: "0.8rem",
    },
    [theme.breakpoints.down("350")]: {
      padding: "0.5rem",
    },
  },
}))(ButtonBase);

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0.5rem",
    },
    [theme.breakpoints.down("310")]: {
      padding: "1rem",
    },
  },
  buttonContainer: {
    backgroundColor: "#fff",
    boxShadow: theme.shadows[2],
    padding: "1rem",
    display: "flex",
    borderRadius: "5.5rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  activeFilterButton: {
    background: "linear-gradient(180deg, #10316D 0%, #295EBD 100%)",
    color: "#fff !important",
  },
  categoriesTypography: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  circularProgressContainer: {
    display: "flex",
    height: "50vh",
    justifyContent: "center",
    alignItems: "center",
  },
  circle: {
    color: theme.palette.darkerFoneca.main,
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0rem",
    },
  },
}));

export default function Products() {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState("all-services");
  const [productCards, setProductCards] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [productId, setProductId] = useState(0);
  const { openSnackbar } = useContext(SnackbarContext);
  const theme = useTheme();
  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { data: services, status: productsStatus } = useQuery("services", getProductList, {
    select: (response) => response.data,
    onSuccess: (response) => {
      setProductCards(response);
    },
    onError: () => {
      openSnackbar("error", "Products cannot be loaded! We apologize for any inconvenience this may have caused!");
    },
    refetchOnWindowFocus: false,
  });

  const { data: categories, status: categoryStatus } = useQuery("categories", getCategoryList, {
    select: (response) => response.data,
  });

  const showAllServicesHandler = (e) => {
    const { name } = e.target;
    setActiveButton(name);
    setProductCards(services);
  };

  const filterByCategory = (category) => setProductCards(
    services?.filter((service) => service.category === category),
  );

  const handleFilterButtonClick = (e) => {
    const { name } = e.target;
    setActiveButton(name);
    filterByCategory(name);
  };

  const getCategoryName = (longName, shortName) => (
    !matchesMobileScreen ? longName : shortName
  );

  const openDialogHandler = (id) => {
    setProductId(id);
    setDialogOpen(true);
  };

  const onCloseDialogHandler = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {matchesMobileScreen && <GradientTypography variant="h2" className={classes.categoriesTypography}>Categories</GradientTypography>}
      <Container className={classes.filterContainer}>
        <Box className={classes.buttonContainer} data-cy="filter-buttons-container">
          {categoryStatus === "loading" ? (
            <CircularProgress />
          ) : (
            <>
              <CategoryButton
                name="all-services"
                className={clsx(activeButton === "all-services" && `${classes.activeFilterButton}`)}
                onClick={showAllServicesHandler}
              >
                {getCategoryName("All Services", "All")}
              </CategoryButton>
              {categories?.map((category) => (
                <CategoryButton
                  key={category.name}
                  name={category.name}
                  className={clsx(activeButton === category.name && classes.activeFilterButton)}
                  onClick={handleFilterButtonClick}
                >
                  {getCategoryName(category.name, category.name.split(" ")[0])}
                </CategoryButton>
              ))}
            </>
          )}
        </Box>
      </Container>
      {productsStatus === "loading" ? (
        <Container className={classes.circularProgressContainer}>
          <CircularProgress className={classes.circle} size="5rem" />
        </Container>
      ) : (
        <Grid container spacing={2} className={classes.gridContainer} data-cy="products-container">
          {productCards?.map(({
            id, name, image, rate,
          }) => (
            <Grid key={id} container item justify="center" xs={12} md={6} lg={4} xl={4} data-cy="product-card-container">
              <ProductCard
                productName={name}
                onClickHandler={() => openDialogHandler(id)}
                image={image}
                rate={rate}
                id={id}
              />
            </Grid>
          ))}
          <Grid container item justify="center" xs={12} md={6} lg={4} xl={4}>
            <RechargeCard />
          </Grid>
          <ShortDescription
            productId={productId}
            openDialog={isDialogOpen}
            closeDialogHandler={onCloseDialogHandler}
          />
        </Grid>
      )}
    </>
  );
}
