import axios from "axios";
import jwt from "jsonwebtoken";
// eslint-disable-next-line import/no-cycle
import { loginAxios } from "../AccountCalls/AccountCalls";

export const loggedInAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

function forceLogOut() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  window.location.href = "/login";
}

function refreshAccessToken(response) {
  const { access } = response.data;
  localStorage.setItem("access_token", access);
}

function isTokenExpired(tokenName) {
  const token = localStorage.getItem(tokenName);
  if (token === null) {
    return false;
  }
  const decodedToken = jwt.decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < dateNow.getTime() / 1000) { return true; }
  return false;
}

async function getRefreshToken() {
  const refreshToken = localStorage.getItem("refresh_token");
  const postBody = { refresh: refreshToken };
  try {
    const response = await loginAxios.post("/accounts/refresh/", postBody);
    return response;
  } catch (error) {
    forceLogOut();
    throw error;
  }
}

loggedInAxios.interceptors.request.use(async (config) => {
  if (isTokenExpired("access_token")) {
    const response = await getRefreshToken();
    await refreshAccessToken(response);
  }
  const accessToken = localStorage.getItem("access_token");
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
}, (error) => error);
