import React from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Dialog, Typography, Grid, IconButton, Box, CircularProgress, ButtonBase, List, ListItem,
  ListItemIcon, Zoom,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Close as CloseIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons/";
import { getProduct } from "../../../api/ServicesCalls/ServicesCalls";
import { rates } from "../../../static/mockData/shortDescriptionRates";
import GradientTypography from "../GradientTypography/GradientTypography";

const OrderButton = withStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[2],
    background: "linear-gradient(180deg, #10316D 0%, #295EBD 100%)",
    color: "#fff",
    padding: "1.3rem 1rem",
    fontSize: "0.9rem",
    borderRadius: "1rem",
    fontWeight: "bolder",
    textTransform: "capitalize",
  },
}))(ButtonBase);

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: "45rem",
    maxHeight: "none",
    borderRadius: theme.radiuses[2],
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 2rem 2rem",
    },
    [theme.breakpoints.down("320")]: {
      padding: "1rem",
    },
  },
  dialogTitleContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #EBEEFF",
    padding: "1rem 2rem 0.5rem 2rem",
    marginBottom: "2rem",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0rem",
    },
  },
  closeButton: {
    position: "absolute",
    right: "0.6rem",
    top: "0.6rem",
    color: "#c7cad9",
    "&:hover": {
      color: "#c7cad9",
    },
    [theme.breakpoints.down("xs")]: {
      right: "-0.5rem",
      top: "0.2rem",
    },
    [theme.breakpoints.down("325")]: {
      right: "-0.9rem",
      top: "-0.1rem",
    },
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    [theme.breakpoints.down("325")]: {
      fontSize: "1.2rem",
    },
  },
  imageContainer: {
    paddingLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
      width: "100%",
      textAlign: "center",
      marginBottom: "2rem",
    },
  },
  image: {
    width: "100%",
    maxWidth: "21rem",
    height: "auto",
    borderRadius: theme.radiuses[1],
  },
  subheaderContainer: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("700")]: {
      padding: "0rem 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  subheader: {
    fontWeight: "bolder",
    lineHeight: "1.2",
    [theme.breakpoints.down("700")]: {
      marginBottom: "1rem",
    },
  },
  rateContainer: {
    lineHeight: "0.7",
    [theme.breakpoints.down("700")]: {
      marginBottom: "1rem",
    },
  },
  rateTypography: {
    width: "100%",
    lineHeight: "0.7",
    fontWeight: "bold",
    color: theme.palette.darkerFoneca.main,
  },
  rateInfo: {
    color: "#10316D",
    fontWeight: "bold",
    fontSize: "0.8rem",
    padding: "0rem 0.8rem",
    opacity: "0.8",
    [theme.breakpoints.down("700")]: {
      marginBottom: "1rem",
    },
  },
  currency: {
    fontSize: "1rem",
    display: "inline-block",
    verticalAlign: "top",
    color: "#4F4F4F",
  },
  subheaderText: {
    color: "#10316D",
    opacity: "0.5",
    fontWeight: "bolder",
    lineHeight: "1.2",
    paddingRight: "2rem",
    [theme.breakpoints.down("700")]: {
      marginBottom: "1rem",
      padding: "0rem",
    },
  },
  descriptionContainer: {
    margin: "2rem 0rem 3rem 0rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
      margin: "2rem 0rem",
    },
  },
  descriptionTypography: {
    color: theme.palette.darkerFoneca.main,
    lineHeight: "1.3",
    fontWeight: "400",
  },
  ratesContainer: {
    paddingLeft: "2rem",
    [theme.breakpoints.down("700")]: {
      padding: "0rem 1rem 0rem 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  ratesList: {
    marginTop: "2rem",
    padding: "0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem",
    },
  },
  listItem: {
    padding: "0rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0.3rem",
    },
  },
  listItemIcon: {
    minWidth: "2.2rem",
    color: theme.palette.orangeFoneca.main,
    alignSelf: "flex-start",
    paddingTop: "0.3rem",
    [theme.breakpoints.down("700")]: {
      paddingTop: "0.1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem",
    },
  },
  ratesTypography: {
    color: theme.palette.darkerFoneca.main,
    maxWidth: "17.5rem",
    fontWeight: "400",
    [theme.breakpoints.down("700")]: {
      lineHeight: "1.3",
    },
  },
  circle: {
    color: theme.palette.darkerFoneca.main,
  },
}));

export default function ShortDescription(props) {
  const classes = useStyles();
  const {
    productId, openDialog, closeDialogHandler,
  } = props;
  const history = useHistory();

  const { data: productShortDescription, status: shortDescriptionStatus } = useQuery(
    ["getProduct", productId], () => getProduct(productId), {
      select: (response) => response.data,
      retry: false,
      enabled: openDialog,
    },
  );

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialogHandler}
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{
        elevation: 0,
      }}
      scroll="body"
      TransitionComponent={Zoom}
      transitionDuration={700}
      data-cy="short-description-doalog"
    >
      {shortDescriptionStatus === "loading" ? (
        <CircularProgress className={classes.circle} size="5rem" />
      ) : (
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={12} lg={12} className={classes.dialogTitleContainer}>
            <GradientTypography className={classes.title}>
              {productShortDescription?.short_description_heading ?? ""}
            </GradientTypography>
            <IconButton onClick={closeDialogHandler} className={classes.closeButton} data-cy="short-description-close-button">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item sm={6} md={6} className={classes.imageContainer}>
            <img src={productShortDescription?.image} alt="some title" className={classes.image} />
          </Grid>
          <Grid item sm={6} md={6} className={classes.subheaderContainer}>
            <GradientTypography style={{ marginBottom: "0.5rem" }} className={classes.subheader} variant="h5">
              {productShortDescription?.short_description_sub_heading ?? ""}
            </GradientTypography>
            <Box style={{ marginBottom: "0.5rem" }} className={classes.rateContainer}>
              <Typography variant="h6" className={classes.rateTypography}>
                <span className={classes.currency}>$</span>
                <span>
                  {productShortDescription?.rate}
                </span>
              </Typography>
              <span className={classes.rateInfo}>
                Starting price.
              </span>
            </Box>
            <Typography
              style={{ marginBottom: "0.5rem" }}
              className={classes.subheaderText}
            >
              Connect to Bulgaria and/or USA directly, no 011359, no 00
            </Typography>
            <Box width="100%" display="flex" justifyContent="flex-start">
              <OrderButton
                onClick={() => history.push(`/services/prepaid-cards/${productId}`)}
                style={{
                  width: "8rem",
                }}
                data-cy="order-now-button"
              >
                Order Now
              </OrderButton>
            </Box>
          </Grid>
          <Grid item sm={6} md={6} className={classes.descriptionContainer}>
            <Typography
              variant="h5"
              className={classes.descriptionTypography}
            >
              {productShortDescription?.short_description}
            </Typography>
          </Grid>
          <Grid item sm={6} md={6} className={classes.ratesContainer}>
            <List className={classes.ratesList}>
              {rates.map(({ id, rate, destination }) => (
                <ListItem key={id} className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckCircleOutlineIcon />
                  </ListItemIcon>
                  <Typography variant="h6" className={classes.ratesTypography}>
                    {rate}
                    {" "}
                    to
                    {" "}
                    {destination}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
}

ShortDescription.propTypes = {
  productId: PropTypes.number.isRequired,
  openDialog: PropTypes.bool.isRequired,
  closeDialogHandler: PropTypes.func.isRequired,
};
