import React, { useState, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import clsx from "clsx";
import {
  TextField, CircularProgress, InputAdornment, IconButton, Typography, Paper, Container,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { changePassword } from "../../api/AccountCalls/AccountCalls";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import CustomSubmitButton from "../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import MessageBox from "../../Components/Dumb/MessageBox/MessageBox";
import successfullPasswordChangeIllustration from "../../static/passwordChangedSuccessfully.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 12.2rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
    maxWidth: "700px",
  },
  mainContainerMessageBox: {
    [theme.breakpoints.down("330")]: {
      margin: "2rem auto",
    },
  },
  paper: {
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
  },
  title: {
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  confirmPasswordTypography: {
    marginBottom: "4rem",
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const [isPasswordChangedSuccessfully, setIsPasswordChangedSuccessfully] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState({ first: false, second: false });
  const location = useLocation();
  const {
    register, handleSubmit, errors, watch,
  } = useForm({ reValidateMode: "onSubmit" });
  const { openSnackbar } = useContext(SnackbarContext);

  const password = useRef({});
  password.current = watch("password", "");

  const { mutate: changePasswordMutation, status: changePasswordStatus } = useMutation(
    changePassword,
    {
      onSuccess: () => {
        setIsPasswordChangedSuccessfully(true);
      },
      onError: (error) => {
        if (error?.response?.status === 400) {
          openSnackbar("error", error.response.data[0], "reset-password-error-snackbar");
        }
      },
    },
  );

  const onSubmit = () => {
    const data = {
      ...Object.fromEntries(new URLSearchParams(location.search)),
      password: password.current,
    };
    changePasswordMutation(data);
  };

  return (
    <>
      {isPasswordChangedSuccessfully ? (
        <HeaderFooterContainer>
          <Container className={clsx(classes.mainContainer, classes.mainContainerMessageBox)}>
            <Paper className={classes.paper}>
              <MessageBox
                title="Password Changed Successfully!"
                illustration={successfullPasswordChangeIllustration}
                showButton
              />
            </Paper>
          </Container>
        </HeaderFooterContainer>
      ) : (
        <HeaderFooterContainer>
          <Container className={classes.mainContainer}>
            <Paper className={classes.paper}>
              <Typography variant="h3" align="center" color="primary" className={classes.title}>Change Your Password</Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="password"
                  type={isPasswordVisible?.first ? "text" : "password"}
                  id="password"
                  label="New Password"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={
                            () => setIsPasswordVisible({
                              ...isPasswordVisible,
                              first: !isPasswordVisible.first,
                            })
                          }
                        >
                          {isPasswordVisible?.first ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: "Please fill in your new password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters.",
                    },
                  })}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="password_confirm"
                  type={isPasswordVisible?.second ? "text" : "password"}
                  id="confirm-password"
                  label="Confirm Password"
                  variant="outlined"
                  className={classes.confirmPasswordTypography}
                  error={!!errors.password_confirm}
                  helperText={errors?.password_confirm?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={
                            () => setIsPasswordVisible({
                              ...isPasswordVisible,
                              second: !isPasswordVisible.second,
                            })
                          }
                        >
                          {isPasswordVisible?.second ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    validate: (value) => value === password.current || "The passwords do not match.",
                    required: "Please fill in your confirmation password",
                  })}
                />
                <CustomSubmitButton
                  fullWidth
                  variant="contained"
                  disableElevation
                  color="primary"
                  id="change-password-button"
                  type="submit"
                >
                  { changePasswordStatus === "loading" ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Update Password"}
                </CustomSubmitButton>
              </form>
            </Paper>
          </Container>
        </HeaderFooterContainer>
      ) }
    </>
  );
}
