import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import {
  TextField, CircularProgress, Typography, Select, MenuItem, Grid,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidNumber } from "libphonenumber-js";
import { SnackbarContext } from "../../../Context/SnackbarContext/SnackbarContext";
import CustomSubmitButton from "../../../Components/Dumb/CustomSubmitButton/CustomSubmitButton";
import { createBillingInfo, updateBillingInfo } from "../../../api/BillingCalls/BillingCalls";
import { UserContext } from "../../../Context/UserContext/UserContext";

export default function PersonalInformationForm({ personalDetails, doesBillingInfoExist }) {
  const { openSnackbar } = useContext(SnackbarContext);
  const [monthlyLimit, setMonthlyLimit] = useState(100);
  const monthlyLimitOptions = [20, 30, 40, 50, 60, 70, 80, 90, 100];
  const { userState } = useContext(UserContext);
  const userId = userState.id;
  const isPhoneValid = (phoneNumber) => isValidNumber(phoneNumber);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({ reValidateMode: "onSubmit" });

  const handleMonthlyLimitChange = (event) => {
    setValue("monthly_limit", event.target.value);
    setMonthlyLimit(event.target.value);
  };

  const { mutate: createPersonalInfoMutation, status: personalInfoCreationStatus } = useMutation(
    createBillingInfo,
    {
      onSuccess: () => {
        openSnackbar("success", "Successfully created your personal information!", "success-personal-info-creation-snackbar");
      },
      onError: () => {
        openSnackbar("error", "Something went wrong!", "error-personal-info-creation-snackbar");
      },
    },
  );

  const { mutate: updatePersonalInfoMutation, status: personalInfoUpdateStatus } = useMutation(
    updateBillingInfo,
    {
      onSuccess: () => {
        openSnackbar("success", "Successfully updated your personal information!", "success-personal-info-update-snackbar");
      },
      onError: () => {
        openSnackbar("error", "Something went wrong!", "error-personal-info-creation-snackbar");
      },
    },
  );

  const onSubmit = (data) => {
    if (doesBillingInfoExist) {
      updatePersonalInfoMutation({ data, userId });
    } else {
      createPersonalInfoMutation(data);
    }
  };

  useEffect(() => {
    if (doesBillingInfoExist) {
      setValue("first_name", personalDetails.first_name);
      setValue("middle_name", personalDetails.middle_name);
      setValue("last_name", personalDetails.last_name);
      setValue("phone_number", personalDetails.phone_number);
      setValue("country", personalDetails.country);
      setValue("state", personalDetails.state);
      setValue("city", personalDetails.city);
      setValue("zip", personalDetails.zip);
      setValue("address", personalDetails.address);
      setValue("monthly_limit", personalDetails.monthly_limit);
      setMonthlyLimit(personalDetails.monthly_limit);
    }
  },
  [personalDetails, doesBillingInfoExist, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={6} justify="center">
        <Grid item xs={12} sm={12} md={6}>
          <Typography color="primary" variant="h6" align="left">Personal Information</Typography>
          <TextField
            required
            fullWidth
            label="First Name"
            name="first_name"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.first_name}
            helperText={errors?.first_name?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-first-name"
          />
          <TextField
            fullWidth
            label="Middle Name"
            name="middle_name"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.middle_name}
            helperText={errors?.middle_name?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-middle-name"
          />
          <TextField
            required
            fullWidth
            label="Last Name"
            name="last_name"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.last_name}
            helperText={errors?.last_name?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-last-name"
          />
          <Controller
            as={MuiPhoneNumber}
            required
            fullWidth
            label="Phone Number"
            name="phone_number"
            variant="outlined"
            margin="normal"
            defaultCountry="us"
            preferredCountries={["us", "bg"]}
            countryCodeEditable={false}
            disableAreaCodes
            defaultValue=""
            control={control}
            error={!!errors.phone_number}
            helperText={errors.phone_number && "Please enter a valid number."}
            rules={{
              validate: (value) => isPhoneValid(value),
            }}
            data-cy="billing-phone-number"
          />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography color="primary" variant="body1" align="center">Monthly limit</Typography>
            <Controller
              render={() => (
                <Select
                  style={{ minWidth: "110px" }}
                  value={monthlyLimit}
                  onChange={handleMonthlyLimitChange}
                  data-cy="billing-monthly-limit-select"
                  variant="outlined"
                >
                  {monthlyLimitOptions.map((option) => (
                    <MenuItem key={option} value={option} data-cy={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="monthly_limit"
              control={control}
              defaultValue={monthlyLimit}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography color="primary" variant="h6" align="left">Address Information</Typography>
          <TextField
            required
            fullWidth
            label="Country"
            name="country"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.country}
            helperText={errors?.country?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-country"
          />
          <TextField
            required
            fullWidth
            label="State"
            name="state"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.state}
            helperText={errors?.state?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-state"
          />
          <TextField
            required
            fullWidth
            label="City"
            name="city"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.city}
            helperText={errors?.city?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-city"
          />
          <TextField
            required
            fullWidth
            label="ZIP Code"
            name="zip"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.city}
            helperText={errors?.city?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-zip"
          />
          <TextField
            required
            fullWidth
            label="Address"
            name="address"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register}
            error={!!errors.city}
            helperText={errors?.city?.message}
            variant="outlined"
            margin="normal"
            data-cy="billing-address"
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6}>
          <CustomSubmitButton
            disableElevation
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            data-cy="billing-personal-save-button"
          >
            {
              (personalInfoCreationStatus || personalInfoUpdateStatus) === "loading" ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : ("Save")
            }
          </CustomSubmitButton>
        </Grid>
      </Grid>
    </form>
  );
}

PersonalInformationForm.propTypes = {
  personalDetails: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    address: PropTypes.string,
    monthly_limit: PropTypes.number,
  }),
  doesBillingInfoExist: PropTypes.bool.isRequired,
};
