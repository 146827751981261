import React from "react";
import PropTypes from "prop-types";
import {
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY,
);

export default function StripeElementsProvider({ children }) {
  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  );
}

StripeElementsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
