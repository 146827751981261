import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paper, Container } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "react-query";
import { verify } from "../../api/AccountCalls/AccountCalls";
import { SnackbarContext } from "../../Context/SnackbarContext/SnackbarContext";
import MessageBox from "../../Components/Dumb/MessageBox/MessageBox";
import loadingIllustration from "../../static/loading_illustration.svg";
import HeaderFooterContainer from "../../Components/Container/HeaderFooterContainer/HeaderFooterContainer";
import { useTimeout } from "../../hooks/useTimeout/useTimeout";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "calc(100vh - 12.2rem)",
    display: "grid",
    placeItems: "center",
    margin: "2rem auto",
    maxWidth: "700px",
    [theme.breakpoints.down("330")]: {
      margin: "2rem auto",
    },
  },
  paper: {
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
  },
}));

export default function VerifyingUserPage() {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const { openSnackbar } = useContext(SnackbarContext);

  const { mutate: verifyMutation } = useMutation(
    verify,
    {
      onSuccess: () => {
        openSnackbar("success", "Verification successful.", "verification-success-snackbar");
      },
      onError: () => {
        openSnackbar("error", "Whoops. Invalid signature.", "verification-error-snackbar");
      },
    },
  );

  const redirect = useTimeout(() => history.push("/login"), 1500);

  const verifyUser = async () => {
    const data = Object.fromEntries(new URLSearchParams(location.search));
    await verifyMutation(data);
  };

  useEffect(async () => {
    await verifyUser();
    redirect();
  }, []);

  return (
    <HeaderFooterContainer>
      <Container className={classes.mainContainer}>
        <Paper className={classes.paper}>
          <MessageBox
            title="Verifying your account"
            illustration={loadingIllustration}
          />
        </Paper>
      </Container>
    </HeaderFooterContainer>
  );
}
