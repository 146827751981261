import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import { useQuery } from "react-query";
import PersonalInformationForm from "./PersonalInformationForm/PersonalInformationForm";
import CompanyInformationForm from "./CompanyInformationForm/CompanyInformationForm";
import { getBillingInfo } from "../../api/BillingCalls/BillingCalls";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: "calc(100vh - 8.2rem)",
    backgroundColor: theme.palette.pageBG.main,
    [theme.breakpoints.down("xl")]: {
      padding: "0 16rem 0px 28rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0 6rem 0 18rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 2rem 0 16rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem 0 4rem",
    },
  },
  paper: {
    display: "grid",
    justifyContent: "center",
    padding: "3rem",
    borderRadius: theme.radiuses[2],
    [theme.breakpoints.down("xs")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("300")]: {
      padding: "1rem",
    },
    margin: "10px",
    boxShadow: "0px 15px 60px rgba(16, 49, 109, 0.1)",
  },
}));

export default function BillingInformationPage() {
  const classes = useStyles();

  const { data: billingData } = useQuery("billingInfo", getBillingInfo, {
    select: (response) => response.data[0],
    retry: false,
  });

  return (
    <>
      <Grid
        className={classes.gridContainer}
        container
        justify="flex-start"
        direction="column"
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <PersonalInformationForm
              personalDetails={billingData}
              doesBillingInfoExist={!!billingData}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper}>
            <CompanyInformationForm
              personalDetails={billingData}
              doesBillingInfoExist={!!billingData}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
