import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { data } from "../../../static/mockData/testimonials";
import TestimonialCard from "../../../Components/Dumb/Cards/TestimonialCard/TestimonialCard";
import GradientTypography from "../../../Components/Dumb/GradientTypography/GradientTypography";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "2rem",
  },
  carouselContainer: {
    height: "65rem",
    [theme.breakpoints.down("670")]: {
      height: "53rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "47rem",
    },
    [theme.breakpoints.down("460")]: {
      height: "40rem",
    },
    [theme.breakpoints.down("300")]: {
      height: "31rem",
    },
  },
}));

export default function Testimonials() {
  const classes = useStyles();

  return (
    <>
      <GradientTypography variant="h2" className={classes.title}>Testimonials</GradientTypography>
      <Grid container spacing={2}>
        {data.map((testimonial) => (
          <Grid key={testimonial.id} container item justify="center" xs={12} md={6} lg={4} xl={4}>
            <TestimonialCard
              key={testimonial.id}
              comment={testimonial.comment}
              rating={testimonial.rating}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
